import { getDefaultValueByType } from '@components/DisplayForm/helpers/getDefaultValueByType'

import { FormRow, ObjectDataRecord } from '@types'

// Формирует готовый объект с значениями полей по-умолчанию в зависимости от их типов
export const getDefaultValuesByObjectFields = (
  fields: FormRow[],
  row: ObjectDataRecord
): ObjectDataRecord => {
  return Object.fromEntries(
    fields.map(field => {
      return getDefaultValueByType(field, row)
    })
  )
}

import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router'
import { t } from 'i18next'

import {
  useCreateAllFieldsMutation,
  useCreateFieldMutation,
  useCreateFormMutation,
  useDeleteFieldMutation,
  useFetchAllFormIntegrationViewsQuery,
  useFetchAllObjectQuery,
  useFetchAllViewQuery,
  useFetchDictionariesByCategoryCodeQuery,
  useFetchFieldMutation,
  useFetchFormQuery,
  useFetchObjectByCodeQuery,
  useUpdateFieldMutation,
  useUpdateFormMutation,
  useUpdateOrderFieldsMutation,
  useUpdateVisibilityFieldByIdMutation,
} from '@redux/api'
import { useFetchContextMenuListQuery } from '@redux/api/contextMenu.api'
import { useCreateMandatoryFieldsMutation } from '@redux/api/field.api'
import { setModelCode } from '@redux/reducers/stepper.reducer'

import { useAppDispatch, useAppSelector, usePrompt } from '@hooks'
import { getDefaultValues } from '@helpers'
import {
  CATEGORIES,
  ENTITY_TYPES_REQUEST,
  FORM_COLUMNS,
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODEL_TYPE,
  REGEX,
  ROUTES,
} from '@constants'

import { FormRow, FormValues, ObjectFieldWithUsed } from '../../../types'
import { FormInputsType } from '../types'

import { useHandlers } from './useHandlers'

export const useFormCreateOrEdit = () => {
  const { modelCode } = useAppSelector(state => state.stepper)
  const [fields, setFields] = useState<FormRow[]>([])
  const [modifyObjectFields, setModifyObjectFields] = useState<ObjectFieldWithUsed[]>([])
  const [isShowScriptValueDialogTitle, setShowScriptValueDialogTitle] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const location = useLocation() as unknown as Location & { state?: { code?: string } }
  const copyCode = location.state?.code
  const { code } = useParams()

  const {
    data: form,
    isError,
    isLoading: formIsLoading,
  } = useFetchFormQuery(code ?? copyCode, { skip: !code && !copyCode })
  const [createForm, { isSuccess: isSuccessCreate, isLoading: formLoadingCreate }] =
    useCreateFormMutation()
  const [updateForm, { isSuccess: isSuccessUpdate, isLoading: formLoadingUpdate }] =
    useUpdateFormMutation()
  const [fetchField, { data: row }] = useFetchFieldMutation()
  const [createField] = useCreateFieldMutation()
  const [createAllFields, { isLoading: createAllFieldsLoading }] = useCreateAllFieldsMutation()
  const [createMandatoryFields, { isLoading: createMandatoryFieldsLoading }] =
    useCreateMandatoryFieldsMutation()
  const [updateField] = useUpdateFieldMutation()
  const [deleteField] = useDeleteFieldMutation()
  const [updateOrderFields] = useUpdateOrderFieldsMutation()
  const [updateVisibilityField] = useUpdateVisibilityFieldByIdMutation()

  const { data: objects } = useFetchAllObjectQuery()

  const formCode = code ?? modelCode
  const isEdit = !!code
  const isFormLoading = isEdit && !!formIsLoading
  const isCreate = ROUTES.CONFIG_FORMS_CREATE === location.pathname

  const defaultValues: FormValues = getDefaultValues()
  const methods = useForm<FormValues>({ defaultValues })

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = methods

  const watchedObject = watch('object')

  const { state, handlers } = useHandlers({
    formCode,
    createForm,
    updateForm,
    createField,
    createAllFields,
    createMandatoryFields,
    deleteField,
    updateField,
    fetchField,
    objects,
    code,
    copyCode,
    handleSubmit,
  })

  const { modalProperty, objectCode, currentForm } = state

  const { data: contextMenus } = useFetchContextMenuListQuery({ objectCode }, { skip: !objectCode })

  const { handleSetObjectCode, handleSetModelCode, handleSetCurrentForm, handleSetCurrentRow } =
    handlers

  const isLeaveEdit = isDirty && !formLoadingCreate && !!currentForm && !isCreate
  const isLeaveCreate = isDirty && isCreate && !formCode && !formLoadingCreate

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  const { data: object } = useFetchObjectByCodeQuery(objectCode, { skip: !objectCode })

  const { data: viewsData } = useFetchAllViewQuery({ viewType: ENTITY_TYPES_REQUEST.LIST_CONTROL })

  const { data: viewsDataForFormIntegration } = useFetchAllFormIntegrationViewsQuery(objectCode, {
    skip: !objectCode,
  })
  const { data: bindingValuesFieldRender } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_RENDER_FIELD,
    {
      skip: modalProperty !== MODEL_TYPE.FIELD,
    }
  )
  const { data: bindingValuesField } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_FIELD,
    {
      skip: modalProperty !== MODEL_TYPE.FIELD,
    }
  )
  const { data: bindingValuesFieldType } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_TYPES_FIELD,
    {
      skip: modalProperty !== MODEL_TYPE.FIELD,
    }
  )
  const { data: bindingValuesColumns } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_TYPES_FIELD,
    {
      skip: modalProperty !== MODEL_TYPE.FIELD,
    }
  )

  const mainTable = { rows: fields, columns: FORM_COLUMNS }

  useLayoutEffect(() => {
    if (code) {
      dispatch(setModelCode(code))
    }
  }, [code])

  useEffect(() => {
    if (object?.fields) {
      setModifyObjectFields(
        object.fields.map(field => {
          const isAlreadyUsed = Boolean(fields.find(column => column.value === field.name))

          return { ...field, isAlreadyUsed }
        })
      )
    }
  }, [object, fields])

  useEffect(() => {
    if (form?.fields) {
      handleSetCurrentForm(copyCode ? { ...form, code: '' } : form)
      setFields(form.fields)
    }
  }, [form])

  useEffect(() => {
    if (row) {
      handleSetCurrentRow(row)
    }
  }, [row])

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.CONFIG_FORMS_CREATE)
    }
  }, [isError])

  useEffect(() => {
    if (currentForm && objects) {
      const object = objects?.find(obj => obj.code === currentForm.objectCode)

      const viewIds = currentForm.views
        ? currentForm.views.map(view => ({
            id: Number(view.id),
            label: view.title,
          }))
        : []

      handleSetModelCode(currentForm.code)

      const commands = currentForm.commands.map(command => ({
        type: {
          id: command.type,
          label: command.type,
        },
        code: {
          id: command.name,
          label: command.name,
        },
      }))

      reset({
        ...currentForm,
        commands,
        object: object ? { id: object.code, label: object.title } : null,
        contextMenu: currentForm.contextMenu
          ? { id: currentForm.contextMenu.id, label: currentForm.contextMenu.title }
          : null,
        viewIds,
      })

      return
    }

    handleSetModelCode('')
  }, [currentForm, objects])

  useEffect(() => {
    if (watchedObject && objects) {
      const currentObj = objects.filter(obj => obj.title === watchedObject.label)
      if (currentObj.length) {
        handleSetObjectCode(currentObj[0].code)
      }
    }
  }, [watchedObject])

  const watchJs = watch('isJsTitle')

  const titleJsValue: FormInputsType = {
    name: 'titleJsValue',
    inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
    placeholder: t('formCreate.titleJsValue.placeholder'),
    label: t('formCreate.titleJsValue.label'),
    maxLengthInput: MAX_INPUT_LENGTH,
    readOnly: true,
    rows: 10,
    rules: {
      required: true,
      maxLength: MAX_INPUT_LENGTH,
    },
    additionalBtn: {
      isEnabled: true,
      text: 'edit',
      color: 'primary',
      variant: 'contained',
      onClick: () => setShowScriptValueDialogTitle(true),
    },
  }

  const newModelInputsConfig: FormInputsType[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('viewsCreate.newModel.code.placeholder'),
      label: t('viewsCreate.newModel.code.label'),
      disabled: isEdit,
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      rules: {
        required: true,
        validate: value => value.trim().length !== 0,
      },
    },
    {
      name: 'rowTitle',
      inputs: [
        {
          name: 'title',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('viewsCreate.newModel.title.placeholder'),
          label: t('viewsCreate.newModel.title.label'),
          rules: { required: true, validate: value => value.trim().length !== 0 },
        },
        {
          name: 'isJsTitle',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: t('formCreate.isJsTitle.label'),
          labelPlacement: 'end',
        },
      ],
    },
    ...(watchJs ? [titleJsValue] : []),
  ]

  const handleSetShowScriptValueDialogTitle = (value: boolean) =>
    setShowScriptValueDialogTitle(value)

  const contextMenuOptions = useMemo(
    () => contextMenus?.map(({ id, title: label }) => ({ id, label })) || [],
    [contextMenus]
  )

  return {
    data: {
      formIsLoading,
      isSuccessCreate,
      formLoadingCreate,
      isSuccessUpdate,
      formLoadingUpdate,
      formCode,
      object,
      objects,
      bindingValuesFieldRender,
      bindingValuesField,
      bindingValuesFieldType,
      bindingValuesColumns,
      mainTable,
      viewsData,
      viewsDataForFormIntegration,
      methods,
      isFormLoading,
      code,
      copyCode,
      isDirty,
      isEdit,
      createAllFieldsLoading,
      createMandatoryFieldsLoading,
    },
    state: {
      ...state,
      modifyObjectFields,
      fields,
      isShowScriptValueDialogTitle,
      newModelInputsConfig,
      contextMenuOptions,
    },
    handlers: {
      ...handlers,
      handleSetShowScriptValueDialogTitle,
    },
    mutations: {
      updateOrderFields,
      updateVisibilityField,
      deleteField,
    },
  }
}

import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { AutocompleteOption } from '@types'

type SegmentLabelsFieldsProps = {
  watchedObject?: AutocompleteOption<string> | null
}

const labelHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.cornersScriptHint'),
}

export const SegmentLabelsFields = ({ watchedObject }: SegmentLabelsFieldsProps) => {
  const { t } = useTranslation()
  const isDisabled = !watchedObject

  return (
    <Box>
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.leftTopTitle')}
        containerName='labels.leftTopTitle'
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.topTitle')}
        containerName='labels.topTitle'
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.rightTopTitle')}
        containerName='labels.rightTopTitle'
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.rightBottomTitle')}
        containerName='labels.rightBottomTitle'
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        hasDivider
        blockLabel={t('ganttCreate.timelineForm.bottomTitle')}
        containerName='labels.bottomTitle'
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        blockLabel={t('ganttCreate.timelineForm.leftBottomTitle')}
        containerName='labels.leftBottomTitle'
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
    </Box>
  )
}

import { FIELD_VALUE_TYPE } from '@constants'
import { ObjectFieldDTO } from '@types'

import { ExtOptionFilter } from '@gantt/types'

export const keyFieldOptionsFilter = (options: ExtOptionFilter[]) => {
  return options.filter(opt => {
    const option = opt as ObjectFieldDTO

    return (
      option.isPk ||
      option.isInternalId ||
      [FIELD_VALUE_TYPE.OBJ_PK_LINK, FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK].includes(
        option.valueType
      )
    )
  })
}

export const dateTimeFieldOptionsFilter = (options: ExtOptionFilter[]) => {
  return options.filter(opt => {
    const option = opt as ObjectFieldDTO

    return [
      FIELD_VALUE_TYPE.DATETIME,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ].includes(option.valueType)
  })
}

export const durationFieldOptionsFilter = (options: ExtOptionFilter[]) => {
  return options.filter(opt => {
    const option = opt as ObjectFieldDTO

    return [
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ].includes(option.valueType)
  })
}

import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useHandlers } from '@pages/ObjectsStatesCreateOrEdit/hooks/useHandlers'

import { ROUTES } from '@constants'

export const useObjectsStatesCreateOrEdit = () => {
  const { id } = useParams()

  const initValueJs = ''

  const [invalidJs, setInvalidJs] = useState(false)
  const [valueJs, setValueJs] = useState(initValueJs || '')
  const isDirtyJsField = initValueJs !== valueJs

  const { handlers, state, data } = useHandlers({ valueJs, setValueJs })

  const isCreate = ROUTES.CONFIG_OBJECTS_STATES_CREATE === location.pathname
  const isEdit = !!id

  return {
    data: {
      ...data,
      id,
      isCreate,
      isEdit,
    },
    state: { ...state, invalidJs, valueJs, isDirtyJsField },
    handlers: { ...handlers },
  }
}

import { GETActionView, GETActionViewById, PayloadCreateAction } from '@types'

import { commonAPI } from './common.api'

export const actionAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchActionView: build.mutation<GETActionViewById, number | string | void>({
      query: id => ({
        url: `/ui/action/id/${id}`,
      }),
    }),
    createActionView: build.mutation<GETActionView, PayloadCreateAction>({
      query: action => ({
        url: '/ui/action',
        method: 'POST',
        body: { ...action, id: undefined },
      }),
      invalidatesTags: ['SingleView'],
    }),
    updateActionView: build.mutation<GETActionView, PayloadCreateAction>({
      query: action => ({
        url: '/ui/action',
        method: 'POST',
        body: action,
      }),
      invalidatesTags: ['SingleView'],
    }),
    deleteActionView: build.mutation<void, number | string>({
      query: id => ({
        url: `/ui/action/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleView'],
    }),
  }),
})

export const {
  useFetchActionViewMutation,
  useCreateActionViewMutation,
  useDeleteActionViewMutation,
  useUpdateActionViewMutation,
} = actionAPI

import { ComponentType, lazy, Suspense } from 'react'
import { LinearProgress } from '@mui/material'

import { ConfiguredGanttProps } from '@pages/ConfiduredGantt/types'

const ConfiguredGantt = lazy<ComponentType<ConfiguredGanttProps>>(() =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('./ConfiguredGantt').then(({ ConfiguredGantt }) => ({
    default: ConfiguredGantt,
  }))
)

export default function ConfiguredGanttLazy({ ...props }: ConfiguredGanttProps) {
  return (
    <Suspense fallback={<LinearProgress />}>
      <ConfiguredGantt {...props} />
    </Suspense>
  )
}

import { Navigate } from 'react-router-dom'

import { getOptionRoute } from '@helpers'
import { ROUTES } from '@constants'

export const SwitchRouter = ({ defaultEntities }: any) => {
  const context = localStorage.getItem('context')

  if (context === null) {
    return <Navigate replace to={ROUTES.CHOOSE_CONTEXT} />
  }
  if (defaultEntities?.length === 1) {
    return (
      <Navigate
        replace
        to={getOptionRoute({ id: defaultEntities[0].sourceId, title: defaultEntities[0].title })}
      />
    )
  }

  if (defaultEntities?.length === 2) {
    return (
      <Navigate
        replace
        to={getOptionRoute(
          {
            id: defaultEntities[0].sourceId,
            title: defaultEntities[0].title,
          },
          {
            id: defaultEntities[1].sourceId,
            title: defaultEntities[1].title,
          }
        )}
      />
    )
  }

  return <Navigate replace to={ROUTES.DEFAULT_PAGE} />
}

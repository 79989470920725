import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import last from 'lodash/last'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Box, FormHelperText } from '@mui/material'

import { BindTypeField, EmbeddedObject } from '@gantt/components/GanttCreateOrEdit/types'

type LinksHelperProps = {
  linkAxisX: BindTypeField
  linkAxisY: BindTypeField
  onErrorLinks: () => void
  onCorrectLinks: () => void
}

export const GanttSegmentLinksHelper = ({
  onErrorLinks,
  onCorrectLinks,
  linkAxisY,
  linkAxisX,
}: LinksHelperProps) => {
  const { t } = useTranslation()
  const lastLinkX = last<EmbeddedObject>(linkAxisX.field?.pathArray)
  const lastLinkY = last<EmbeddedObject>(linkAxisY.field?.pathArray)

  const objectCodeX = lastLinkX?.objectCode
  const objectCodeY = lastLinkY?.objectCode
  const fieldX = lastLinkX?.field
  const fieldY = lastLinkY?.field

  const isErrorLinks = objectCodeX !== objectCodeY || fieldX !== fieldY

  const isLinksEmpty = !linkAxisX.field?.pathStr && !linkAxisY.field?.pathStr
  const isOneLinkFilled = !linkAxisX.field?.pathStr || !linkAxisY.field?.pathStr
  const isLinksFilledNotCorrect = !isLinksEmpty && isErrorLinks
  const valueY = `${objectCodeY} / ${fieldY}`
  const valueX = `${objectCodeX} / ${fieldX}`
  const fieldObjectText = fieldY ? `${valueY}` : `${valueX}`
  const isCorrectLinks =
    !isErrorLinks && !isLinksFilledNotCorrect && !isLinksEmpty && !isOneLinkFilled

  const messages = useMemo(
    () => ({
      isLinksEmpty: t('ganttCreate.timelineForm.segmentLinksHelper'),
      isOneLinkFilled: t('ganttCreate.timelineForm.segmentLinksErrorOneLink', {
        value: fieldObjectText,
      }),
      isLinksFilledNotCorrect: t('ganttCreate.timelineForm.segmentLinksErrorLinksNotCorrect', {
        valueY,
        valueX,
      }),
      isNoErrorLinks: t('ganttCreate.timelineForm.segmentLinksCorrect'),
    }),
    [valueY, valueX]
  )

  useEffect(() => {
    isErrorLinks && onErrorLinks()
  }, [isErrorLinks])

  useEffect(() => {
    isCorrectLinks && onCorrectLinks()
  }, [isCorrectLinks])

  return (
    <FormHelperText error={isErrorLinks} sx={{ pt: 0.5, pl: 0, ml: 0 }}>
      <Box alignItems={'end'} display={'flex'}>
        {isLinksEmpty && (
          <>
            <WarningAmberIcon color={'warning'} />
            {messages.isLinksEmpty}
          </>
        )}
        {isOneLinkFilled && !isLinksEmpty && (
          <>
            <WarningAmberIcon color={'warning'} />
            {messages.isOneLinkFilled}
          </>
        )}
        {isLinksFilledNotCorrect && !isLinksEmpty && !isOneLinkFilled && (
          <>
            <CancelIcon color={'error'} />
            {messages.isLinksFilledNotCorrect}
          </>
        )}
        {isCorrectLinks && (
          <>
            <CheckCircleIcon color={'success'} />
            {messages.isNoErrorLinks}
          </>
        )}
      </Box>
    </FormHelperText>
  )
}

import { createContext, FC, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, FormInput } from '@microservices/wiskey-react-components'
import { Box, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { DataSource } from '@components/DataSource'
import { GeneralButtons } from '@components/GeneralButtons'
import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { SwitchTable } from '@components/SwitchTable'
import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { GENERATOR_INPUT_TYPE, MODAL_TYPE, MODEL_TYPE } from '@constants'

import { ScriptValueDialog } from '../../components/ScriptValueDialog'

import { FieldsConfig } from './components/FieldsConfig'
import { Integration } from './components/Integration'
import { useFormCreateOrEdit } from './hooks/useFormCreateOrEdit'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const FormCreateOrEdit: FC = () => {
  const { data, state, handlers, mutations } = useFormCreateOrEdit()
  const { t } = useTranslation()

  const {
    formIsLoading,
    isSuccessCreate,
    formLoadingCreate,
    isSuccessUpdate,
    formLoadingUpdate,
    formCode,
    object,
    objects,
    bindingValuesFieldRender,
    bindingValuesField,
    bindingValuesFieldType,
    bindingValuesColumns,
    mainTable,
    viewsData,
    viewsDataForFormIntegration,
    methods,
    isFormLoading,
    code,
    copyCode,
    isDirty,
    isEdit,
    createAllFieldsLoading,
    createMandatoryFieldsLoading,
  } = data

  const {
    currentRow,
    currentForm,
    showDialog,
    modalType,
    modifyObjectFields,
    currentTab,
    isShowScriptValueDialog,
    objectCode,
    newModelInputsConfig,
    isShowScriptValueDialogTitle,
    contextMenuOptions,
  } = state

  const {
    handleEditField,
    handleAddField,
    handleSetModelCode,
    handleAddForm,
    handleDeleteField,
    handleCancel,
    handleSetObjectCode,
    handleCloseModal,
    handleOpenDialog,
    handleSetModalProperty,
    toggleOpenScriptValueDialog,
    handleSave,
    handleTabChange,
    handleEdit,
    handleSetShowScriptValueDialogTitle,
    handleUpdateColumnTitle,
    handleCreateAllFields,
  } = handlers

  const { updateOrderFields, updateVisibilityField, deleteField } = mutations

  const contextValue: PageContextType = {
    objectCode,
    formIsLoading,
    formLoadingCreate: formLoadingUpdate || formLoadingCreate,
    formCode,
    isSuccessAddForm: isSuccessCreate || isSuccessUpdate,
    currentRow,
    showDialog,
    modalType,
    modifyObjectFields,
    objectFieldsForFilter: object?.fields,
    bindingValuesFieldRender,
    bindingValuesField,
    bindingValuesFieldType,
    bindingValuesColumns,
    objects,
    type: MODEL_TYPE.FORM,
    currentForm,
    mainTable,
    editField: handleEditField,
    addField: handleAddField,
    updateOrderFields,
    updateVisibilityField,
    onSetModelCode: handleSetModelCode,
    addForm: handleAddForm,
    deleteField: handleDeleteField,
    onCancel: handleCancel,
    onSetObjectCode: handleSetObjectCode,
    handleCloseModal,
    handleShowDialog: handleOpenDialog,
    handleSetModalProperty,
    toggleOpenScriptValueDialog,
    isShowScriptValueDialog,
    viewsByObjectCode: viewsData?.data,
  }

  const modelType = isEdit ? 'editModel' : 'newModel'

  const { setValue, watch } = methods

  const titleJsValue = watch('titleJsValue')
  const watchedObject = watch('object')
  const selectedObjectCode = useMemo(() => (watchedObject ? watchedObject.id : ''), [watchedObject])

  return (
    <PageContext.Provider value={contextValue}>
      <PageContentLayout>
        {isShowScriptValueDialogTitle && (
          <ScriptValueDialog
            windowBoundaries
            isShow={isShowScriptValueDialogTitle}
            modalType={MODAL_TYPE.EDIT}
            objectFields={object?.fields}
            value={titleJsValue || ''}
            onClose={() => handleSetShowScriptValueDialogTitle(false)}
            onSave={value => setValue('titleJsValue', value, { shouldDirty: true })}
          />
        )}
        <Box sx={{ width: '100%' }}>
          <form>
            <FormProvider {...methods}>
              <Grid container px={1} py={0.5}>
                <Grid container>
                  <Typography py={0.5} variant='h5'>
                    {t(`viewsCreate.${modelType}.${MODEL_TYPE.FORM}`)}
                  </Typography>
                </Grid>
                <Grid container display='flex' justifyContent='space-between'>
                  <Grid item md={5.9}>
                    <Typography py={0.5} variant='h5'>
                      {t('viewsCreate.general')}
                    </Typography>
                    <FormGenerator inputs={newModelInputsConfig} mainGridSpacing={0} />
                  </Grid>
                  <Grid item md={5.9}>
                    <DataSource
                      isDisabledObject={!!code || !!copyCode}
                      isLoading={isFormLoading}
                      objects={objects}
                    />
                    <Integration loading={isFormLoading} views={viewsData?.data} />
                    <FormInput
                      autocompleteOptions={contextMenuOptions}
                      inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
                      label={t('formCreate.contextMenu.label')}
                      name='contextMenu'
                      placeholder={t('formCreate.contextMenu.placeholder')}
                    />
                  </Grid>
                </Grid>
                <Grid item md>
                  <CommandPickerController objectCode={selectedObjectCode} />
                </Grid>
              </Grid>
            </FormProvider>
          </form>
          <Grid container justifyContent={'flex-end'}>
            <GeneralButtons
              cancelText={t('viewOrFormCreate.cancelBtnText')}
              saveDisabled={!isDirty}
              saveText={t('viewOrFormCreate.saveBtnText')}
              onCancel={handleCancel}
              onSave={handleSave}
            />
          </Grid>
          <Box sx={{ pl: 1, pt: 0 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={currentTab} onChange={handleTabChange}>
                <Tab
                  label={`${t(`viewsCreate.forms.name`)}`}
                  tabIndex={0}
                  sx={{
                    pl: 0,
                  }}
                />
                <Tab
                  label={`${t(`viewsCreate.forms.config`)}`}
                  tabIndex={1}
                  sx={{
                    pl: 0,
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel index={0} value={currentTab}>
              <SwitchTable
                disablePagination
                isCrud
                btnDisabled={!isEdit && (!isSuccessCreate || isSuccessUpdate)}
                btnText={t(`viewsCreate.forms.btnText`)}
                columns={mainTable.columns}
                createAllBtnText={t('configEntity.allForms.createAll')}
                createAllRequiredBtnText={t('configEntity.allForms.createAllMandatory')}
                isCreateAllLoading={createAllFieldsLoading}
                isCreateMandatoryLoading={createMandatoryFieldsLoading}
                loading={formIsLoading}
                rows={mainTable.rows}
                showActionsColumn={!copyCode}
                showDialog={showDialog}
                type={MODEL_TYPE.FORM}
                updateRow={handleUpdateColumnTitle}
                containerSx={{
                  '.MuiTableBody-root > div': {
                    height: '400px !important',
                  },
                }}
                onCreateAllEntityFields={handleCreateAllFields}
                onDelete={deleteField}
                onEdit={params => handleEdit(params.row.original.id)}
                onShowDialog={handleOpenDialog}
              />
            </TabPanel>
            <TabPanel contentSx={{ p: 0 }} index={1} value={currentTab}>
              <FieldsConfig formCode={code} />
            </TabPanel>
          </Box>
        </Box>
      </PageContentLayout>
    </PageContext.Provider>
  )
}

import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { VISIBLE_HIDDEN } from '@constants'

import { AllStyleSettings } from './components/AllStyleSettings'
import { useConfigStyleSettings } from './hooks/useConfigStyleSettings'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ConfigStyleSettings: FC = () => {
  const { t } = useTranslation()

  const {
    state: { allStyleSettings, currentSort },
    data: { isAllStyleSettings, methods, isLoadingStyleSettingsData },
    handlers: {
      handleCreateStyleSetting,
      handleEditStyleSetting,
      handleDeleteStyleSetting,
      handleSetSorting,
    },
  } = useConfigStyleSettings()

  const contextValue = {
    allStyleSettings,
    isLoadingStyleSettingsData,
    currentSort,
    onCreateStyleSetting: handleCreateStyleSetting,
    onEditStyleSetting: handleEditStyleSetting,
    onDeleteStyleSetting: handleDeleteStyleSetting,
    onSetSorting: handleSetSorting,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <Outlet />
      <Box sx={{ ...(!isAllStyleSettings && VISIBLE_HIDDEN) }}>
        <PageTitle
          end={false}
          start={
            <>
              <Typography display='inline' sx={{ mr: 1, ml: 1 }} variant='h6'>
                {t('pageTitle.styleSettings')}
              </Typography>
            </>
          }
        />
        <PageContentLayout>
          <AllStyleSettings methods={methods} />
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}

import { FC, MouseEvent, useMemo, useState } from 'react'
import { t } from 'i18next'
// import { MuiEvent } from '@microservices/wiskey-react-components/DataGrid'
import { MuiEvent } from '@microservices/wiskey-react-components'

import { ConfiguredEntity, ConfiguredEntityProps } from '@pages/ConfiguredEntity'
import { OpenFormProps } from '@pages/ConfiguredEntity/types'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import { openFormDialog } from '@redux/reducers/dialogWindowManager.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useAppSelector } from '@hooks'
import { getInitialPositionWindow, transformCommonParametersToValues } from '@helpers'
import { CLICK_EVENT_TYPE, COMMON_PARAMETERS, EMBEDDED_VIEW_ROWS_AMOUNT } from '@constants'

import { DIALOG_WINDOW_TYPE, EntityType } from '../../../../types'

export const EmbeddedListControl: FC<
  Omit<ConfiguredEntityProps, 'entity' | 'onSetEntity'>
> = props => {
  const dispatch = useAppDispatch()
  const [entity, setEntity] = useState<EntityType | null>(null)
  const { dialogWindows, selectedId } = useAppSelector(state => state.dialogWindowManager)
  const currentDialogWindow = dialogWindows.find(dialogWindow => dialogWindow.id === selectedId)

  const handleSetEntity = (value: EntityType | null) => setEntity(value)

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const handleOpenForm = (
    { entityType, ...formProps }: OpenFormProps,
    muiEvent: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => {
    if (
      (type === CLICK_EVENT_TYPE.ROW_CLICK && muiEvent.shiftKey) ||
      type === CLICK_EVENT_TYPE.CREATE_CLICK
    ) {
      const { id, formCode, viewCode, objectId, path, event } = formProps
      const initialPosition = getInitialPositionWindow(muiEvent)
      const openedFormsCount = dialogWindows.filter(
        dialog =>
          [DIALOG_WINDOW_TYPE.FORM, DIALOG_WINDOW_TYPE.VIEW].includes(dialog.type) && !dialog.hidden
      ).length
      const maxOpenedFormsCount = Number(initialCommonParameters.numberOfWindows)
      if (openedFormsCount === maxOpenedFormsCount && type !== CLICK_EVENT_TYPE.CREATE_CLICK) {
        dispatch(
          showMessage({
            type: 'info',
            text: t('error.dynamicFormsExceeded', { count: maxOpenedFormsCount }),
          })
        )

        return
      }

      dispatch(
        openFormDialog({
          id,
          parentDialogId: currentDialogWindow ? currentDialogWindow.id : null,
          type: DIALOG_WINDOW_TYPE.FORM,
          title: null,
          meta: {
            formCode,
            objectId,
            viewCode,
            path,
            event,
            globalId: id,
            entityType,
          },
          initialPosition,
        })
      )

      return
    }
  }

  return (
    <ConfiguredEntity
      {...props}
      entity={entity}
      handleOpenForm={handleOpenForm}
      pageSize={EMBEDDED_VIEW_ROWS_AMOUNT}
      onSetEntity={handleSetEntity}
    />
  )
}

import {
  CopyContextMenu,
  CREATEContextMenu,
  GETContextMenu,
  GETObjectDataParams,
  UPDATEContextMenu,
} from '@types'

import { commonAPI } from './common.api'

export const contextMenuApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchContextMenuList: build.query<GETContextMenu[], GETObjectDataParams | void>({
      query: params => ({
        url: 'ui/menu/context',
        params: {
          page: params?.page,
          size: params?.size,
          sort: params?.sort,
          searchPattern: params?.searchPattern,
          objectCode: params?.objectCode,
        },
      }),
      providesTags: ['ContextMenuList'],
    }),
    fetchContextMenuById: build.query<GETContextMenu, string | void>({
      query: (id: string) => ({
        url: `ui/menu/context/id/${id}`,
      }),
      providesTags: ['SingleContextMenu'],
    }),
    createContextMenu: build.mutation<GETContextMenu, CREATEContextMenu>({
      query: elements => ({
        url: `ui/menu/context`,
        method: 'POST',
        body: elements,
      }),
      invalidatesTags: ['ContextMenuList'],
    }),
    updateContextMenu: build.mutation<GETContextMenu, UPDATEContextMenu>({
      query: ({ elements }) => ({
        url: `ui/menu/context`,
        method: 'POST',
        body: elements,
      }),
      invalidatesTags: ['ContextMenuList', 'SingleContextMenu'],
    }),
    copyContextMenu: build.mutation<GETContextMenu, CopyContextMenu>({
      query: contextMenuData => ({
        url: 'ui/menu/context/copy',
        method: 'POST',
        body: contextMenuData,
      }),
      invalidatesTags: ['ContextMenuList'],
    }),
    deleteContextMenu: build.mutation<GETContextMenu, string | number>({
      query: id => ({
        url: `ui/menu/context/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContextMenuList'],
    }),
  }),
})

export const {
  useCopyContextMenuMutation,
  useCreateContextMenuMutation,
  useDeleteContextMenuMutation,
  useFetchContextMenuByIdQuery,
  useUpdateContextMenuMutation,
  useFetchContextMenuListQuery,
} = contextMenuApi

import {
  ContextGanttChartsDTO,
  ContextViewsDTO,
  GETAllContext,
  GETContextDTO,
  GETControlPanel,
  GETDefaultBackgroundEntity,
  GETObjectDataParams,
  GETSearchAssistantParams,
  GETVariablesById,
  POSTContextDTO,
  POSTCopyContext,
  POSTVariableDTO,
  SearchAssistantType,
} from '@types'

import { commonAPI } from './common.api'

export const modelContextAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllContext: build.query<GETAllContext, GETObjectDataParams | void>({
      query: params => ({
        url: 'ui/context',
        params: {
          searchPattern: params?.searchPattern,
        },
      }),
      providesTags: ['AllContexts'],
    }),
    fetchContextById: build.query<GETContextDTO, string | number | undefined>({
      query: id => ({
        url: `ui/context/id/${id}`,
      }),
      providesTags: ['SingleContext'],
    }),
    createContext: build.mutation<GETContextDTO, POSTContextDTO>({
      query: context => ({
        url: '/ui/context',
        method: 'POST',
        body: context,
      }),
      invalidatesTags: ['AllContexts'],
    }),
    updateContext: build.mutation<GETContextDTO, POSTContextDTO>({
      query: form => ({
        url: '/ui/context',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['AllContexts', 'SingleContext'],
    }),
    copyContext: build.mutation<GETContextDTO, POSTCopyContext>({
      query: contextData => ({
        url: 'ui/context/copy',
        method: 'POST',
        body: contextData,
      }),
      invalidatesTags: ['AllContexts'],
    }),
    deleteContext: build.mutation<GETContextDTO, number | string>({
      query: id => ({
        url: `/ui/context/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllContexts', 'SingleControlPanel'],
    }),
    fetchContextViewsById: build.query<ContextViewsDTO, string | number | undefined>({
      query: id => ({
        url: `ui/context/id/${id}/view`,
      }),
      providesTags: ['ContextViews'],
    }),
    updateContextViewsById: build.mutation<ContextViewsDTO, { id: number | string; data: any }>({
      query: ({ id, data }) => ({
        url: `ui/context/id/${id}/view`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['ContextViews'],
    }),
    fetchContextGanttChartsById: build.query<ContextGanttChartsDTO, string | number | undefined>({
      query: id => ({
        url: `ui/context/id/${id}/ganttChart`,
      }),
    }),
    updateContextGanttChartsById: build.mutation<
      ContextGanttChartsDTO,
      { id: number | string; data: any }
    >({
      query: ({ id, data }) => ({
        url: `ui/context/id/${id}/ganttChart`,
        method: 'POST',
        body: data,
      }),
    }),
    fetchContextControlPanelById: build.query<
      { id: number; panel: GETControlPanel },
      string | number | undefined
    >({
      query: id => ({
        url: `ui/context/id/${id}/controlPanel`,
      }),
      providesTags: ['SingleControlPanel', 'ContextControlPanel'],
    }),
    fetchContextControlPanelByIdNoCache: build.mutation<
      { id: number; panel: GETControlPanel },
      string | number | undefined
    >({
      query: id => ({
        url: `ui/context/id/${id}/controlPanel`,
      }),
    }),
    updateContextControlPanelById: build.mutation<
      GETControlPanel,
      { contextId: number | string; controlPanelId: number | null }
    >({
      query: ({ contextId, controlPanelId }) => ({
        method: 'POST',
        url: `ui/context/id/${contextId}/controlPanel`,
        body: { controlPanelId },
      }),
      invalidatesTags: ['ContextControlPanel'],
    }),
    fetchSearchAssistantByContextId: build.query<SearchAssistantType, GETSearchAssistantParams>({
      query: ({ contextId }) => ({
        url: `ui/context/id/${contextId}/searchAssistant`,
      }),
    }),
    fetchContextVariablesById: build.query<GETVariablesById, string | number | undefined>({
      query: id => ({
        url: `ui/context/id/${id}/variables`,
      }),
      providesTags: ['ContextVariables'],
    }),
    fetchContextVariablesByIdNoCache: build.mutation<GETVariablesById, string | number>({
      query: id => ({
        url: `ui/context/id/${id}/variables`,
      }),
    }),
    updateContextVariablesById: build.mutation<
      GETVariablesById,
      { contextId: number; variables: POSTVariableDTO[] }
    >({
      query: ({ contextId, variables }) => ({
        url: `ui/context/id/${contextId}/variables`,
        method: 'POST',
        body: variables,
      }),
      invalidatesTags: ['ContextVariables'],
    }),
    fetchContextDefaultEntityBackgroundById: build.query<
      GETDefaultBackgroundEntity[],
      string | number | undefined
    >({
      query: id => ({
        url: `ui/context/id/${id}/background`,
      }),
      providesTags: ['ContextDefaultEntity'],
    }),
    updateContextDefaultEntityBackgroundById: build.mutation<
      GETDefaultBackgroundEntity,
      { contextId: number; backgroundEntity: GETDefaultBackgroundEntity[] }
    >({
      query: ({ contextId, backgroundEntity }) => ({
        url: `/ui/context/id/${contextId}/background`,
        method: 'POST',
        body: backgroundEntity,
      }),
      invalidatesTags: ['ContextDefaultEntity'],
    }),
  }),
})

export const {
  useFetchAllContextQuery,
  useFetchContextByIdQuery,
  useCreateContextMutation,
  useUpdateContextMutation,
  useCopyContextMutation,
  useDeleteContextMutation,
  useFetchContextViewsByIdQuery,
  useUpdateContextViewsByIdMutation,
  useFetchContextGanttChartsByIdQuery,
  useUpdateContextGanttChartsByIdMutation,
  useFetchContextControlPanelByIdQuery,
  useUpdateContextControlPanelByIdMutation,
  useFetchContextControlPanelByIdNoCacheMutation,
  useFetchSearchAssistantByContextIdQuery,
  useFetchContextVariablesByIdQuery,
  useFetchContextVariablesByIdNoCacheMutation,
  useUpdateContextVariablesByIdMutation,
  useFetchContextDefaultEntityBackgroundByIdQuery,
  useUpdateContextDefaultEntityBackgroundByIdMutation,
} = modelContextAPI

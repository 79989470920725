import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@microservices/wiskey-react-components'

import { useDebounce } from '@hooks'
import { generalFieldStylesByMode } from '@helpers'
import { QUICK_SEARCH_PATTERN_DELAY } from '@constants'

type QuickSearchInDDWProps = {
  setGlobalFilter: (value: string) => void
}

export const QuickSearchInDDW: FC<QuickSearchInDDWProps> = ({ setGlobalFilter }) => {
  const [value, setValue] = useState('')

  const { t } = useTranslation()

  const debounceValue = useDebounce(value, QUICK_SEARCH_PATTERN_DELAY)

  useEffect(() => {
    setGlobalFilter(debounceValue)
  }, [debounceValue])

  return (
    <TextField
      placeholder={t('configViews.allTab.search')}
      value={value}
      sx={theme => ({
        ...generalFieldStylesByMode(theme, undefined, undefined, undefined, true),
        mb: 1,
        mt: 0.5,
      })}
      onChange={({ target: { value } }) => setValue(value)}
    />
  )
}

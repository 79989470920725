import { useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@microservices/wiskey-react-components'
import { Add } from '@mui/icons-material'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import { Box, Button, IconButton } from '@mui/material'

import { AutocompleteOption } from '@types'

import { OptionsFilter } from '@gantt/types'

import { PickerItem } from './PickerItem'

type Props = {
  name: string
  disabled: boolean
  isFlatOptions: boolean
  watchedObject?: AutocompleteOption<string> | null
  label: string
  prefix?: string
  optionsFilter?: OptionsFilter
}

export const PickerFieldArrayController = ({
  name,
  disabled = false,
  isFlatOptions,
  watchedObject,
  label,
  prefix,
  optionsFilter,
}: Props) => {
  const { control, watch, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({ control, name })
  const { t } = useTranslation()
  const [isShowClearModal, setIsShowClearModal] = useState(false)

  const handleAppend = () => {
    append({ pathStr: '', pathArray: [], field: '' })
  }

  useEffect(() => {
    if (fields.length === 0) {
      setValue(name, [{ pathStr: '', pathArray: [], field: '' }])
    }
  }, [])

  const handleRemove = (index: number) => {
    if (fields.length > 1) {
      remove(index)
    }
  }

  const handleDeleteAll = () => {
    setValue(name, [{ pathStr: '', pathArray: [], field: '' }])
    handleCloseClearConfirmModal()
  }

  const handleCloseClearConfirmModal = () => {
    setIsShowClearModal(false)
  }

  const handleOpenConfirmModal = () => {
    setIsShowClearModal(true)
  }

  return (
    <>
      {fields.map((field, index) => {
        return (
          <PickerItem
            key={field.id}
            embeddedObjectPickerControllerName={`${name}.${index}.pathArray`}
            field={field}
            fieldName={`${name}.${index}.field`}
            index={index}
            isDisabled={disabled}
            isFlatOptions={isFlatOptions}
            isSingleField={fields.length === 1}
            label={label}
            name={`${name}.${index}.pathStr`}
            optionsFilter={optionsFilter}
            prefix={prefix}
            watchValue={watch(`${name}.${index}`)}
            watchedObject={watchedObject}
            onRemove={() => handleRemove(index)}
          />
        )
      })}
      <Box display='flex' gap={2} justifyContent='flex-end'>
        <Button onClick={handleAppend}>
          <Add fontSize='small' />
          {t('ganttCreate.common.add')}
        </Button>
        <IconButton size='small' sx={{ width: 35 }} onClick={handleOpenConfirmModal}>
          <DeleteSweepIcon />
        </IconButton>
      </Box>
      {isShowClearModal && (
        <ConfirmModal
          actionBtnText={t('modal.clearFieldArray.btn')}
          containerStyle={{ px: 2.5, py: 2.5, borderRadius: 0 }}
          isShow={isShowClearModal}
          text={t('modal.clearFieldArray.warning')}
          title={t('modal.clearFieldArray.title')}
          onClose={handleCloseClearConfirmModal}
          onConfirm={handleDeleteAll}
        />
      )}
    </>
  )
}

import { SyntheticEvent } from 'react'
import i18next from 'i18next'
import { Box } from '@mui/material'

import { Tab, TabPanel, Tabs } from '@components/Tabs'

import { CONTEXT_MODEL_TYPE, ROUTES, VISIBLE_HIDDEN } from '@constants'

import { Variables } from './Variables/Variables'
import { ControlPanels } from './ControlPanels'
import { DefaultEntity } from './DefaultEntity'
import { GanttCharts } from './GanttCharts'
import { SearchAssistant } from './SearchAssistant'
import { Views } from './Views'

export const tabs = [
  {
    modelType: CONTEXT_MODEL_TYPE.VIEW,
    label: i18next.t('contexts.tabs.views'),
    addButtonText: i18next.t('contexts.addButton.view'),
    createRoute: ROUTES.CONFIG_VIEWS_CREATE,
    component: <Views />,
  },
  {
    modelType: CONTEXT_MODEL_TYPE.GANTT_CHART,
    label: i18next.t('contexts.tabs.ganttCharts'),
    addButtonText: i18next.t('contexts.addButton.ganttChart'),
    createRoute: ROUTES.CONFIG_GANTT_CREATE,
    component: <GanttCharts />,
  },
  {
    modelType: CONTEXT_MODEL_TYPE.CONTROL_PANEL,
    label: i18next.t('contexts.tabs.controlPanels'),
    addButtonText: i18next.t('contexts.addButton.controlPanel'),
    createRoute: ROUTES.CONFIG_CONTROL_PANELS_CREATE,
    component: <ControlPanels />,
  },
  {
    modelType: CONTEXT_MODEL_TYPE.VARIABLE,
    label: i18next.t('contexts.tabs.variables'),
    addButtonText: i18next.t('contexts.addButton.variable'),
    component: <Variables />,
  },
  {
    modelType: CONTEXT_MODEL_TYPE.SEARCH_ASSISTANT,
    label: i18next.t('contexts.tabs.searchAssistant'),
    component: <SearchAssistant />,
  },
  {
    modelType: CONTEXT_MODEL_TYPE.DEFAULT_BACKGROUND_ENTITY,
    label: i18next.t('contexts.tabs.workingArea'),
    component: <DefaultEntity />,
  },
]

type ContextTabsProps = {
  currentTab: number
  handleTabChange: ((event: SyntheticEvent<Element, Event>, value: number) => void) | undefined
}

export const ContextTabs = ({ currentTab, handleTabChange }: ContextTabsProps) => (
  <Box>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {tabs.map(tab => (
          <Tab key={tab.modelType} label={tab.label} />
        ))}
      </Tabs>
    </Box>
    <Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={tab.modelType}
          index={index}
          sx={{ ...(currentTab !== index && VISIBLE_HIDDEN) }}
          value={currentTab}
        >
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  </Box>
)

import i18next from 'i18next'
import { OptionType } from 'src/types/menu'
import { v4 as uuid } from 'uuid'

import { ROUTES } from '../constants'

export const getSidebarDefaultOptions = (isAdmin: boolean): OptionType[] => {
  return [
    ...(isAdmin
      ? [
          {
            id: uuid(),
            title: i18next.t('sidebar.links.uiConfigs'),
            code: 'ui_configs',
            viewCode: '',
            hasChild: true,
            childrenMenu: [
              {
                id: uuid(),
                title: i18next.t('sidebar.links.contexts'),
                code: 'contexts',
                viewCode: ROUTES.CONFIG_CONTEXTS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.contextMenu'),
                code: 'context_menu',
                viewCode: ROUTES.CONFIG_CONTEXT_MENU,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.controlPanels'),
                code: 'control_panels',
                viewCode: ROUTES.CONFIG_CONTROL_PANELS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.views'),
                code: 'views',
                viewCode: ROUTES.CONFIG_VIEWS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.ganttChart'),
                code: 'gantt',
                viewCode: ROUTES.CONFIG_GANTT,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.forms'),
                code: 'forms',
                viewCode: ROUTES.CONFIG_FORMS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.listControls'),
                code: 'list_controls',
                viewCode: ROUTES.CONFIG_LIST_CONTROLS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.dropdownEntities'),
                code: 'dropdown_entities',
                viewCode: ROUTES.CONFIG_DROP_DOWN_ENTITY,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.dictionary'),
                code: 'dictionary',
                viewCode: ROUTES.DICTIONARY,
                hasChild: false,
                childrenMenu: [],
              },
              // {
              //   id: uuid(),
              //   title: i18next.t('sidebar.links.searchAssistant'),
              //   code: 'search_assistant',
              //   viewCode: ROUTES.SEARCH_ASSISTANT,
              //   hasChild: false,
              //   childrenMenu: [],
              // },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.variables'),
                code: 'variables',
                viewCode: ROUTES.VARIABLES,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.parameters'),
                code: 'parameters',
                viewCode: ROUTES.PARAMETERS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.styleSettings'),
                code: 'style_settings',
                viewCode: ROUTES.CONFIG_STYLE_SETTINGS,
                hasChild: false,
                childrenMenu: [],
              },
              {
                id: uuid(),
                title: i18next.t('sidebar.links.objectsStates'),
                code: 'objects_states',
                viewCode: ROUTES.CONFIG_OBJECTS_STATES,
                hasChild: false,
                childrenMenu: [],
              },
            ],
          },
        ]
      : []),
  ]
}

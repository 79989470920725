import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'

import { COMMON_PARAMETERS, GLOBAL_PARAMETER_CODE } from '@constants'

import {
  transformCommonParameters,
  transformCommonParametersData,
  transformTextParamsData,
} from '../helpers'

import { useHandlers } from './useHandlers'

export type CommonParametersFormType = {
  numberOfWindows: AutocompleteOption | null
  timezone: AutocompleteOption | null
  pagination: AutocompleteOption | null
}

export const useParameters = () => {
  const methods = useForm<CommonParametersFormType>({
    defaultValues: {
      numberOfWindows: null,
      pagination: null,
      timezone: null,
    },
  })

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = methods

  const { data: globalColumnHeaderStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES,
  })

  const { data: globalColumnTextStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES,
  })

  const { data: commonParametersData } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.DATA,
  })

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialGlobalColumnHeaderStyles = useMemo(
    () => transformTextParamsData(globalColumnHeaderStyles),
    [globalColumnHeaderStyles]
  )

  const initialGlobalColumnTextStyles = useMemo(
    () => transformTextParamsData(globalColumnTextStyles),
    [globalColumnTextStyles]
  )

  const initialCommonParameters = useMemo(
    () => transformCommonParameters(commonParameters),
    [commonParameters]
  )

  const initialCommonParametersData = useMemo(
    () => transformCommonParametersData(commonParametersData || []),
    [commonParametersData]
  )

  useEffect(() => {
    if (initialCommonParameters) {
      reset({ ...initialCommonParameters })
    }
  }, [initialCommonParameters])

  const { handlers, state, data } = useHandlers({
    onSubmitCommonForm: handleSubmit,
    commonParameters,
    reset,
    isDirtyCommonParameters: isDirty,
    initialGlobalColumnHeaderStyles,
    initialGlobalColumnTextStyles,
    onSetValueCommonParameters: setValue,
  })

  return {
    data: {
      ...data,
      initialGlobalColumnHeaderStyles,
      initialGlobalColumnTextStyles,
      initialCommonParametersData,
      initialCommonParameters,
    },
    state: {
      ...state,
      methods,
    },
    handlers,
  }
}

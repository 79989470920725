export const tooltipAvailableKeys = ['name', 'data', 'itemType', 'items', 'filler', 'parameters']

export const contextMenuAvailableKeys = [
  'name',
  'command',
  'commands',
  'additionalData',
  'items',
  'isHeader',
  'isDivider',
]

import uniqueId from 'lodash/uniqueId'

import { GANTT_BIND_TYPE_LIST, GanttColumnForm } from '@gantt/components/GanttCreateOrEdit'

export const getGanttColumnDefaultValue = (): GanttColumnForm => {
  const code = uniqueId('column_')

  return {
    id: Date.now(),
    title: '',
    code,
    field: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: {
        field: '',
        pathStr: '',
        pathArray: [],
      },
    },
    valueType: null,
    pinning: false,
    pinnedColumn: null,
    useParameters: false,
    visibility: true,
    parameters: [],
  }
}

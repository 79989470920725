import uniqueId from 'lodash/uniqueId'

export const getDefaultValue = () => {
  const code = uniqueId('action_X_')

  return structuredClone({
    id: undefined,
    code,
    title: '',
    actionCode: null,
    eventCode: null,
    target: null,
    actionField: null,
  })
}

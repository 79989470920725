import { useContext, useEffect } from 'react'
import isEqual from 'lodash.isequal'

import { useFetchContextGanttChartsByIdQuery } from '@redux/api'
import { useFetchGanttChartQuery } from '@redux/api/ganttChart.api'

import { PageContext } from '../ContextCreateOrEdit'

export const useGanttCharts = () => {
  const { id, isCreate, methods } = useContext(PageContext)
  const { watch, setValue, getValues, reset } = methods
  const searchGanttChart = watch('searchGanttChart')
  const selectedGanttCharts = watch('selectedGanttCharts')
  const unselectedGanttCharts = watch('unselectedGanttCharts')

  const { data: contextGanttChartsById, isLoading: isLoadingContextGanttChartsById } =
    useFetchContextGanttChartsByIdQuery(id, {
      skip: !id,
      refetchOnMountOrArgChange: true,
    })

  const { data: ganttCharts, isLoading: isLoadingGanttCharts } = useFetchGanttChartQuery(
    {},
    {
      skip: !isCreate,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (contextGanttChartsById) {
      reset({
        ...getValues(),
        selectedGanttCharts: contextGanttChartsById.selectedGanttCharts,
        unselectedGanttCharts: contextGanttChartsById.unselectedGanttCharts,
      })
    }
  }, [contextGanttChartsById])

  useEffect(() => {
    if (ganttCharts) {
      reset({
        ...getValues(),
        selectedGanttCharts: [],
        unselectedGanttCharts: ganttCharts,
      })
    }
  }, [ganttCharts])

  useEffect(() => {
    if (searchGanttChart && searchGanttChart?.id && unselectedGanttCharts) {
      handleAddGanttChart(Number(searchGanttChart?.id))
      setValue('searchGanttChart', null, { shouldDirty: false })
    }
  }, [searchGanttChart])

  const handleAddGanttChart = (ganttChartId: number) => {
    const { selectedGanttCharts, unselectedGanttCharts } = getValues()
    const addedGanttChart = unselectedGanttCharts.find(ganttChart => ganttChart.id === ganttChartId)
    const addedGanttCharts = addedGanttChart ? [...selectedGanttCharts, addedGanttChart] : []
    const newUnselectedGanttCharts = unselectedGanttCharts.filter(
      ganttChart => ganttChart.id !== ganttChartId
    )

    setValue('selectedGanttCharts', addedGanttCharts, {
      shouldDirty: !isEqual(addedGanttCharts, contextGanttChartsById?.selectedGanttCharts),
    })
    setValue('unselectedGanttCharts', newUnselectedGanttCharts)
  }

  const handleDeleteGanttChart = (ganttChartId: number) => {
    const { selectedGanttCharts, unselectedGanttCharts } = getValues()
    const removedGanttChart = selectedGanttCharts.find(ganttChart => ganttChart.id === ganttChartId)
    const addedGanttCharts = selectedGanttCharts.filter(
      ganttChart => ganttChart.id !== ganttChartId
    )
    const newUnselectedGanttCharts = removedGanttChart
      ? [...unselectedGanttCharts, removedGanttChart].sort((a, b) => b.id - a.id)
      : []

    setValue('selectedGanttCharts', addedGanttCharts, {
      shouldDirty: !isEqual(addedGanttCharts, contextGanttChartsById?.selectedGanttCharts),
    })
    setValue('unselectedGanttCharts', newUnselectedGanttCharts)
  }

  return {
    data: {
      methods,
      isLoadingContextGanttChartsById,
      isLoadingGanttCharts,
    },
    state: {
      selectedGanttCharts,
      unselectedGanttCharts,
    },
    handlers: {
      handleAddGanttChart,
      handleDeleteGanttChart,
    },
  }
}

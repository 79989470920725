import {
  GETField,
  GETFieldById,
  GETUndefinedFields,
  PayloadCreateField,
  POSTCreateAllFields,
  POSTDefinedFields,
  POSTOrderFieldType,
  POSTVisibilityFieldType,
} from '@types'

import { commonAPI } from './common.api'

export const fieldAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchField: build.mutation<GETFieldById, number | string | void>({
      query: id => ({
        url: `/ui/field/${id}`,
      }),
    }),
    createField: build.mutation<GETField, PayloadCreateField>({
      query: column => ({
        url: '/ui/field',
        method: 'POST',
        body: { ...column, id: undefined },
      }),
      invalidatesTags: ['SingleForm', 'UndefinedFields', 'DefinedFields'],
    }),
    createAllFields: build.mutation<void, POSTCreateAllFields>({
      query: allFieldsParams => ({
        url: '/ui/field/addAll',
        method: 'POST',
        body: { ...allFieldsParams, onlyRequired: false },
      }),
      invalidatesTags: ['SingleForm', 'UndefinedFields', 'DefinedFields'],
    }),
    createMandatoryFields: build.mutation<void, POSTCreateAllFields>({
      query: allFieldsParams => ({
        url: '/ui/field/addAll',
        method: 'POST',
        body: { ...allFieldsParams, onlyRequired: true },
      }),
      invalidatesTags: ['SingleForm', 'UndefinedFields', 'DefinedFields'],
    }),
    updateField: build.mutation<GETField, PayloadCreateField>({
      query: column => ({
        url: '/ui/field',
        method: 'POST',
        body: column,
      }),
      invalidatesTags: ['SingleForm', 'UndefinedFields', 'DefinedFields', 'EnrichedContainer'],
    }),
    deleteField: build.mutation<void, number | string>({
      query: id => ({
        url: `/ui/field/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleForm', 'UndefinedFields', 'DefinedFields'],
    }),
    updateOrderFields: build.mutation<void, POSTOrderFieldType[]>({
      query: fields => ({
        url: '/ui/field/order',
        method: 'POST',
        body: fields,
      }),
      invalidatesTags: ['SingleForm'],
    }),
    updateVisibilityFieldById: build.mutation<void, POSTVisibilityFieldType>({
      query: field => ({
        url: '/ui/field/visibility',
        method: 'POST',
        body: field,
      }),
      invalidatesTags: ['SingleForm', 'EnrichedContainer'],
    }),
    fetchUndefinedFieldsByFormCode: build.query<
      GETUndefinedFields,
      { formCode: string; filter?: string }
    >({
      query: ({ formCode, filter }) => ({
        url: `/ui/field/formCode/${formCode}/undefined`,
        params: { filter },
      }),
      providesTags: ['UndefinedFields'],
    }),
    updateDefinedFieldsByFormCode: build.mutation<void, POSTDefinedFields>({
      query: ({ formCode, fields }) => ({
        url: `/ui/field/formCode/${formCode}/defined`,
        method: 'POST',
        body: fields,
      }),
      invalidatesTags: ['DefinedFields', 'UndefinedFields'],
    }),
  }),
})

export const {
  useCreateFieldMutation,
  useCreateAllFieldsMutation,
  useCreateMandatoryFieldsMutation,
  useDeleteFieldMutation,
  useFetchFieldMutation,
  useUpdateFieldMutation,
  useUpdateOrderFieldsMutation,
  useUpdateVisibilityFieldByIdMutation,
  useFetchUndefinedFieldsByFormCodeQuery,
  useUpdateDefinedFieldsByFormCodeMutation,
} = fieldAPI

import { FieldErrors, FieldPath } from 'react-hook-form'
import i18next from 'i18next'
import some from 'lodash/some'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'
import {
  AXIS_X_FORM_FIELD_KEYS,
  AXIS_Y_FORM_FIELD_KEYS,
  GENERAL_FORM_FIELD_KEYS,
  MAIN_OBJECT_FORM_FIELD_KEYS,
} from '@gantt/constants'
import { GANTT_CONFIG_STEPS } from '@gantt/types'

export const getIsStepFailed = (stepLabel: string, errors: FieldErrors<GanttFormValues>) => {
  const errorKeys = Object.keys(errors)

  function hasCommonItems(errors: string[], appliedFields: string[]) {
    return some(errors, r => appliedFields.includes(r))
  }

  switch (stepLabel) {
    case GANTT_CONFIG_STEPS.GENERAL:
      return hasCommonItems(errorKeys, GENERAL_FORM_FIELD_KEYS)
    case GANTT_CONFIG_STEPS.AXIS_Y:
      return hasCommonItems(errorKeys, AXIS_Y_FORM_FIELD_KEYS)
    case GANTT_CONFIG_STEPS.AXIS_X:
      return hasCommonItems(errorKeys, AXIS_X_FORM_FIELD_KEYS)
    case GANTT_CONFIG_STEPS.MAIN_OBJECT:
      return hasCommonItems(errorKeys, MAIN_OBJECT_FORM_FIELD_KEYS)
    default:
      return false
  }
}

export const getStepErrorFields = (stepLabel: string, errors: FieldErrors<GanttFormValues>) => {
  const errorKeys = Object.keys(errors) as Array<FieldPath<GanttFormValues>>
  const timelineErrors = errors.timeline || []

  switch (stepLabel) {
    case GANTT_CONFIG_STEPS.GENERAL:
      return errorKeys
        .filter(element => GENERAL_FORM_FIELD_KEYS.includes(element))
        .map(item => i18next.t(`ganttCreate.generalForm.stepFieldErrors.${item}`))
    case GANTT_CONFIG_STEPS.AXIS_Y:
      return Object.keys(errors?.resource || {})?.map(item =>
        i18next.t(`ganttCreate.resourceForm.stepFieldErrors.${item}`)
      )
    case GANTT_CONFIG_STEPS.AXIS_X:
      return (
        (timelineErrors as []).map((item, index) => {
          if (item) {
            return `Bar ${index}: ${Object.keys(item).map(item =>
              i18next.t(`ganttCreate.timelineForm.stepFieldErrors.${item}`)
            )}`
          }

          return undefined
        }) || []
      )
    case GANTT_CONFIG_STEPS.MAIN_OBJECT:
      return Object.keys(errors?.sort || {})?.map(item =>
        i18next.t(`ganttCreate.mainObjectForm.stepFieldErrors.${item}`)
      )
    default:
      return []
  }
}

import i18next from 'i18next'
import get from 'lodash/get'

import { getParamsString } from '@helpers'

import {
  ConfigField,
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  GanttFormFilledCommands,
  SectionType,
} from '@gantt/components/GanttCreateOrEdit/types'

const accessorFnActionValue = (originalRow: GanttActionType) => {
  const bindType = originalRow.actionField?.bindType
  const value = getParamsString(originalRow.actionField)

  switch (bindType) {
    case GANTT_BIND_TYPE_LIST.FORM:
      return value?.id
    case GANTT_BIND_TYPE_LIST.JSON:
      return JSON.stringify(value)
    case GANTT_BIND_TYPE_LIST.JS:
      return value
    case GANTT_BIND_TYPE_LIST.COMMANDS:
      return (value as GanttFormFilledCommands).map(item => item.name)

    default:
      return value
  }
}

const accessorFnSegmentValue = (originalRow: SectionType) => {
  const bindType = originalRow?.title?.bindType
  const value = get(originalRow, ['title', bindType || GANTT_BIND_TYPE_LIST.STATIC])

  switch (bindType) {
    case GANTT_BIND_TYPE_LIST.FIELD_ARRAY:
      return (value as ConfigField[]).map(item => item.pathStr).join(', ')
    default:
      return value?.pathStr ?? value
  }
}

export const GANTT_CONFIG_COLUMNS = [
  {
    accessorKey: 'code',
    header: i18next.t('ganttCreate.columnForm.code'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title',
    header: i18next.t('ganttCreate.columnForm.title'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'bindType',
    header: i18next.t('ganttCreate.columnForm.bindType'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'field.field.pathStr',
    header: i18next.t('ganttCreate.columnForm.value'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'valueType',
    header: i18next.t('ganttCreate.columnForm.valueType'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'pinnedColumn',
    header: i18next.t('ganttCreate.columnForm.pinnedColumn'),
    meta: { flex: 1 },
  },
]

export const GANTT_CONFIG_ACTION_COLUMNS = [
  {
    accessorKey: 'code',
    header: i18next.t('ganttCreate.actionForm.code'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title',
    header: i18next.t('ganttCreate.actionForm.title'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'eventCode',
    header: i18next.t('ganttCreate.actionForm.eventCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'actionCode',
    header: i18next.t('ganttCreate.actionForm.actionCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'target',
    header: i18next.t('ganttCreate.actionForm.columnTarget'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'actionField.bindType',
    header: i18next.t('ganttCreate.actionForm.valueType'),
    meta: { flex: 1 },
  },
  {
    header: i18next.t('ganttCreate.actionForm.value'),
    meta: { flex: 1 },
    accessorFn: accessorFnActionValue,
  },
]

export const GANTT_CONFIG_ACTION_TIMELINE_COLUMNS = [
  {
    accessorKey: 'code',
    header: i18next.t('ganttCreate.actionForm.code'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title',
    header: i18next.t('ganttCreate.actionForm.title'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'eventCode',
    header: i18next.t('ganttCreate.actionForm.eventCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'actionCode',
    header: i18next.t('ganttCreate.actionForm.actionCode'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'target',
    header: i18next.t('ganttCreate.actionForm.segmentTarget'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'actionField.bindType',
    header: i18next.t('ganttCreate.actionForm.valueType'),
    meta: { flex: 1 },
  },
  {
    header: i18next.t('ganttCreate.actionForm.value'),
    meta: { flex: 1 },
    accessorFn: accessorFnActionValue,
  },
]

export const GANTT_SEGMENT_COLUMNS = [
  {
    accessorKey: 'link.systemName',
    header: i18next.t('ganttCreate.timelineForm.systemName'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'link.axisX.field.pathStr',
    header: i18next.t('ganttCreate.objectLinks'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'datetimeStart.field.pathStr',
    header: i18next.t('ganttCreate.timelineForm.dateTimeStart'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'datetimeEnd.field.pathStr',
    header: i18next.t('ganttCreate.timelineForm.dateTimeEnd'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'duration.field.pathStr',
    header: i18next.t('ganttCreate.timelineForm.duration'),
    meta: { flex: 1 },
  },
  {
    accessorKey: 'title.static.pathStr',
    header: i18next.t('ganttCreate.timelineForm.title'),
    meta: { flex: 1 },
    accessorFn: accessorFnSegmentValue,
  },
]

export const GANTT_LIST_COLUMNS = [
  {
    field: 'id',
    headerName: i18next.t('configGantt.ganttList.id'),
  },
  {
    field: 'title',
    headerName: i18next.t('configGantt.ganttList.title'),
    flex: 1,
  },
  {
    field: 'resourceObjectCode',
    headerName: i18next.t('configGantt.ganttList.resourceObjectCode'),
    flex: 1,
  },
  {
    field: 'timelineObjectCodes',
    headerName: i18next.t('configGantt.ganttList.timelineObjectCode'),
    flex: 1,
    valueFormatter: (originalRow: any) => {
      const value: string[] = originalRow.value

      return value.map(item => item).join(', ')
    },
  },
]

export enum ELEMENT_TYPE {
  COMMAND = 'COMMAND',
  GANTT_CHART = 'GANTT_CHART',
  GROUP = 'GROUP',
  SEARCH_ASSISTANT = 'SEARCH_ASSISTANT',
  VIEW = 'VIEW',
  FORM = 'FORM',
  FILE_UPLOAD = 'FILE_UPLOAD',
}

export const ELEMENT_VALUES = {
  [ELEMENT_TYPE.COMMAND]: 'COMMAND',
  [ELEMENT_TYPE.GANTT_CHART]: 'GANTT CHART',
  [ELEMENT_TYPE.GROUP]: 'GROUP',
  [ELEMENT_TYPE.SEARCH_ASSISTANT]: 'SEARCH ASSISTANT',
  [ELEMENT_TYPE.VIEW]: 'VIEW',
  [ELEMENT_TYPE.FORM]: 'FORM',
  [ELEMENT_TYPE.FILE_UPLOAD]: 'FILE UPLOAD',
}

export enum MENU_TYPE {
  BUTTON = 'BUTTON',
  ICON = 'ICON',
  DIVIDER = 'DIVIDER',
}

export const MENU_TYPE_VALUES = {
  [MENU_TYPE.BUTTON]: 'BUTTON',
  [MENU_TYPE.ICON]: 'ICON',
  [MENU_TYPE.DIVIDER]: 'DIVIDER',
}

export const ELEMENT_TYPE_OPTIONS = Object.values(ELEMENT_TYPE).map(value => ({
  id: value,
  label: ELEMENT_VALUES[value],
}))

export const MENU_TYPE_OPTIONS = Object.values(MENU_TYPE).map(value => ({
  id: value,
  label: MENU_TYPE_VALUES[value],
}))

export const enum OPERATION_TYPE {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export const enum ELEMENT_TYPE_PANEL {
  PANEL = 'PANEL',
  LINE = 'LINE',
  ITEM = 'ITEM',
}

import { createContext, FC, ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, SxProps } from '@mui/material'

import { AppBar } from '@components/AppBar'
import { AppFooter } from '@components/AppFooter'
import { Sidebar } from '@components/Sidebar'

import { useAuth, useSidebar } from '@hooks'
import { ROUTES, USER_ROLES } from '@constants'

type AppLayoutContextType = {
  sidebarOpen: boolean
}

type AppLayoutProps = {
  children: ReactNode
}

export const AppLayoutContext = createContext<AppLayoutContextType>({ sidebarOpen: true })

export const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const { pathname } = useLocation()
  const {
    sidebarOpen,
    handleOpenSidebar,
    sidebarOptions,
    loadingAppbarOptions,
    appbarOptions,
    handleSelected,
  } = useSidebar()
  const { hasRole } = useAuth()

  const isChooseContext = pathname === ROUTES.CHOOSE_CONTEXT
  const isAdmin = useMemo(() => hasRole([USER_ROLES.ADMIN]), [])

  const sx: SxProps = {
    px: '0px !important',
    my: 0,
    height: '100%',
    overflow: 'hidden',
  }

  const container = (
    <Container maxWidth={false} sx={sx}>
      <Box height={'100%'} overflow={'auto'}>
        {children}
      </Box>
    </Container>
  )

  if (isChooseContext) {
    return container
  }

  return (
    <AppLayoutContext.Provider value={{ sidebarOpen }}>
      <AppBar
        isAdmin={isAdmin}
        loadingOptions={loadingAppbarOptions}
        open={sidebarOpen}
        options={appbarOptions}
        setOpen={handleOpenSidebar}
      />
      {isAdmin ? (
        <Sidebar
          open={sidebarOpen}
          options={sidebarOptions}
          setOpen={handleOpenSidebar}
          onSelected={handleSelected}
        >
          {container}
        </Sidebar>
      ) : (
        container
      )}
      <AppFooter hasSidebar={sidebarOpen} />
    </AppLayoutContext.Provider>
  )
}

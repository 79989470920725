import { MouseEvent } from 'react'
import {
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
} from '@microservices/wiskey-react-components'
import { Close, ContentCopy, Delete, Done, Edit } from '@mui/icons-material'
import { Checkbox } from '@mui/material'

import { DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'

type HandlerActionClick = (
  params: GridCellParams<any, any, any>,
  event: MouseEvent<HTMLButtonElement>
) => void

type DataGridActionsColumnParams = {
  onSave?: HandlerActionClick
  onCancel?: HandlerActionClick
  onCopy?: HandlerActionClick
  onEdit?: HandlerActionClick
  onDelete?: HandlerActionClick
  onAdd?: HandlerActionClick
  columnDef?: GridEnrichedColDef
  headerName?: string
  minWidth?: number
  disabledDelete?: boolean
  editRowId?: number | string | null
}

export const generateDataGridActionsColumnForContext = <T extends { id: string | number }>({
  onSave,
  onCancel,
  onCopy,
  onEdit,
  onDelete,
  onAdd,
  columnDef,
  headerName,
  minWidth,
  disabledDelete,
  editRowId,
}: DataGridActionsColumnParams): GridEnrichedColDef => {
  const editOrSaveActionCell = (params: GridCellParams<T>) => {
    if (editRowId && params.id === editRowId) {
      return [
        <GridActionsCellItem
          key={params.id}
          icon={<Done color='info' />}
          label={'Save'}
          showInMenu={false}
          onClick={event => onSave?.(params, event)}
        />,
      ]
    }

    return [
      <GridActionsCellItem
        key={params.id}
        icon={<Edit />}
        label={'Edit'}
        showInMenu={false}
        onClick={event => onEdit?.(params, event)}
      />,
    ]
  }

  const cancelOrDeleteActionCell = (params: GridCellParams<T>) => {
    if (editRowId && params.id === editRowId) {
      return [
        <GridActionsCellItem
          key={params.id}
          icon={<Close color='error' />}
          label={'Cancel'}
          showInMenu={false}
          onClick={event => onCancel?.(params, event)}
        />,
      ]
    }

    return [
      <GridActionsCellItem
        key={params.id}
        disabled={disabledDelete}
        icon={<Delete />}
        label='Delete'
        showInMenu={false}
        onClick={event => onDelete?.(params, event)}
      />,
    ]
  }

  return {
    field: 'actions',
    type: 'actions',
    align: 'center',
    minWidth: minWidth || 120,
    resizable: false,
    hideable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    pinnedColumn: PINNED_COLUMN.RIGHT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    ...(headerName ? { headerName } : {}),
    getActions: (params: GridCellParams) => [
      ...(onAdd
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<Checkbox sx={{ padding: 0 }} />}
              label='Add'
              showInMenu={false}
              onClick={event => onAdd?.(params, event)}
            />,
          ]
        : []),
      ...(onCopy
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<ContentCopy />}
              label='Copy'
              showInMenu={false}
              onClick={event => onCopy?.(params, event)}
            />,
          ]
        : []),
      ...(onEdit ? editOrSaveActionCell(params) : []),
      ...(onDelete ? cancelOrDeleteActionCell(params) : []),
    ],
    ...columnDef,
  } as GridEnrichedColDef
}

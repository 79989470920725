import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@microservices/wiskey-react-components'
import { Button, Grid, SxProps, Theme } from '@mui/material'
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { AddActionDialog } from '@pages/EntityCreateOrEdit/components/AddActionDialog'
import { AddColumnDialog } from '@pages/EntityCreateOrEdit/components/AddColumnDialog'
import { AddFieldDialog } from '@pages/FormCreateOrEdit/components/AddFieldDialog'
import { AddGanttActionDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/AddGanttActionDialog'
import { AddGanttActionTimelinesDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/AddGanttActionTimelinesDialog'
import { AddGanttFieldDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/AddGanttFieldDialog'
import { AddSegmentDialog } from '@pages/GanttChart/components/GanttCreateOrEdit/components/GanttConfiguration/components/AddSegmentDialog'
import { AddObjectStateValueDialog } from '@pages/ObjectsStatesCreateOrEdit/components/AddObjectStateValueDialog'

import { LoadingButton } from '@components/LoadingButton'
import { SearchInTable } from '@components/SwitchTable/SearchInTable'

import { ENTITY, MODAL_TYPE, MODEL_TYPE } from '@constants'

import { ModalType, ShareActionType } from '../../types'
import { ReactTable } from '../ui/ReactTable'

type SwitchTableProps<T> = {
  columns: ColumnDef<T>[]
  rows: T[]
  btnText?: string
  loading?: boolean
  type: ShareActionType
  page?: number
  isCrud?: boolean
  pageSize?: number
  btnDisabled?: boolean
  disablePagination?: boolean
  useDragAndDrop?: boolean
  disableSort?: boolean
  showActionsColumn?: boolean
  showVisibilityColumn?: boolean
  onChangePage?: (event: ChangeEvent<unknown>, value: number) => void
  onEdit?: (props: CellContext<T, unknown>) => void
  onHide?: (props: CellContext<T, unknown>, checked: boolean) => void
  onDelete?: (id: string | number) => void
  onDropChange?: (rows: T[]) => T[]
  onShowDialog: (type: ModalType, id?: string | number, isAction?: boolean) => void
  showDialog: boolean
  showSearch?: boolean
  disabledVisibilityColumn?: boolean
  disabledDeleteActionColumn?: boolean
  disabledEditActionColumn?: boolean
  disabledCopyActionColumn?: boolean
  containerSx?: SxProps<Theme>
  updateRow?: (data: T) => void
  onCreateAllEntityFields?: (onlyRequired?: boolean) => void
  createAllBtnText?: string
  createAllRequiredBtnText?: string
  isCreateAllLoading?: boolean
  isCreateMandatoryLoading?: boolean
}

const getDialogType = (type: ShareActionType): MODEL_TYPE => {
  switch (type) {
    case MODEL_TYPE.VIEW:
      return MODEL_TYPE.COLUMN
    case MODEL_TYPE.FORM:
      return MODEL_TYPE.FIELD
    case MODEL_TYPE.ACTION:
      return MODEL_TYPE.ACTION
    case MODEL_TYPE.GANTT:
      return MODEL_TYPE.GANTT
    case MODEL_TYPE.GANTT_ACTION_RESOURCE:
      return MODEL_TYPE.GANTT_ACTION_RESOURCE
    case MODEL_TYPE.GANTT_ACTION_TIMELINES:
      return MODEL_TYPE.GANTT_ACTION_TIMELINES
    case MODEL_TYPE.GANTT_SEGMENT:
      return MODEL_TYPE.GANTT_SEGMENT
    case MODEL_TYPE.OBJECT_STATES:
      return MODEL_TYPE.OBJECT_STATES
    default:
      return MODEL_TYPE.COLUMN
  }
}

export const SwitchTable = <T extends { id: string | number }>({
  columns,
  rows,
  btnText,
  type,
  btnDisabled,
  loading,
  showDialog,
  useDragAndDrop,
  disablePagination,
  disableSort,
  showActionsColumn,
  showVisibilityColumn,
  onEdit,
  onHide,
  onDropChange,
  onDelete,
  onShowDialog,
  showSearch,
  disabledVisibilityColumn,
  disabledEditActionColumn,
  disabledDeleteActionColumn,
  disabledCopyActionColumn,
  containerSx,
  updateRow,
  onCreateAllEntityFields,
  createAllBtnText,
  createAllRequiredBtnText,
  isCreateAllLoading = false,
  isCreateMandatoryLoading = false,
}: SwitchTableProps<T>) => {
  const { t } = useTranslation()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateAllColumnsModal, setShowCreateAllColumnsModal] = useState(false)
  const [showCreateMandatoryColumnsModal, setShowCreateMandatoryColumnsModal] = useState(false)
  const [currentRowId, setCurrentRowId] = useState<number | string | null>()
  const [globalFilter, setGlobalFilter] = useState('')
  const dialogType: MODEL_TYPE = getDialogType(type)

  const handleClickDelete = (params?: CellContext<T, unknown>) => {
    if (params?.row.original.id && !showDeleteModal) {
      setCurrentRowId(params?.row.original.id)
      setShowDeleteModal(true)

      return
    }

    if (currentRowId) {
      onDelete?.(currentRowId)
      setCurrentRowId(null)
      setShowDeleteModal(false)
    }
  }

  const isShowSearch = (type: ShareActionType | ENTITY): boolean => {
    return (Object.values(ENTITY).includes(type as ENTITY) && showSearch) || type === 'forms'
  }

  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  const handleSetSearch = (pattern: string) => {
    setGlobalFilter(pattern)
  }

  const handleShowCreateAllColumnsModal = () => {
    setShowCreateAllColumnsModal(true)
  }

  const handleCloseCreateAllColumnsModal = () => {
    setShowCreateAllColumnsModal(false)
  }

  const handleClickCreateAll = () => {
    handleCloseCreateAllColumnsModal()
    onCreateAllEntityFields?.()
  }

  const handleShowCreateMandatoryColumnsModal = () => {
    setShowCreateMandatoryColumnsModal(true)
  }

  const handleCloseCreateMandatoryColumnsModal = () => {
    setShowCreateMandatoryColumnsModal(false)
  }

  const handleClickCreateMandatory = () => {
    handleCloseCreateMandatoryColumnsModal()
    onCreateAllEntityFields?.(true)
  }

  const renderDialog = useCallback(() => {
    switch (dialogType) {
      case 'field':
        return <AddFieldDialog />
      case 'action':
        return <AddActionDialog />
      case 'column':
        return <AddColumnDialog />
      case 'gantt':
        return <AddGanttFieldDialog />
      case 'gantt_action_resource':
        return <AddGanttActionDialog />
      case 'gantt_action_timelines':
        return <AddGanttActionTimelinesDialog />
      case 'gantt_segment':
        return <AddSegmentDialog />
      case 'object_states':
        return <AddObjectStateValueDialog />
    }
  }, [dialogType])

  return (
    <Grid container flexDirection='column' spacing={1}>
      <Grid
        container
        item
        alignItems='center'
        justifyContent='space-between'
        sx={{ pl: '0px !important' }}
      >
        <Grid item>
          {isShowSearch(type) && (
            <SearchInTable searchValue={globalFilter} setGlobalFilter={handleSetSearch} />
          )}
        </Grid>
        <Grid item>
          {createAllBtnText && (
            <LoadingButton
              disabled={btnDisabled}
              loading={isCreateAllLoading}
              sx={{ ml: 2 }}
              variant='contained'
              onClick={handleShowCreateAllColumnsModal}
            >
              {createAllBtnText}
            </LoadingButton>
          )}
          {createAllRequiredBtnText && (
            <LoadingButton
              disabled={btnDisabled}
              loading={isCreateMandatoryLoading}
              sx={{ ml: 2 }}
              variant='contained'
              onClick={handleShowCreateMandatoryColumnsModal}
            >
              {createAllRequiredBtnText}
            </LoadingButton>
          )}
          {btnText && (
            <Button
              disabled={btnDisabled}
              sx={{ ml: 2 }}
              variant='contained'
              onClick={() => onShowDialog(MODAL_TYPE.CREATE)}
            >
              {btnText}
            </Button>
          )}
        </Grid>
      </Grid>
      {showDialog && renderDialog()}
      <ConfirmModal
        actionBtnText={t('modal.delete.btn')}
        containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
        isShow={showDeleteModal}
        text={t('modal.delete.warning')}
        title={t('modal.delete.title')}
        onClose={handleCloseDeleteModal}
        onConfirm={handleClickDelete}
      />

      <ConfirmModal
        actionBtnText={t('modal.createAll.actionButton')}
        containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
        isShow={showCreateAllColumnsModal}
        title={t('modal.createAll.title')}
        text={t('modal.createAll.text', {
          entityField: dialogType,
        })}
        onClose={handleCloseCreateAllColumnsModal}
        onConfirm={handleClickCreateAll}
      />

      <ConfirmModal
        actionBtnText={t('modal.createMandatory.actionButton')}
        containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
        isShow={showCreateMandatoryColumnsModal}
        title={t('modal.createMandatory.title')}
        text={t('modal.createMandatory.text', {
          entityField: dialogType,
        })}
        onClose={handleCloseCreateMandatoryColumnsModal}
        onConfirm={handleClickCreateMandatory}
      />

      <Grid item sx={{ pl: '0px !important' }}>
        <ReactTable
          columns={columns}
          containerSx={containerSx}
          data={rows}
          disablePagination={disablePagination}
          disableSort={disableSort}
          disabledCopyActionColumn={disabledCopyActionColumn}
          disabledDeleteActionColumn={disabledDeleteActionColumn}
          disabledEditActionColumn={disabledEditActionColumn}
          disabledVisibilityColumn={disabledVisibilityColumn}
          globalFilter={globalFilter}
          loading={loading}
          setGlobalFilter={setGlobalFilter}
          showActionsColumn={showActionsColumn}
          showVisibilityColumn={showVisibilityColumn}
          updateRow={updateRow}
          useDragAndDrop={useDragAndDrop}
          onDelete={handleClickDelete}
          onDropChange={onDropChange}
          onEdit={onEdit}
          onHide={onHide}
        />
      </Grid>
    </Grid>
  )
}

import { FieldErrors } from 'react-hook-form'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import some from 'lodash/some'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'

export const getIsErrorTab = (errors: FieldErrors<GanttFormValues>, keys: string[] | string) => {
  if (isArray(keys)) {
    return some(keys, item => has(errors, item)) ? 'error' : undefined
  }

  return get(errors, keys) && 'error'
}

import { useContext, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import i18next from 'i18next'

import { FormInputsType } from '@pages/FormCreateOrEdit/types'

import { ACTION_CODE, EVENT_CODE_GANTT, GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { SelectOption } from '@types'

import {
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  PageContext,
} from '@gantt/components/GanttCreateOrEdit'
import { EMPTY_COMMAND_FORM } from '@gantt/constants'
import {
  getAvailableSegmentTarget,
  getAvailableXEvents,
  getBindTypeOptionsByStrings,
  getOptionByStr,
  isSegmentTarget,
} from '@gantt/helpers'

type useInputsParams = {
  actionTypeOptions: SelectOption[]
  eventOptions: SelectOption[]
  watchEventCode: string
  targetOptions: SelectOption[]
  methods: UseFormReturn<GanttActionType, any, undefined>
  watchActionCode?: ACTION_CODE
}

export const useInputs = ({
  actionTypeOptions,
  eventOptions,
  watchEventCode,
  targetOptions,
  methods,
  watchActionCode,
}: useInputsParams) => {
  const { modalType, currentActionTimelineList, currentRow, bindingValuesTimelineAction } =
    useContext(PageContext)
  const { setValue, resetField } = methods

  const handleChangeActionCode = (value: string | number) => {
    const getBind = () => {
      switch (value) {
        case ACTION_CODE.OPEN_CONTEXT_MENU:
          return GANTT_BIND_TYPE_LIST.JSON
        case ACTION_CODE.EXECUTE_COMMAND:
          return GANTT_BIND_TYPE_LIST.COMMANDS
        default:
          return null
      }
    }

    if (value === ACTION_CODE.EXECUTE_COMMAND) {
      setValue('actionField.commands', EMPTY_COMMAND_FORM)
    }

    const dictAction = bindingValuesTimelineAction?.find(item => item.code === value)
    const title = dictAction?.title || dictAction?.code || value

    const bind = getBind()

    setValue('title', String(title))
    resetField('eventCode')
    setValue('actionField.bindType', bind)
  }

  const eventOptionsList: SelectOption[] = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return getAvailableXEvents({
        actionEventsList: getBindTypeOptionsByStrings([EVENT_CODE_GANTT.ON_BAR_RIGHT_CLICK]),
        actionsList: currentActionTimelineList,
        currentRow: currentRow as GanttActionType,
      })
    }

    if (watchActionCode === ACTION_CODE.OPEN_TOOLTIP) {
      return [
        //todo next stage
        //on_tooltip_bar
        //on_tooltip_segment
      ]
    }

    if (watchActionCode === ACTION_CODE.EXECUTE_COMMAND) {
      return getAvailableXEvents({
        actionEventsList: eventOptions,
        actionsList: currentActionTimelineList,
        currentRow: currentRow as GanttActionType,
      })
    }

    if (watchActionCode === ACTION_CODE.ALLOW_EXECUTE_UI_ACTION) {
      return getAvailableXEvents({
        actionEventsList: getBindTypeOptionsByStrings([
          EVENT_CODE_GANTT.ON_SEGMENT_RESIZE,
          EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_RESIZE,
          EVENT_CODE_GANTT.ON_SEGMENT_INNER_MOVE,
          EVENT_CODE_GANTT.ON_SEGMENT_OUTER_MOVE,
          EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_INNER_MOVE,
          EVENT_CODE_GANTT.ON_BACKLOG_SEGMENT_OUTER_MOVE,
        ]),
        actionsList: currentActionTimelineList,
        currentRow: currentRow as GanttActionType,
      })
    }

    return getAvailableXEvents({
      actionEventsList: eventOptions,
      actionsList: currentActionTimelineList,
      currentRow: currentRow as GanttActionType,
    })
  }, [watchActionCode])

  const actionCodeOptions = useMemo(
    () => [
      ...actionTypeOptions,
      getOptionByStr(ACTION_CODE.OPEN_CONTEXT_MENU),
      // getOptionByStr(ACTION_CODE.OPEN_TOOLTIP), //todo next stage
      getOptionByStr(ACTION_CODE.EXECUTE_COMMAND),
      getOptionByStr(ACTION_CODE.ALLOW_EXECUTE_UI_ACTION),
    ],
    [actionTypeOptions]
  )

  const actionInputs: FormInputsType[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('ganttCreate.actionForm.actionInputs.code.placeholder'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      label: i18next.t('ganttCreate.actionForm.actionInputs.code.label'),
      disabled: modalType === 'edit',
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'actionCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('ganttCreate.actionForm.actionInputs.actionCode.label'),
      placeholder: i18next.t('placeholder.actionType'),
      selectOptions: actionCodeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.actionType') },
      onChangeSelect: handleChangeActionCode,
    },
    {
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: i18next.t('ganttCreate.actionForm.actionInputs.title.label'),
      name: 'title',
      placeholder: i18next.t('ganttCreate.actionForm.actionInputs.title.placeholder'),
    },
    {
      name: 'eventCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('ganttCreate.actionForm.actionInputs.eventCode.label'),
      placeholder: i18next.t('placeholder.event'),
      selectOptions: eventOptionsList,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.event') },
    },
    {
      name: 'targetRow',
      inputs: isSegmentTarget(watchEventCode)
        ? [
            {
              name: 'target',
              inputType: GENERATOR_INPUT_TYPE.SELECT,
              label: i18next.t('ganttCreate.actionForm.actionInputs.target.label'),
              selectOptions: getAvailableSegmentTarget({
                segmentTargets: targetOptions,
                actionsList: currentActionTimelineList,
                currentRow: currentRow as GanttActionType,
                currentEventCode: watchEventCode,
              }),
              rules: { required: true },
            },
          ]
        : [],
    },
  ]

  return actionInputs
}

import { GETCriteriaType } from 'src/types'

import { CriteriaType, FILTERS_SA_TYPE } from '@constants'

export const transformCriteriaFromServer = (criteria: GETCriteriaType): CriteriaType => {
  return {
    id: criteria.id,
    title: criteria.title,
    codeCriteria: criteria.code,
    order: criteria.order,
    storedValues: criteria.values
      ? criteria.values.map(criteria => ({
          ...criteria,
          searchRule: FILTERS_SA_TYPE.EQUAL,
          value: '',
        }))
      : [],
    isPinning: criteria.isPinning,
  }
}

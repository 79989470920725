import { MouseEvent } from 'react'
import {
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
} from '@microservices/wiskey-react-components'
import { Delete, Edit } from '@mui/icons-material'

import { DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'

type HandlerActionClick = (
  params: GridCellParams<any, any, any>,
  event: MouseEvent<HTMLButtonElement>
) => void

type DataGridActionsColumnParams = {
  onEdit?: HandlerActionClick
  onDelete?: HandlerActionClick
}

export const generateDataGridActionsColumnForStyleSettings = ({
  onEdit,
  onDelete,
}: DataGridActionsColumnParams): GridEnrichedColDef => {
  return {
    field: 'actions',
    type: 'actions',
    align: 'center',
    minWidth: 120,
    resizable: false,
    hideable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    pinnedColumn: PINNED_COLUMN.RIGHT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    getActions: (params: GridCellParams) => [
      ...(onEdit
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<Edit />}
              label='Edit'
              showInMenu={false}
              onClick={event => onEdit?.(params, event)}
            />,
          ]
        : []),
      ...(onDelete
        ? [
            <GridActionsCellItem
              key={params.id}
              icon={<Delete />}
              label='Delete'
              showInMenu={false}
              onClick={event => onDelete?.(params, event)}
            />,
          ]
        : []),
    ],
  } as GridEnrichedColDef
}

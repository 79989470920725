import { FieldPath } from 'react-hook-form'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'
import {
  AXIS_X_FORM_FIELD_KEYS,
  AXIS_Y_FORM_FIELD_KEYS,
  GENERAL_FORM_FIELD_KEYS,
  MAIN_OBJECT_FORM_FIELD_KEYS,
} from '@gantt/constants/keys'

export const EMPTY_COMMAND_FORM = [{ type: null, name: null }]

export const FORM_STEP_FIELDS: Record<number, Array<FieldPath<GanttFormValues>>> = {
  0: GENERAL_FORM_FIELD_KEYS,
  1: AXIS_Y_FORM_FIELD_KEYS,
  2: AXIS_X_FORM_FIELD_KEYS,
  3: MAIN_OBJECT_FORM_FIELD_KEYS,
}

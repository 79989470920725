import { FC, useMemo } from 'react'
import {
  NestedMenuItem as MUINestedMenuItem,
  NestedMenuItemProps as MUINestedMenuItemProps,
} from 'mui-nested-menu'
import { ListItemButton } from '@mui/material'

import { sxNestedMenu } from '@components/MenuPortal'

import { executeCode } from '@helpers'
import { MENU_POINT_TYPES } from '@constants'
import { MenuPointOptionType } from '@types'

import { getJsTitleItem } from './helpers/getJsTitleItem'
import { ActionData, MetaData } from '.'

export type NestedMenuItemProps = Omit<MUINestedMenuItemProps, 'parentMenuOpen'> & {
  menuitem: MenuPointOptionType
  dataTransfer: (Omit<ActionData, 'metaData'> & { metaData?: MetaData }) | null
}

export const NestedMenuItem: FC<NestedMenuItemProps> = ({ menuitem, dataTransfer, ...props }) => {
  const { conditionsDisabled, conditionsHidden, title, titleJsValue, jsTitle } = menuitem

  const isHeader = menuitem.menuPointType === MENU_POINT_TYPES.HEADER

  const disabled = useMemo(
    () =>
      conditionsDisabled
        ? Boolean(executeCode(conditionsDisabled, { srcObj: dataTransfer?.metaData?.srcObj || {} }))
        : false,
    [conditionsDisabled, dataTransfer?.metaData?.srcObj]
  )

  const hidden = useMemo(
    () =>
      conditionsHidden
        ? Boolean(executeCode(conditionsHidden, { srcObj: dataTransfer?.metaData?.srcObj || {} }))
        : false,
    [conditionsHidden, dataTransfer?.metaData?.srcObj]
  )

  const itemTitle = useMemo(
    () => getJsTitleItem({ title, jsTitle, titleJsValue, data: dataTransfer?.metaData }),
    [jsTitle, titleJsValue, title, dataTransfer?.metaData]
  )

  return (
    <MUINestedMenuItem
      component={ListItemButton}
      disabled={disabled}
      label={itemTitle}
      parentMenuOpen={!disabled}
      MenuProps={{
        anchorReference: 'anchorEl',
        sx: sxNestedMenu,
      }}
      sx={{
        pl: '40px',
        '& .MuiTypography-root': { px: 0 },
        ...(isHeader && { pl: '15px !important' }),
        ...(hidden && { display: 'none' }),
      }}
      {...props}
    />
  )
}

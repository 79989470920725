import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'

type Props = {
  children: ReactNode
}

export const AccordionConfigExamples = ({ children }: Props) => {
  const { t } = useTranslation()

  return (
    <Accordion sx={{ mb: 1 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t('ganttCreate.availableKeysVars.title')}
      </AccordionSummary>
      <AccordionDetails>
        <Typography color='gray' fontSize={12}>
          {t('ganttCreate.availableKeysVars.subtitle')}
        </Typography>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

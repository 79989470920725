export const getOptionRoute = <T>(
  firstOption: T & { id: number; title: string },
  secondOption?: T & { id: number; title: string }
) => {
  const option = `${firstOption.id}_${encodeURIComponent(firstOption.title)}`

  if (secondOption) {
    return `${option}+${secondOption.id}_${encodeURIComponent(secondOption.title)}`
  }

  return option
}

import i18next from 'i18next'

import { checkAvailableKeyInArrayOfObjects, ErrorValidJSType } from '@helpers'
import { ErrorCatch } from '@types'

import { checkReturnedValueType } from '@gantt/helpers/checkScriptReturnedValueType'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

type EC = ErrorCatch

export const checkValidGanttJSON = (
  value: string,
  returnValueType?: GANTT_SCRIPT_RETURN_TYPE,
  keys?: string[]
): ErrorValidJSType | undefined => {
  let parsedValue = null

  try {
    parsedValue = JSON.parse(value)
  } catch (e: EC) {
    return { error: true, message: e?.message || `${i18next.t('error.valueJSON')}` }
  }

  const returnedValueTypeError = checkReturnedValueType(parsedValue, returnValueType)

  if (returnedValueTypeError) {
    return returnedValueTypeError
  }

  const returnedValueObjectKeysError = checkAvailableKeyInArrayOfObjects(parsedValue, keys)

  if (returnedValueObjectKeysError) {
    return returnedValueObjectKeysError
  }

  if (!value) {
    return { error: false }
  }

  return
}

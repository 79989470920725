import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { matchPath, useLocation } from 'react-router-dom'

import { useFetchAllObjectQuery, useFetchAllObjectStatesQuery } from '@redux/api'

import { useDebounce } from '@hooks'
import { ROUTES, SEARCH_PATTERN_DELAY } from '@constants'
import { AutocompleteOption, FormValues, ShortObjectState } from '@types'

import { useHandlers } from './useHandlers'

type FormValues = {
  searchPattern: string
  selectedObject: AutocompleteOption<string> | null
}

const defaultValues = {
  searchPattern: '',
  selectedObject: null,
}

export const useObjectsStates = () => {
  const [allObjectsStates, setAllObjectsStates] = useState<ShortObjectState>([])
  const methods = useForm<FormValues>({ defaultValues })
  const { watch } = methods
  const watchSearchPattern = watch('searchPattern')
  const watchSelectedObject = watch('selectedObject')

  const debouncedSearchPattern = useDebounce(watchSearchPattern, SEARCH_PATTERN_DELAY)

  const { state, handlers } = useHandlers()

  const { data: objects } = useFetchAllObjectQuery()

  const { data: objectsStatesData, isFetching: isLoadingObjectsStates } =
    useFetchAllObjectStatesQuery(
      {
        searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
        objectCode: watchSelectedObject?.id ? watchSelectedObject?.id : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    )

  useEffect(() => {
    if (objectsStatesData) {
      setAllObjectsStates(objectsStatesData)
    }
  }, [objectsStatesData])

  const { pathname } = useLocation()

  const isAllObjectsStates = matchPath(ROUTES.CONFIG_OBJECTS_STATES, pathname)

  return {
    state: {
      ...state,
      allObjectsStates,
      methods,
    },
    data: {
      isAllObjectsStates,
      objects,
      isLoadingObjectsStates,
    },
    handlers: {
      ...handlers,
    },
  }
}

import i18next from 'i18next'

import { ActionForm } from '../hooks'

export const getDefaultValue = (): ActionForm => {
  return {
    code: '',
    title: '',
    actionCode: i18next.t('placeholder.actionType'),
    actionTitle: 'test',
    eventTitle: 'test',
    eventCode: i18next.t('placeholder.event'),
    params: '',
    columnToSort: null,
    json: false,
    objectFormCode: null,
    contextMenu: null,
    targetColumn: null,
    formObject: null,
  }
}

import { FC } from 'react'
import { TableCell as MUITableCell, TableCellProps as MUITableCellProps } from '@mui/material'

type TableCellProps = MUITableCellProps

export const TableCell: FC<TableCellProps> = ({ children, sx, ...props }) => {
  return (
    <MUITableCell
      {...props}
      component='div'
      sx={{
        minHeight: 32,
        maxHeight: 32,
        lineHeight: '32px',
        height: 32,
        padding: '0px 10px',
        display: 'block',
        flexGrow: 0,
        flexShrink: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        background: theme => theme.palette.background.paper,
        ...sx,
      }}
    >
      {children}
    </MUITableCell>
  )
}

import { RefObject, useContext, useState } from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import isEqual from 'lodash.isequal'

import {
  ExternalTextSettings,
  GetTextParamsHandle,
  TextSettingsStateType,
} from '@components/TextSettings'

import { getParametersForCreation } from '@helpers'
import {
  DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS,
  DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
  DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS,
  FIELD_VALUE_TYPE,
} from '@constants'
import { ColumnParametersType } from '@types'

import { GanttColumnForm, PageContext } from '@gantt/components/GanttCreateOrEdit'

type useHandlersParams = {
  watchUseParameters: boolean
  isDirty: boolean
  handleSubmit: UseFormHandleSubmit<GanttColumnForm>
  viewObjectCode: string
  columnHeaderSettingsRef: RefObject<GetTextParamsHandle>
  columnTextSettingsRef: RefObject<GetTextParamsHandle>
  initialColumnHeaderStyles: TextSettingsStateType | null
  initialColumnTextStyles: TextSettingsStateType | null
  valueType: FIELD_VALUE_TYPE | null
}

export const useHandlers = ({
  watchUseParameters,
  handleSubmit,
  columnHeaderSettingsRef,
  columnTextSettingsRef,
  initialColumnHeaderStyles,
  initialColumnTextStyles,
  valueType,
}: useHandlersParams) => {
  const [isDirtyHeader, setDirtyHeader] = useState(false)
  const [isDirtyText, setDirtyText] = useState(false)
  const [isShowResetConfirmModal, setShowResetConfirmModal] = useState(false)

  const { onSetCurrentColumn } = useContext(PageContext)

  const handleTextExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialColumnTextStyles) {
      const isDirty = !isEqual(object, initialColumnTextStyles)

      setDirtyText(isDirty)
    }
  }

  const handleHeaderExternalDirty = (object: ExternalTextSettings) => {
    if (object && initialColumnTextStyles) {
      const isDirty = !isEqual(object, initialColumnHeaderStyles)

      setDirtyHeader(isDirty)
    }
  }

  const handleSave = () => {
    handleSubmit((data: GanttColumnForm) => {
      const {
        id = Date.now(),
        code,
        title,
        objectValue,
        pinning,
        pinnedColumn: _pinnedColumn,
        field,
        useParameters,
        order,
        visibility,
      } = data
      const pinnedColumn = _pinnedColumn //todo типизировать нормально
      const columnHeaderCode = `column_header_styles_${code}`
      const columnTextCode = `column_text_styles_${code}`
      const columnHeaderParams = columnHeaderSettingsRef?.current?.getTextParams()
      const columnTextParams = columnTextSettingsRef?.current?.getTextParams()
      const parametersForCreation = watchUseParameters
        ? getParametersForCreation(columnHeaderCode, columnTextCode)
        : []
      const [headerParamsForCreation, textParamsForCreation] = parametersForCreation
      const newParams =
        watchUseParameters && parametersForCreation
          ? ([
              {
                ...headerParamsForCreation,
                param: { ...headerParamsForCreation.param, properties: columnHeaderParams },
              },
              {
                ...textParamsForCreation,
                param: {
                  ...textParamsForCreation.param,
                  properties: {
                    bgFontColor: columnTextParams?.bgFontColor,
                    fontColor: columnTextParams?.fontColor,
                    alignment: columnTextParams?.alignment,
                    borderColor: columnTextParams?.borderColor,
                    fontFamily: columnTextParams?.fontFamily,
                    fontSize: columnTextParams?.fontSize,
                    isBold: columnTextParams?.isBold,
                    isItalic: columnTextParams?.isItalic,
                  },
                },
              },
            ] as ColumnParametersType)
          : []
      const newData: GanttColumnForm = {
        id,
        code,
        title,
        objectValue,
        pinning,
        field,
        useParameters,
        pinnedColumn: pinning ? pinnedColumn : null,
        useLocalParameters: watchUseParameters,
        parameters: newParams,
        valueType,
        order,
        visibility,
      }
      onSetCurrentColumn(newData)
    })()
  }

  const handleSetValuesColumnStyles = (
    columnHeaderStyles: TextSettingsStateType,
    columnTextStyles: TextSettingsStateType
  ) => {
    columnHeaderSettingsRef.current?.setValues(columnHeaderStyles)
    columnTextSettingsRef.current?.setValues(columnTextStyles)
  }

  const handleResetParams = () => {
    handleSetValuesColumnStyles(
      DEFAULT_INITIAL_STATE_COLUMN_HEADER_PARAMETERS,
      DEFAULT_INITIAL_STATE_COLUMN_TEXT_PARAMETERS
    )

    setShowResetConfirmModal(false)
  }

  const handleClickReset = () => {
    const columnHeaderData = columnHeaderSettingsRef.current?.getTextParams()
    const columnTextData = columnTextSettingsRef.current?.getTextParams()

    if (
      isEqual(columnHeaderData, DEFAULT_GLOBAL_COLUMN_HEADER_PARAMETERS) &&
      isEqual(columnTextData, DEFAULT_GLOBAL_COLUMN_TEXT_PARAMETERS)
    ) {
      return
    }

    setShowResetConfirmModal(true)
  }

  const handleSetShowResetConfirmModal = (value: boolean) => setShowResetConfirmModal(value)

  return {
    state: {
      isTextParamsDirty: isDirtyHeader || isDirtyText,
      isShowResetConfirmModal,
    },
    handlers: {
      handleSave,
      handleTextExternalDirty,
      handleHeaderExternalDirty,
      handleSetValuesColumnStyles,
      handleSetShowResetConfirmModal,
      handleResetParams,
      handleClickReset,
    },
  }
}

import { combineReducers } from '@reduxjs/toolkit'

import { commonAPI, refetchApi } from '../api'

import appFooterReducer from './appFooter.reducer'
import availableGanttsReducer from './availableGantts.reducer'
import changedFields from './changedFormFields.reducer'
import changedRows from './changedViewRows.reducer'
import contextReducer from './contexts.reducer'
import dialogWindowManager from './dialogWindowManager.reducer'
import formHistory from './formHistory.reducer'
import formStatesReducer from './formStates.reducer'
import snackbarsReducer from './snackbar.reducer'
import stepperReducer from './stepper.reducer'
import uiOverlayReducer from './uiOverlay.reducer'

export const rootReducer = combineReducers({
  stepper: stepperReducer,
  snackbar: snackbarsReducer,
  formHistory,
  formStates: formStatesReducer,
  changedFormFields: changedFields,
  changedViewRows: changedRows,
  dialogWindowManager,
  uiOverlay: uiOverlayReducer,
  contexts: contextReducer,
  availableGantts: availableGanttsReducer,
  appFooter: appFooterReducer,
  [commonAPI.reducerPath]: commonAPI.reducer,
  [refetchApi.reducerPath]: refetchApi.reducer,
})

import isString from 'lodash/isString'
import { Box, Divider, TableHead as MUITableHead, TableSortLabel } from '@mui/material'
import { flexRender, Header, SortDirection, Table } from '@tanstack/react-table'

import { SortItem, TableCell, TableRow } from '.'

type TableHeadCellProps<T> = {
  header: Header<T, unknown>
  index: number
}

type TableHeadProps<T> = {
  table: Table<T>
}

const TableHeadCell = <T,>({ header, index }: TableHeadCellProps<T>) => {
  const meta = header.getContext().table.options.meta
  const sortModel = meta?.sortModel || []
  const disableSort = meta?.disableSort
  const manualSorting = meta?.manualSorting
  const onSortModelChange = meta?.onSortModelChange
  const useColumnResizing = meta?.useColumnResizing
  const showDivider = header.column.columnDef.meta?.showDivider ?? true

  const turnDirectionMap = new Map<SortItem['sort'], SortItem['sort']>([
    [undefined, 'asc'],
    ['asc', 'desc'],
    ['desc', null],
  ])

  const handleChangeSort = (id: string, direction: SortItem['sort']) => {
    const newSortModel = [...sortModel]
    const newSortItem = turnDirectionMap.get(direction)
    const fieldIndex = newSortModel.findIndex(sortItem => sortItem.field === id)

    if (fieldIndex !== -1) {
      newSortModel[fieldIndex] = { field: id, sort: newSortItem }
    } else {
      // TODO: обработать возможность мультисортировки, если потребуется
      // newSortModel.push({ field: id, sort: newSortItem })
      newSortModel[0] = { field: id, sort: newSortItem }
    }
    const filteredNewSortModel = newSortModel.filter(({ sort }) => !!sort)
    onSortModelChange?.(filteredNewSortModel.length ? filteredNewSortModel : null)
  }

  const sortItem = sortModel.find(({ field }) => field === header.id)
  const clientDirection = isString(header.column.getIsSorted())
    ? (header.column.getIsSorted() as SortDirection)
    : undefined
  const direction = manualSorting ? sortItem?.sort || undefined : clientDirection
  const active = manualSorting ? !!sortItem : !!header.column.getIsSorted()
  const onClick = manualSorting
    ? () => handleChangeSort(header.id, direction)
    : header.column.getToggleSortingHandler()
  const sortable = header.column.columnDef.enableSorting ?? true
  const size = header.getSize()
  const isLastCell = index === header.headerGroup.headers.length - 1
  const isActionsCell = header.column.id === '_actions'

  return (
    <TableCell
      title={header.column.columnDef.header?.toString()}
      sx={{
        position: 'relative',
        width: size,
        height: 32,
        fontWeight: 600,
      }}
    >
      {!disableSort && sortable ? (
        <TableSortLabel active={active} direction={direction} onClick={onClick}>
          {header.isPlaceholder ? null : (
            <Box
              component={'span'}
              sx={{
                maxWidth: header.getSize() - 36,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'pre',
              }}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
            </Box>
          )}
        </TableSortLabel>
      ) : (
        flexRender(header.column.columnDef.header, header.getContext())
      )}
      {showDivider && (
        <Divider
          {...(useColumnResizing && {
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
          })}
          sx={{
            position: 'absolute',
            right: 0,
            top: 9,
            height: 14,
            borderWidth: 1,
            userSelect: 'none',
            touchAction: 'none',
            zIndex: 1,
            ...(useColumnResizing && {
              cursor: 'col-resize',
            }),
            ...(header.column.getIsResizing() && {
              background: theme => theme.palette.primary.light,
            }),
          }}
          // style={{
          //   transform: header.column.getIsResizing()
          //     ? `translateX(${header.getContext().table.getState().columnSizingInfo.deltaOffset}px)`
          //     : '',
          // }}
          orientation='vertical'
        />
      )}
    </TableCell>
  )
}

export const TableHead = <T,>({ table }: TableHeadProps<T>) => {
  return (
    <MUITableHead component={'div'}>
      {table.getHeaderGroups().map(headerGroup => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header, i) => {
            return <TableHeadCell key={header.id} header={header} index={i} />
          })}
        </TableRow>
      ))}
    </MUITableHead>
  )
}

import { createContext, FC } from 'react'
import { Outlet } from 'react-router-dom'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { useGanttCreateOrEdit } from './hooks'
import { GanttColumnForm, PageContextType, SegmentType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType) // todo, чтобы не писать as и не описывать руками все типы контекста, можно делать так https://stackoverflow.com/questions/61333188/react-typescript-avoid-context-default-value

const GANTT_SEGMENT_TYPE_OPTIONS = [
  { id: 0, value: SegmentType.SINGLE, name: SegmentType.SINGLE },
  // { id: 1, value: SegmentType.OWN_SECTIONS, name: SegmentType.OWN_SECTIONS },
  // { id: 2, value: SegmentType.REF_SECTIONS, name: SegmentType.REF_SECTIONS },
  { id: 3, value: SegmentType.MULTI, name: SegmentType.MULTI },
]

export const GanttCreateOrEdit: FC = () => {
  const { data, handlers, state } = useGanttCreateOrEdit()
  const {
    objects,
    bindingValuesColumns,
    objectResource,
    bindingValuesActionResourceEvents,
    bindingValuesActionTimelineEvents,
    bindingValuesResourceAction,
    bindingValuesTimelineAction,
  } = data
  const {
    showDialog,
    objectFields,
    currentRow,
    objectCodeResource,
    objectCodeTimelines,
    modalType,
    watchedObjectResource,
    watchedObjectTimeline,
    currentColumn,
    columnToDelete,
    currentConfiguration,
    currentActionResource,
    currentActionTimeline,
    actionResourceToDelete,
    actionTimelineToDelete,
    currentColumnsList,
    currentSectionsList,
    currentSegment,
    segmentToDelete,
    currentBarIndex,
    currentBarObject,
    availableVariables,
    currentActionTimelineList,
    currentActionResourceList,
  } = state
  const {
    handleOpenDialog,
    handleCloseModal,
    handleSetObjectCodeResource,
    handleSetObjectCodeTimelines,
    handleSetModalProperty,
    handleEdit,
    handleAddGantt,
    handleSetWatchedObjectResource,
    handleSetWatchedObjectTimeline,
    handleSetCurrentColumn,
    handleSetColumnToDelete,
    handleDeleteColumn,
    handleSetCurrentConfiguration,
    handleSetCurrentActionResource,
    handleSetCurrentActionTimeline,
    handleDeleteActionResource,
    handleDeleteActionTimeline,
    handleHide,
    handleSetCurrentColumnsList,
    handleSetCurrentSectionsList,
    handleSetCurrentSegment,
    handleDeleteSegment,
    handleSetCurrentBarIndex,
    handleSetCurrentBarObject,
    handleSetCurrentActionTimelineList,
    handleSetCurrentActionResourceList,
  } = handlers

  const contextValue = {
    GANTT_SEGMENT_TYPE_OPTIONS,
    objects,
    objectCodeResource,
    objectCodeTimelines,
    showDialog,
    bindingValuesColumns,
    bindingValuesResourceAction,
    bindingValuesTimelineAction,
    bindingValuesActionResourceEvents,
    bindingValuesActionTimelineEvents,
    objectFields,
    objectFieldsForFilter: objectResource?.fields,
    rawObjectFields: objectResource?.fields,
    modalType,
    ganttCode: '',
    watchedObjectTimeline,
    watchedObjectResource,
    availableVariables,
    columnToDelete,
    segmentToDelete,
    currentRow,
    currentColumn: currentColumn as GanttColumnForm,
    currentConfiguration,
    currentActionResource,
    currentActionTimeline,
    currentColumnsList,
    currentSectionsList,
    currentSegment,
    currentBarIndex,
    currentBarObject,
    currentActionTimelineList,
    currentActionResourceList,
    actionResourceToDelete,
    actionTimelineToDelete,
    addGantt: handleAddGantt,
    //todo единообразие в именовании
    handleOpenDialog,
    handleCloseModal,
    handleEdit,
    handleHide,
    onSetObjectCodeResource: handleSetObjectCodeResource,
    onSetObjectCodeTimelines: handleSetObjectCodeTimelines,
    onSetModalProperty: handleSetModalProperty,
    onSetWatchedObjectResource: handleSetWatchedObjectResource,
    onSetWatchedObjectTimeline: handleSetWatchedObjectTimeline,
    onSetCurrentColumn: handleSetCurrentColumn,
    onSetColumnToDelete: handleSetColumnToDelete,
    onDeleteColumn: handleDeleteColumn,
    onSetCurrentConfiguration: handleSetCurrentConfiguration,
    onSetCurrentActionResource: handleSetCurrentActionResource,
    onSetCurrentActionTimeline: handleSetCurrentActionTimeline,
    onSetActionToDeleteResource: handleDeleteActionResource,
    onSetActionToDeleteTimeline: handleDeleteActionTimeline,
    onSetCurrentColumnsList: handleSetCurrentColumnsList,
    onSetCurrentSectionsList: handleSetCurrentSectionsList,
    onSetCurrentSegment: handleSetCurrentSegment,
    onSetSegmentToDelete: handleDeleteSegment,
    onSetCurrentBarIndex: handleSetCurrentBarIndex,
    onSetCurrentBarObject: handleSetCurrentBarObject,
    onSetCurrentActionTimelineList: handleSetCurrentActionTimelineList,
    onSetCurrentActionResourceList: handleSetCurrentActionResourceList,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <PageContentLayout>
        <Outlet />
      </PageContentLayout>
    </PageContext.Provider>
  )
}

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton, Typography } from '@mui/material'

import { useCopy } from '@gantt/hooks/useCopy'

import values from './values'

export const BtnCopyConfigExample = ({ name }: { name: keyof typeof values }) => {
  const copy = useCopy()

  const handleClick = () => {
    const value = values[name]
    copy(value)
  }

  return (
    <Typography
      borderBottom='1px dotted black'
      mr={1}
      sx={{ cursor: 'copy' }}
      onClick={handleClick}
    >
      {name}
      <IconButton onClick={handleClick}>
        <ContentCopyIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Typography>
  )
}

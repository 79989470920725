import { FC, useContext, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { FormGenerator, FormInputProps } from '@microservices/wiskey-react-components'
import { Close, Done } from '@mui/icons-material'
import { Grid, IconButton, SxProps } from '@mui/material'

import { getBackgroundByElementType } from '@pages/ControlPanelsCreateOrEdit/helpers'

import { useFetchAllFormQuery, useFetchAllGanttQuery, useFetchAllViewQuery } from '@redux/api'

import { getEntityAutocompleteOptionsFromType } from '@helpers'
import {
  ELEMENT_TYPE,
  ELEMENT_TYPE_OPTIONS,
  ENTITY_TYPES_REQUEST,
  GENERATOR_INPUT_TYPE,
  MENU_TYPE,
  MENU_TYPE_OPTIONS,
  selectTypeFields,
} from '@constants'
import { AutocompleteOption, TreeItemControlPanelOptionType } from '@types'

import { MenuTabPanelContext } from '../MenuTabPanel'

type AddItemProps = {
  treeItem?: TreeItemControlPanelOptionType
  onClose?: () => void
  isEdit?: boolean
  sx?: SxProps
}

type FormValues = {
  name: string
  menuType: (typeof MENU_TYPE_OPTIONS)[number] | null
  elementType: (typeof ELEMENT_TYPE_OPTIONS)[number] | null
  element: AutocompleteOption<number> | null
  background: boolean | null
}

export const AddItem: FC<AddItemProps> = ({ sx, onClose, treeItem, isEdit }) => {
  const { t } = useTranslation()
  const { tree, isDirty, handleAddTreeItem, handleEditTreeItem } = useContext(MenuTabPanelContext)

  const defaultValues: FormValues = {
    menuType: null,
    elementType: null,
    name: '',
    element: null,
    background: null,
  }
  const methods = useForm<FormValues>({
    defaultValues,
  })
  const { handleSubmit, reset, setValue, watch } = methods

  const currentElementType = watch('elementType', null)
  const currentElement = watch('element', null)
  const currentMenuType = watch('menuType', null)
  const isDivider = currentMenuType?.id === MENU_TYPE.DIVIDER

  const disabledSelectObject =
    currentElementType?.id === ELEMENT_TYPE.GROUP ||
    currentElementType?.id === ELEMENT_TYPE.SEARCH_ASSISTANT ||
    isDivider

  const entityTypeForRequest = useMemo(() => {
    if (!currentElementType) {
      return undefined
    }

    switch (currentElementType.id) {
      case ELEMENT_TYPE.VIEW:
        return ENTITY_TYPES_REQUEST.VIEW
      case ELEMENT_TYPE.GANTT_CHART:
        return ENTITY_TYPES_REQUEST.GANTT_CHART
      default:
        return undefined
    }
  }, [currentElementType])

  const {
    data: entities,
    isLoading: isLoadingEntities,
    isFetching: isFetchingEntities,
  } = useFetchAllViewQuery({ viewType: entityTypeForRequest }, { skip: !entityTypeForRequest })

  const {
    data: forms,
    isLoading: isLoadingForms,
    isFetching: isFetchingForms,
  } = useFetchAllFormQuery(undefined, { skip: currentElementType?.id !== ELEMENT_TYPE.FORM })

  const { data: ganttConfigList } = useFetchAllGanttQuery()

  const loadingObjects =
    isLoadingEntities || isFetchingEntities || isLoadingForms || isFetchingForms

  const treeNames = useMemo(
    () => Object.values(tree?.items ?? {}).map(op => op.data.title.toLowerCase()),
    [tree]
  )

  const isCurrentTreeName = (value: string) =>
    Boolean(isEdit && treeItem && treeItem.data.title.toLowerCase() === value)

  const autocompleteViewOptions = useMemo(
    () =>
      getEntityAutocompleteOptionsFromType(currentElementType?.id, {
        [ELEMENT_TYPE.VIEW]: entities?.data,
        [ELEMENT_TYPE.GANTT_CHART]: ganttConfigList,
        [ELEMENT_TYPE.FORM]: forms?.data,
      }),
    [currentElementType?.id, entities?.data, forms?.data]
  )

  useEffect(() => {
    if (!isDirty && !isEdit) {
      reset()
    }
  }, [tree])

  useEffect(() => {
    if (isEdit) {
      reset({
        menuType: MENU_TYPE_OPTIONS.find(({ label }) => label === treeItem?.data.menuType),
        name: treeItem?.data.title,
        element:
          treeItem?.data.elementId && treeItem.data.elementTitle
            ? { id: treeItem.data.elementId, label: treeItem.data.elementTitle }
            : null,
        elementType: ELEMENT_TYPE_OPTIONS.find(({ id }) => id === treeItem?.data.elementType),
        background: treeItem?.data.background,
      })

      return
    }
    reset(defaultValues)
  }, [isEdit])

  const onAdd = (data: FormValues): void => {
    const { name, element, elementType, menuType, background } = data

    if ((elementType && menuType) || menuType?.id === MENU_TYPE.DIVIDER) {
      const findElement = element
        ? (entities?.data || entities?.data || forms?.data || []).find(
            ({ id }) => element.id === id
          )
        : null

      const option = {
        title: name,
        elementCode: findElement?.code || null,
        elementTitle: element?.label || null,
        elementId: Number(element?.id) || null,
        elementType: elementType?.id || null,
        menuType: menuType?.id || null,
        background: getBackgroundByElementType(background, elementType?.id),
      }

      if (isEdit && treeItem) {
        handleEditTreeItem({
          ...treeItem,
          data: {
            ...treeItem.data,
            ...option,
          },
        })

        onClose?.()

        return
      }

      handleAddTreeItem(
        {
          uuid: uuid(),
          hasChild: false,
          childrenMenu: [],
          ...(menuType?.label === MENU_TYPE.ICON && { showTitle: true, iconName: null }),
          ...(treeItem?.data.id
            ? { parentId: treeItem.data.id }
            : { parentUuid: treeItem?.data.uuid }),
          ...option,
        },
        treeItem
      )

      reset()
      onClose?.()

      return
    }
  }

  const handleClose = () => {
    if (treeItem) {
      onClose?.()
    }

    reset()
  }

  const handleSelectTypeChange = (e: any) => {
    if (e.target.value) {
      if (currentElementType?.id !== e.target.value.id) {
        setValue('element', null, { shouldValidate: true })
      }

      return
    }

    setValue('element', null, { shouldValidate: true })
  }

  const isNameUsed = (value: string) =>
    !treeNames.includes(value.toLowerCase()) || isCurrentTreeName(value.toLowerCase())

  const checkValidate = (value: string): string | undefined => {
    if (!(value.trim().length !== 0 && isNameUsed(value))) {
      return t('error.alreadyUsed', { name: value })
    }
  }

  return (
    <Grid
      container
      item
      alignItems='flex-start'
      direction={'row'}
      spacing={1}
      sx={sx}
      width={'100%'}
    >
      <FormProvider {...methods}>
        <Grid item flex={1}>
          <FormGenerator
            mainGridSpacing={0}
            inputs={[
              {
                rows: 'row',
                name: 'row',
                inputs: [
                  {
                    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                    name: 'menuType',
                    placeholder: t(`controlPanelCreate.form.addItem.pointType.placeholder`),
                    autocompleteOptions: MENU_TYPE_OPTIONS,
                    rules: {
                      required: true,
                    },
                  },
                  {
                    inputType: GENERATOR_INPUT_TYPE.INPUT,
                    name: 'name',
                    placeholder: t(`controlPanelCreate.form.addItem.name.placeholder`),
                    maxLengthInput: 100,
                    rules: {
                      required: true,
                      maxLength: 100,
                      validate: value => checkValidate(value),
                    },
                  },
                  {
                    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                    name: 'elementType',
                    disabled: (isEdit && treeItem?.hasChildren) || isDivider,
                    placeholder: t(`controlPanelCreate.form.addItem.entityType.placeholder`),
                    loading: !selectTypeFields,
                    isOptionEqualToValue: (option, value) => option.label === value.label,
                    autocompleteOptions: ELEMENT_TYPE_OPTIONS,
                    rules: {
                      onChange: handleSelectTypeChange,
                    },
                  },
                  {
                    inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                    name: 'element',
                    disabled:
                      (isEdit && treeItem?.hasChildren) ||
                      !currentElementType ||
                      disabledSelectObject,
                    placeholder: t(`controlPanelCreate.form.addItem.entity.placeholder`),
                    loading: loadingObjects,
                    isOptionEqualToValue: (option, value) => option.label === value.label,
                    autocompleteOptions: autocompleteViewOptions,
                  },
                  ...(currentElementType?.id === ELEMENT_TYPE.VIEW && currentElement
                    ? [
                        {
                          name: 'background',
                          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                          label: t(`controlPanelCreate.form.addItem.background`),
                          labelPlacement: 'end',
                        } as FormInputProps,
                      ]
                    : []),
                ],
              },
            ]}
          />
        </Grid>

        <Grid item>
          <IconButton onClick={handleSubmit(onAdd)}>
            <Done color='primary' />
          </IconButton>
          <IconButton onClick={handleClose}>
            <Close color='error' />
          </IconButton>
        </Grid>
      </FormProvider>
    </Grid>
  )
}

import i18next from 'i18next'

import { ColumnForm } from '../hooks/useInputs'

export const getDefaultValue = (): ColumnForm => {
  return {
    code: '',
    bindType: i18next.t('placeholder.bindType'),
    title: '',
    value: i18next.t('placeholder.value'),
    objectValue: i18next.t('placeholder.objectValue'),
    valueType: null,
    columnToSort: null,
    pinning: false,
    pinnedColumn: null,
    useParameters: false,
    enumDescription: true,
    useObjectState: false,
    asCheckbox: false,
    asDuration: false,
  }
}

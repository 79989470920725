import { commonAPI } from './common.api'

export const slotsAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    uploadFlights: build.mutation<void, FormData>({
      query: body => ({
        url: 'api/slots/telegram/uploader/tbs/flights',
        responseHandler: response => response.text(),
        method: 'POST',
        body,
        formData: true,
      }),
      invalidatesTags: [],
    }),
  }),
})

export const { useUploadFlightsMutation } = slotsAPI

import uniqueId from 'lodash/uniqueId'

import { GanttActionType } from '@gantt/components/GanttCreateOrEdit'

export const getDefaultValue = (): GanttActionType => {
  const code = uniqueId('action_Y_')

  return structuredClone({
    id: undefined,
    code,
    title: '',
    actionCode: null,
    eventCode: null,
    target: null,
    actionField: null,
  })
}

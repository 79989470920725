import { MouseEvent } from 'react'
// import {
//   GridActionsCellItem,
//   GridCellParams,
//   GridEnrichedColDef,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  GridActionsCellItem,
  GridCellParams,
  GridEnrichedColDef,
} from '@microservices/wiskey-react-components'
import { ContentCopy, Delete, Edit, Send } from '@mui/icons-material'

import { DEFAULT_HEADER_STYLES_CLASSNAME, PINNED_COLUMN } from '@constants'

type HandlerActionClick = (
  params: GridCellParams<any, any, any>,
  event: MouseEvent<HTMLButtonElement>
) => void

type DataGridActionsColumnParams = {
  onCopy?: HandlerActionClick
  onEdit?: HandlerActionClick
  onAdditionalButtonClick?: HandlerActionClick
  onDelete?: HandlerActionClick
  columnDef?: GridEnrichedColDef
}

export const generateDataGridActionsColumn = ({
  onCopy,
  onEdit,
  onAdditionalButtonClick,
  onDelete,
  columnDef,
}: DataGridActionsColumnParams): GridEnrichedColDef => {
  return {
    field: 'actions',
    type: 'actions',
    align: 'right',
    // minWidth: onCopy ? 150 : 100,
    minWidth: [onCopy, onAdditionalButtonClick, onEdit, onDelete].filter(Boolean).length * 50,
    resizable: false,
    hideable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    pinnedColumn: PINNED_COLUMN.RIGHT, // Для промежуточных расчётов. Не влияет на реальное закрепление.
    getActions: (params: GridCellParams) =>
      [
        onCopy ? (
          <GridActionsCellItem
            key={params.id}
            icon={<ContentCopy />}
            label={'Copy'}
            showInMenu={false}
            onClick={event => onCopy?.(params, event)}
          />
        ) : null,
        onEdit ? (
          <GridActionsCellItem
            key={params.id}
            icon={<Edit />}
            label={'Edit'}
            showInMenu={false}
            onClick={event => onEdit?.(params, event)}
          />
        ) : null,
        onAdditionalButtonClick ? (
          <GridActionsCellItem
            key={params.id}
            // TODO Сейчас иконка хардкод. В дальнейшем админ должен сам выбрать нужную иконку
            icon={<Send />}
            label={'Edit'}
            showInMenu={false}
            onClick={event => onAdditionalButtonClick?.(params, event)}
          />
        ) : null,
        onDelete ? (
          <GridActionsCellItem
            key={params.id}
            icon={<Delete />}
            label={'Delete'}
            showInMenu={false}
            onClick={event => onDelete?.(params, event)}
          />
        ) : null,
      ].filter(Boolean),
    ...columnDef,
  } as GridEnrichedColDef
}

import { Dispatch, FC, SetStateAction, useContext, useMemo } from 'react'
import { Chip } from '@microservices/wiskey-react-components'
import { Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { checkDirtyStoredValue } from '@helpers'
import { COUNT_FORM_FIELD, CriteriaType, FILTERS_SA_TYPE, ValueAddType } from '@constants'

import { SearchAssistantContext } from '../../SearchAssistant'

type SearchChipsProps = {
  criterias: CriteriaType[]
  currentCriterias: CriteriaType[]
  addValue: (value: ValueAddType) => void
  setCurrentCriteria: Dispatch<SetStateAction<CriteriaType[]>>
  title: string
}

export const SearchChips: FC<SearchChipsProps> = ({
  criterias,
  currentCriterias,
  addValue,
  setCurrentCriteria,
  title,
}) => {
  const usedCriterias = useMemo(
    () =>
      criterias.filter(criteria =>
        criteria.storedValues.some(({ value, searchRule }) =>
          checkDirtyStoredValue(value, searchRule)
        )
      ),
    [criterias]
  )

  const { isSearchAssistantDialogWindow } = useContext(SearchAssistantContext)

  const setLastCurrentCriteria = (criteria: CriteriaType) =>
    setCurrentCriteria(prevCriterias =>
      prevCriterias.map((prevCriteria, index) =>
        index === COUNT_FORM_FIELD - 1 ? criteria : prevCriteria
      )
    )

  const handleClick = (codeCriteria: string) => {
    const includeCurrentCriterias = currentCriterias.some(
      criteria => criteria?.codeCriteria === codeCriteria
    )

    if (!includeCurrentCriterias) {
      const criteria = criterias.find(criteria => criteria.codeCriteria === codeCriteria)

      if (criteria) {
        setLastCurrentCriteria(criteria)
      }
    }
  }

  return (
    <Grid container>
      <Grid item mr={8}>
        <Typography color={grey[600]}>{title}</Typography>
      </Grid>
      <Grid sx={{ pl: 1 }}>
        {usedCriterias
          .sort((a, b) =>
            currentCriterias.findIndex(filter => filter.codeCriteria === b.codeCriteria)
          )
          .map(value => {
            const isUsedFilter = currentCriterias.some(
              filter => filter?.codeCriteria === value.codeCriteria
            )

            return (
              <Chip
                key={value.codeCriteria}
                label={value.title}
                size='small'
                variant={isUsedFilter ? 'filled' : 'outlined'}
                sx={{
                  height: isSearchAssistantDialogWindow ? '19px' : '23px',
                  ml: 1,
                  color: theme => theme.palette.color.default,
                  bgcolor: theme => theme.palette.background.chip,
                  border: theme => theme.palette.border.field,
                  '&.MuiChip-deleteIcon': {
                    color: theme => theme.palette.color.default,
                  },
                  '&.MuiChip-root:hover': {
                    bgcolor: theme => theme.palette.background.hoveredChip,
                  },
                }}
                onClick={() => handleClick(value.codeCriteria)}
                onDelete={() =>
                  addValue({
                    codeCriteria: value.codeCriteria,
                    value: '',
                    searchRule: FILTERS_SA_TYPE.EQUAL,
                  })
                }
              />
            )
          })}
      </Grid>
    </Grid>
  )
}

import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { CellContext } from '@tanstack/react-table'

type TableActionsCellProps<T> = {
  onEdit?: (props: CellContext<T, unknown>) => void
  onDelete?: (props: CellContext<T, unknown>) => void
  onCopy?: (props: CellContext<T, unknown>) => void
  justifyContent?: string
  disabledEditActionColumn?: boolean
  disabledDeleteActionColumn?: boolean
  disabledCopyActionColumn?: boolean
} & CellContext<T, unknown>

export const TableActionsCell = <T,>({
  justifyContent,
  disabledEditActionColumn = false,
  disabledDeleteActionColumn = false,
  disabledCopyActionColumn = false,
  ...props
}: TableActionsCellProps<T>) => {
  const meta = props.table.options.meta
  const onEdit = meta?.onEdit
  const onCopy = meta?.onCopy
  const onDelete = meta?.onDelete

  return (
    <Box sx={{ display: 'flex', justifyContent, alignItems: 'center', height: 32 }}>
      {onCopy && (
        <IconButton
          disabled={disabledCopyActionColumn}
          size={'small'}
          onClick={event => {
            event.stopPropagation()
            onCopy(props)
          }}
        >
          <ContentCopy fontSize={'small'} />
        </IconButton>
      )}
      <IconButton
        disabled={disabledEditActionColumn}
        size={'small'}
        onClick={event => {
          event.stopPropagation()
          onEdit?.(props)
        }}
      >
        <Edit fontSize={'small'} />
      </IconButton>
      <IconButton
        disabled={disabledDeleteActionColumn}
        size={'small'}
        onClick={event => {
          event.stopPropagation()
          onDelete?.(props)
        }}
      >
        <Delete fontSize={'small'} />
      </IconButton>
    </Box>
  )
}

import { useContext, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Add, Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'

import {
  useFetchContextDefaultEntityBackgroundByIdQuery,
  useFetchContextGanttChartsByIdQuery,
  useFetchContextViewsByIdQuery,
} from '@redux/api/modelContext.api'

import {
  CONTEXT_MODEL_TYPE,
  DEFAULT_ENTITY_BACKGROUND,
  DEFAULT_ENTITY_RADIO_OPTIONS,
  GENERATOR_INPUT_TYPE,
} from '@constants'

import { PageContext } from '../ContextCreateOrEdit'
import { getBackgroundEntityOptionByModelType } from '../helpers'

const DEFAULT_BACKGROUND_ENTITY = {
  type: DEFAULT_ENTITY_BACKGROUND.VIEW,
  view: null,
  ganttChart: null,
}

export const DefaultEntity = () => {
  const { t } = useTranslation()
  const { id, isCreate } = useContext(PageContext)

  const methods = useFormContext()
  const { control, getValues, reset, watch } = methods
  const name = 'defaultBackgroundEntities'
  const defaultBackgroundEntities = watch(name)
  const { fields: entityFields, append, remove, update } = useFieldArray({ control, name })

  const { data: contextViews, isLoading: loadingViewOptions } = useFetchContextViewsByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  })

  const { data: contextGanttCharts, isLoading: loadingGanttChartOptions } =
    useFetchContextGanttChartsByIdQuery(id, {
      skip: !id,
      refetchOnMountOrArgChange: true,
    })

  const { data: backgroundEntities } = useFetchContextDefaultEntityBackgroundByIdQuery(id, {
    skip: isCreate || !id,
  })

  const ganttChartAutocompleteOptions = useMemo(
    () =>
      contextGanttCharts?.selectedGanttCharts.map(gantt => ({
        id: gantt.id,
        label: gantt.title,
        type: CONTEXT_MODEL_TYPE.GANTT_CHART,
      })) || [],
    [contextGanttCharts]
  )

  const viewAutocompleteOptions = useMemo(
    () =>
      contextViews?.selectedViews.map(view => ({
        id: view.id,
        label: view.title,
        type: CONTEXT_MODEL_TYPE.VIEW,
      })) || [],
    [contextViews]
  )

  useEffect(() => {
    if (backgroundEntities?.length) {
      reset({
        ...getValues(),
        defaultBackgroundEntities: backgroundEntities.map(entity => ({
          type: DEFAULT_ENTITY_BACKGROUND[
            entity.type! as CONTEXT_MODEL_TYPE.VIEW | CONTEXT_MODEL_TYPE.GANTT_CHART
          ],
          view: getBackgroundEntityOptionByModelType(entity, viewAutocompleteOptions),
          ganttChart: getBackgroundEntityOptionByModelType(entity, ganttChartAutocompleteOptions),
        })),
      })
    }
  }, [backgroundEntities, viewAutocompleteOptions, ganttChartAutocompleteOptions])

  const handleChangeRadio = (value: string, index: number) => {
    update(index, { ...DEFAULT_BACKGROUND_ENTITY, type: value })
  }

  const handleAddDefaultEntity = () => append(DEFAULT_BACKGROUND_ENTITY)

  const handleRemoveDefaultEntity = (index: number) => remove(index)

  const disabledRadio = (index: number, entityLenght: number) => {
    if (index === 0) {
      return (
        entityLenght === 2 &&
        defaultBackgroundEntities[1].type === DEFAULT_ENTITY_BACKGROUND.GANTT_CHART
      )
    }

    return defaultBackgroundEntities[0].type === DEFAULT_ENTITY_BACKGROUND.GANTT_CHART
  }

  return (
    <Box>
      <Grid>
        <Typography variant='subtitle1'>{t('contexts.defaultBackgroundEntity')}</Typography>
      </Grid>
      {entityFields.map((entity, index) => (
        <Grid key={entity.id} container flexWrap='nowrap'>
          <FormInput
            key={index}
            disabled={disabledRadio(index, entityFields.length)}
            inputType={GENERATOR_INPUT_TYPE.RADIO_GROUP}
            labelSx={{ flex: 1, width: 200 }}
            name={`${name}.${index}.type`}
            radioOptionsList={DEFAULT_ENTITY_RADIO_OPTIONS}
            formInputContainerSx={{
              '.MuiFormGroup-root': {
                width: 220,
              },
              width: 'fit-content',
            }}
            onChangeRadio={value => handleChangeRadio(value, index)}
          />
          {entity.type === DEFAULT_ENTITY_BACKGROUND.GANTT_CHART ? (
            <FormInput
              key={entity.id}
              autocompleteOptions={ganttChartAutocompleteOptions}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              loading={loadingGanttChartOptions}
              name={`${name}.${index}.ganttChart`}
            />
          ) : (
            <FormInput
              key={entity.id}
              autocompleteOptions={viewAutocompleteOptions}
              inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
              loading={loadingViewOptions}
              name={`${name}.${index}.view`}
            />
          )}
          {entityFields?.length === 1 && (
            <IconButton
              sx={{ height: 40 }}
              disabled={
                !defaultBackgroundEntities[0].view && !defaultBackgroundEntities[0].ganttChart
              }
              onClick={handleAddDefaultEntity}
            >
              <Add fontSize='small' />
            </IconButton>
          )}
          <IconButton
            disabled={entityFields?.length === 1}
            sx={{ height: 40 }}
            onClick={() => handleRemoveDefaultEntity(index)}
          >
            <Delete fontSize='small' />
          </IconButton>
        </Grid>
      ))}
    </Box>
  )
}

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { useFetchContextControlPanelByIdQuery } from '@redux/api'

import { getLocalStorageContext, getSidebarDefaultOptions } from '@helpers'
import { ROUTES, USER_ROLES } from '@constants'

import {
  ExtendsNavProps,
  GETControlPanelLine,
  NavOptionProps,
  TransformLinesForAppBarType,
} from '../types'

import { useAuth } from './auth'

type NavOption<T> = T & {
  childrenMenu: NavOption<T>[]
  id: number
}

// Добавление пропса isSelected, чтобы контролировать состояние
export const addedNavProps = <T>(arr: NavOption<T>[], parentId?: number): ExtendsNavProps<T>[] => {
  return arr.map(o => ({
    ...o,
    parentId,
    isSelected: false,
    options: o.childrenMenu ? addedNavProps(o.childrenMenu, o.id) : undefined,
  }))
}

// Добавление пропса isSelected, чтобы контролировать состояние
export const addedPropsForLines = (
  lines: GETControlPanelLine[],
  parentId?: number
): TransformLinesForAppBarType => {
  return lines.map(line => {
    return {
      id: line.id,
      items: line.items.map(item => ({
        ...item,
        parentId,
        isSelected: false,
        options: item.childrenMenu ? addedNavProps(item.childrenMenu, item.id) : undefined,
      })),
    }
  })
}

// обработчик выбранного айтема
const modifySelected = (arr: NavOptionProps[], item: NavOptionProps): NavOptionProps[] => {
  return arr.map(op => {
    if (op.id === item.id && item.title === op.title && op.viewCode) {
      return {
        ...op,
        isSelected: true,
      }
    }

    if (op.options?.length) {
      return {
        ...op,
        options: modifySelected(op.options, item),
      }
    }

    return { ...op, isSelected: false }
  })
}

// Поиск вложенного пункта меню
export const searchOption = (
  arr: NavOptionProps[],
  items: string[],
  currentItem?: string
): NavOptionProps | undefined => {
  return arr
    .map((option, i) => {
      const name = option.title?.toLowerCase()
      const path = `/${items.join('/')}`.toLowerCase()
      const optionPath = `/${option.code?.toLowerCase()}`

      if (name === currentItem || (path.includes(optionPath) && !option.options?.length)) {
        return option
      }

      if (option.options?.length) {
        return searchOption(option.options, items, items[i])
      }
    })
    .filter(item => item)[0]
}

type SidebarHook = {
  sidebarOpen: boolean
  handleOpenSidebar: (value: boolean) => void
  appbarOptions: TransformLinesForAppBarType
  loadingAppbarOptions: boolean
  sidebarOptions: NavOptionProps[]
  handleSelected: (item: NavOptionProps) => void
  resetAppbarOptions: () => void
}

const isDev = process.env.NODE_ENV === 'development'

export const useSidebar = (): SidebarHook => {
  const { hasRole } = useAuth()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const sidebarDefaultOptions = getSidebarDefaultOptions(hasRole([USER_ROLES.ADMIN]))
  const context = getLocalStorageContext()
  const {
    data: menu,
    isSuccess,
    error: errorControlPanel,
    isFetching: loadingAppbarOptions,
  } = useFetchContextControlPanelByIdQuery(context?.id, {
    skip: !context || pathname === ROUTES.CHOOSE_CONTEXT,
  })

  const [sidebarOptions, setSidebarOptions] = useState<NavOptionProps[]>(
    addedNavProps(sidebarDefaultOptions)
  )
  const [appbarOptions, setAppbarOptions] = useState<TransformLinesForAppBarType>([])
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true)

  useEffect(() => {
    if (errorControlPanel && (errorControlPanel as FetchBaseQueryError).status === 404) {
      localStorage.removeItem('context')
      navigate(ROUTES.CHOOSE_CONTEXT)
    }
  }, [errorControlPanel])

  useEffect(() => {
    setAppbarOptions(menu?.panel ? addedPropsForLines(menu.panel.lines) : [])
  }, [menu])

  useEffect(() => {
    if (pathname && sidebarOptions.length) {
      const currentOptions = pathname.split('/').slice(1)
      const option = searchOption(sidebarOptions, currentOptions)
      const path = new URLSearchParams(search).get('path')

      if (option) {
        setSidebarOptions(modifySelected(sidebarOptions, option))

        return
      }
      if (path && !option) {
        const optionPath = searchOption(sidebarOptions, [path])
        optionPath && setSidebarOptions(modifySelected(sidebarOptions, optionPath))
      }
    }
  }, [pathname])

  const handleSelected = (item: NavOptionProps) => {
    setSidebarOptions(modifySelected(sidebarOptions, item))
  }

  const handleOpenSidebar = (value: boolean) => setSidebarOpen(value)

  const resetAppbarOptions = () => setAppbarOptions([])

  return {
    sidebarOpen,
    handleOpenSidebar,
    sidebarOptions,
    appbarOptions,
    loadingAppbarOptions,
    handleSelected,
    resetAppbarOptions,
  }
}

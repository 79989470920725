import {
  GETEntityColumn,
  PayloadCreateViewColumn,
  POSTCreateAllColumns,
  POSTOrderColumnType,
  POSTVisibilityColumnType,
} from '@types'

import { commonAPI } from './common.api'

export const columnAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchColumnView: build.mutation<GETEntityColumn, number | string | void>({
      query: id => ({
        url: `/ui/column/id/${id}`,
      }),
    }),
    createColumnView: build.mutation<GETEntityColumn, PayloadCreateViewColumn>({
      query: column => ({
        url: '/ui/column',
        method: 'POST',
        body: { ...column, id: undefined },
      }),
      invalidatesTags: ['SingleView'],
    }),
    createAllColumns: build.mutation<GETEntityColumn, POSTCreateAllColumns>({
      query: allColumnsParams => ({
        url: '/ui/column/addAll',
        method: 'POST',
        body: { ...allColumnsParams, onlyRequired: false },
      }),
      invalidatesTags: ['SingleView'],
    }),
    createMandatoryColumns: build.mutation<GETEntityColumn, POSTCreateAllColumns>({
      query: allColumnsParams => ({
        url: '/ui/column/addAll',
        method: 'POST',
        body: { ...allColumnsParams, onlyRequired: true },
      }),
      invalidatesTags: ['SingleView'],
    }),
    updateColumnView: build.mutation<GETEntityColumn, PayloadCreateViewColumn>({
      query: column => ({
        url: '/ui/column',
        method: 'POST',
        body: column,
      }),
      invalidatesTags: ['SingleView'],
    }),
    deleteColumnView: build.mutation<void, number | string>({
      query: id => ({
        url: `/ui/column/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleView'],
    }),
    updateOrderColumns: build.mutation<void, POSTOrderColumnType[]>({
      query: columns => ({
        url: '/ui/column/order',
        method: 'POST',
        body: columns,
      }),
      invalidatesTags: ['SingleView'],
    }),
    updateVisibilityColumnById: build.mutation<void, POSTVisibilityColumnType>({
      query: column => ({
        url: '/ui/column/visibility',
        method: 'POST',
        body: column,
      }),
      invalidatesTags: ['SingleView'],
    }),
  }),
})

export const {
  useCreateColumnViewMutation,
  useCreateAllColumnsMutation,
  useCreateMandatoryColumnsMutation,
  useDeleteColumnViewMutation,
  useFetchColumnViewMutation,
  useUpdateColumnViewMutation,
  useUpdateOrderColumnsMutation,
  useUpdateVisibilityColumnByIdMutation,
} = columnAPI

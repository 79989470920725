import { Dispatch, FC, SetStateAction, useContext, useMemo } from 'react'
import { AutoComplete } from '@microservices/wiskey-react-components'
import { Box, Grid, TextField, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { ScrollableLine } from '@components/ScrollableLine'

import {
  generalFieldStylesByMode,
  getAutoCompletePopperSxByDisplayMethod,
  isObjectValueType,
} from '@helpers'
import { COUNT_FORM_FIELD, CriteriaType, FIELD_VALUE_FORMAT, ValueAddType } from '@constants'

import { AutocompleteOption } from '../../../../../../types'
import { SearchAssistantContext } from '../../SearchAssistant'

import { InputValue } from './InputValue'

type SelectOptionProps = {
  options?: CriteriaType[]
  assistantCriteria: CriteriaType
  addValue: (value: ValueAddType) => void
  currentCriteriaNames: string[]
  setCurrentCriteria: Dispatch<SetStateAction<CriteriaType[]>>
  disabled?: boolean
  width: number
}
export const SelectOption: FC<SelectOptionProps> = ({
  options,
  addValue,
  assistantCriteria,
  setCurrentCriteria,
  currentCriteriaNames,
  disabled = false,
  width,
}) => {
  const { isSearchAssistantDialogWindow } = useContext(SearchAssistantContext)
  const hasLinkedObjectValues = assistantCriteria.storedValues.some(value =>
    isObjectValueType(value.valueType)
  )
  const heightValuesInSA = hasLinkedObjectValues ? undefined : '28px'

  const handleChange = (value: AutocompleteOption) => {
    const criteria = options?.find(criteria => criteria.title === value.label)

    if (criteria) {
      setCurrentCriteria(prevCriterias =>
        prevCriterias.map(prevCriteria =>
          prevCriteria.codeCriteria === assistantCriteria.codeCriteria ? criteria : prevCriteria
        )
      )
    }
  }

  const autocompleteOptions = useMemo(
    () =>
      options
        ?.filter(
          criteria =>
            !currentCriteriaNames.includes(criteria.title) ||
            criteria.title === assistantCriteria?.title
        )
        .map((criteria, i) => ({ label: criteria.title, id: i + COUNT_FORM_FIELD })),
    [options, currentCriteriaNames, assistantCriteria]
  )

  return (
    <Grid item>
      <Grid container alignItems={'center'} flexWrap={'nowrap'}>
        <Grid item minWidth={300} width={300}>
          {autocompleteOptions ? (
            <AutoComplete
              disableClearable
              fullWidth
              disabled={disabled}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              options={autocompleteOptions}
              size='small'
              componentsProps={{
                popper: {
                  sx: {
                    zIndex: theme => theme.zIndex.tooltip,
                    ...getAutoCompletePopperSxByDisplayMethod(isSearchAssistantDialogWindow),
                  },
                },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={theme => ({
                    ...generalFieldStylesByMode(
                      theme,
                      undefined,
                      undefined,
                      undefined,
                      isSearchAssistantDialogWindow
                    ),
                  })}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} title={option.label}>
                  <Box sx={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {option.label}
                  </Box>
                </li>
              )}
              value={
                assistantCriteria
                  ? { id: assistantCriteria.codeCriteria, label: assistantCriteria.title }
                  : null
              }
              onChange={handleChange}
            />
          ) : (
            <Typography color={theme => (disabled ? grey[600] : theme.palette.color.field)}>
              {assistantCriteria?.title}
            </Typography>
          )}
        </Grid>
        <Grid item flex={1}>
          <ScrollableLine width={width - 295}>
            {assistantCriteria?.storedValues.map(value => {
              const asCheckbox =
                value.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
                value.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN

              return (
                <Box
                  key={value.id}
                  sx={
                    asCheckbox
                      ? {}
                      : {
                          minWidth: '200px',
                          width: '100%',
                        }
                  }
                >
                  <InputValue
                    addValue={addValue}
                    codeCriteria={assistantCriteria.codeCriteria}
                    currentValue={value}
                    disabled={disabled}
                    fieldType={value.fieldType}
                    // inputWidth={inputWidth}
                    linkedObjectCode={value.linkedObjectCode}
                    objectValue={value.objectValue}
                  />
                </Box>
              )
            })}
          </ScrollableLine>
          {/*<Grid container></Grid>*/}
        </Grid>
      </Grid>
    </Grid>
  )
}

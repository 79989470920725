import { FC, useState } from 'react'
import { Grid } from '@mui/material'

import ConfiguredGanttLazy from '@pages/ConfiduredGantt/ConfiguredGanttLazy'
import { ConfiguredEntityPage } from '@pages/ConfiguredEntityPage'

import { CONTEXT_MODEL_TYPE, ENTITY } from '@constants'
import { GETDefaultBackgroundEntity, POSTObjectDataFilter } from '@types'

type ConfiguredEntitiesPageProps = {
  path: string
  entities: GETDefaultBackgroundEntity[]
  timezone: string | null
}

export const ConfiguredEntities: FC<ConfiguredEntitiesPageProps> = ({
  path,
  entities,
  timezone,
}) => {
  const [searchFilterBody, setSearchFilterBody] = useState<POSTObjectDataFilter>(null)

  const bothEntitiesIsView =
    entities[0].type === CONTEXT_MODEL_TYPE.VIEW && entities[1].type === CONTEXT_MODEL_TYPE.VIEW

  const entityByType = (entity: GETDefaultBackgroundEntity, index: number) => {
    if (entity.type === CONTEXT_MODEL_TYPE.VIEW) {
      return (
        <ConfiguredEntityPage
          key={path}
          entityCode={entity.code as string}
          entityId={entity.sourceId as number}
          path={path}
          title={entity.title as string}
          type={ENTITY.VIEW}
          {...(bothEntitiesIsView &&
            index === 0 && {
              setSearchFilter: setSearchFilterBody,
            })}
          {...(bothEntitiesIsView &&
            index === 1 && {
              searchFilterBody,
            })}
        />
      )
    }

    if (entity.type === CONTEXT_MODEL_TYPE.GANTT_CHART) {
      return (
        <ConfiguredGanttLazy
          key={path}
          entityId={entity.sourceId as string}
          path={path}
          timezone={timezone}
          title={entity.title as string}
        />
      )
    }

    return null
  }

  return (
    <Grid height='100%'>
      {entities.map((entity, index) => (
        <Grid key={entity.id} sx={{ height: '50%', overflowY: 'auto' }}>
          {entityByType(entity, index)}
        </Grid>
      ))}
    </Grid>
  )
}

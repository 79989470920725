import { GETDefaultBackgroundEntity } from '@types'

import { EntityAutocompleteOption } from '../types'

export const getBackgroundEntityOptionByModelType = (
  entity?: GETDefaultBackgroundEntity,
  options?: EntityAutocompleteOption[]
) => {
  return options?.find(opt => opt.id === entity?.sourceId) ?? null
}

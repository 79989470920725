import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { AllObjectsStates } from '@pages/ObjectsStates/components/AllObjectsStates'
import { useObjectsStates } from '@pages/ObjectsStates/hooks'
import { PageContextType } from '@pages/ObjectsStates/types'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { VISIBLE_HIDDEN } from '@constants'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ObjectsStates: FC = () => {
  const { t } = useTranslation()

  const {
    state: { methods, allObjectsStates },
    data: { isAllObjectsStates, objects, isLoadingObjectsStates },
    handlers: { handleCreateObjectsState, handleEditObjectsState, handleDeleteObjectsState },
  } = useObjectsStates()

  const contextValue = {
    objectsStatesTables: allObjectsStates,
    isLoadingObjectsStates,
    objects,
    onCreateObjectState: handleCreateObjectsState,
    onEditObjectState: handleEditObjectsState,
    onDeleteObjectState: handleDeleteObjectsState,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <Outlet />
      <Box
        sx={{
          ...(!isAllObjectsStates && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          end={false}
          start={
            <>
              <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
                {t('pageTitle.objectsStates')}
              </Typography>
              <Typography display={'inline'} variant={'subtitle2'}>
                {t('pageTitle.objectsStatesControl')}
              </Typography>
            </>
          }
        />

        <PageContentLayout>
          <AllObjectsStates methods={methods} />
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}

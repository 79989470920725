import { createContext, FC, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'

import { FormInputsType } from '@pages/FormCreateOrEdit/types'
import { useObjectsStatesCreateOrEdit } from '@pages/ObjectsStatesCreateOrEdit/hooks'
import {
  ObjectStateValueShotType,
  PageObjectStatesType,
} from '@pages/ObjectsStatesCreateOrEdit/types'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { ScriptValueDialog } from '@components/ScriptValueDialog'
import { SwitchTable } from '@components/SwitchTable'
import { TabPanel } from '@components/Tabs'

import {
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODAL_TYPE,
  OBJECT_STATE_VALUES_COLUMNS,
} from '@constants'

export const PageObjectStatesContext = createContext<PageObjectStatesType>(
  {} as PageObjectStatesType
)

export const ObjectsStatesCreateOrEdit: FC = () => {
  const { t } = useTranslation()

  const {
    state: {
      showDialog,
      modalType,
      valueJs,
      fieldsSelectedObject,
      objectStateValueID,
      showScriptValueDialogTitle,
      rows,
      editableFieldValue,
      isDirty,
      dirtyOnField,
      allObjectsStates,
    },
    data: {
      methods,
      watchIsJsObjectStates,
      id,
      isCreate,
      objects,
      isEdit,
      objectStateId,
      isLoadingObjectStateById,
      watchObject,
    },
    handlers: {
      handleCancel,
      handleSave,
      handleOpenDialog,
      handleCloseModal,
      handleEdit,
      handleDeleteObjectStateValue,
      handleSetShowScriptValueDialogTitle,
      setValue,
      handleSetFields,
      handleSetShowDialog,
    },
  } = useObjectsStatesCreateOrEdit()

  const contextValue: PageObjectStatesType = {
    methods,
    id,
    isCreate,
    isEdit,
    showDialog,
    handleCloseModal,
    handleEdit,
    modalType,
    valueJs,
    objectStateId,
    objectStateValueID,
    handleSetFields,
    editableFieldValue,
    handleSetShowDialog,
    fields: rows,
  }

  const modelType = isCreate ? 'create' : 'edit'
  const fieldOptions = useMemo(
    () => objects?.map(obj => ({ id: obj.code, label: obj.title })),
    [objects]
  )

  const listFieldsSelectedObject = useMemo(() => {
    if (watchObject === null) {
      setValue('objectField', null)

      return []
    }

    return fieldsSelectedObject?.map(obj => ({
      id: obj.id,
      label: obj.name,
    }))
  }, [fieldsSelectedObject, watchObject])

  const objectStateFields: FormInputsType[] = [
    {
      name: 'internalId',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      loading: isLoadingObjectStateById,
      placeholder: t('objectsStates.placeholder.internalId'),
      label: t('objectsStates.label.internalId'),
      rules: {
        required: true,
        validate: value => {
          if (allObjectsStates.find(ObjectsState => ObjectsState.objectCode === value)) {
            return `${value} ${t('objectsStates.errors.alreadyUsed')}`
          }
        },
      },
      disabled: isEdit,
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      loading: isLoadingObjectStateById,
      placeholder: t('objectsStates.placeholder.title'),
      label: t('objectsStates.label.title'),
      rules: {
        required: true,
      },
    },
    {
      name: 'object',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('objectsStates.placeholder.object'),
      label: t('objectsStates.label.object'),
      disabled: isEdit,
      rules: {
        required: true,
      },
      autocompleteOptions: fieldOptions,
    },
    {
      name: 'objectField',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('objectsStates.placeholder.objectField'),
      label: t('objectsStates.label.objectField'),
      disabled: isEdit,
      rules: {
        required: true,
      },
      autocompleteOptions: listFieldsSelectedObject,
    },
    {
      name: 'jsFieldRow',
      inputs: [
        {
          name: 'isJsObjectStates',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          labelPlacement: 'end',
          disabled: true, // TODO нужна отдельная доработка, также нужно получать значения из ячеек при создании стилевых настроек для конкретной ячейки
          label: t('objectsStates.label.javascript'),
          formLabelSx: {
            width: 104,
          },
        },
        {
          name: 'titleJsValue',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('objectsStates.placeholder.javascript'),
          maxLengthInput: MAX_INPUT_LENGTH,
          readOnly: true,
          rules: {
            maxLength: MAX_INPUT_LENGTH,
          },
          formInputContainerSx: {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          },
          formLabelSx: {
            ml: 2,
          },
          additionalBtn: {
            isEnabled: true,
            text: 'edit',
            color: 'primary',
            variant: 'contained',
            disabled: !watchIsJsObjectStates,
            onClick: () => handleSetShowScriptValueDialogTitle(true),
          },
        },
      ],
    },
  ]

  const currentTab = 0

  return (
    <PageObjectStatesContext.Provider value={contextValue}>
      <PageContentLayout>
        <Box sx={{ width: '100%' }}>
          <form>
            <FormProvider {...methods}>
              <Box p={1} pb={0}>
                <Grid container sx={{ pb: 2 }}>
                  <Typography variant='h5'>{t(`objectsStates.title.${modelType}`)}</Typography>
                </Grid>
                <FormGenerator inputs={objectStateFields} />
                {showScriptValueDialogTitle && (
                  <ScriptValueDialog
                    isShow={showScriptValueDialogTitle}
                    modalType={MODAL_TYPE.EDIT}
                    objectFields={fieldsSelectedObject}
                    value={titleJsValue || ''}
                    onClose={() => handleSetShowScriptValueDialogTitle(false)}
                    onSave={value => setValue(value, { shouldDirty: true })}
                  />
                )}
                <Box sx={{ p: 0, m: 0 }}>
                  <TabPanel index={0} value={currentTab}>
                    <SwitchTable
                      disablePagination
                      disableSort
                      isCrud
                      btnDisabled={watchIsJsObjectStates || !isEdit}
                      btnText={t(`objectsStates.button.addState`)}
                      columns={OBJECT_STATE_VALUES_COLUMNS}
                      disabledDeleteActionColumn={watchIsJsObjectStates}
                      disabledEditActionColumn={watchIsJsObjectStates}
                      loading={false}
                      showActionsColumn={true}
                      showDialog={showDialog}
                      type='object_states'
                      rows={
                        rows as Omit<ObjectStateValueShotType, 'id'> & {
                          id: string | number
                        } & Array<any>
                      }
                      onDelete={handleDeleteObjectStateValue}
                      onEdit={params => handleEdit(params.row.original.id)}
                      onShowDialog={handleOpenDialog}
                    />
                  </TabPanel>
                </Box>
              </Box>
              <Grid
                container
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                pb='5px'
              >
                <Box></Box>
                <Box>
                  <Button sx={{ mr: '10px' }} variant='outlined' onClick={handleCancel}>
                    {t('objectsStates.button.close')}
                  </Button>
                  <Button
                    disabled={!isDirty && !dirtyOnField}
                    sx={{ mr: '18px' }}
                    variant='contained'
                    onClick={handleSave}
                  >
                    {t('objectsStates.button.save')}
                  </Button>
                </Box>
              </Grid>
            </FormProvider>
          </form>
        </Box>
      </PageContentLayout>
    </PageObjectStatesContext.Provider>
  )
}

import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { transformResponseWithPagination } from '@helpers'
import {
  GETDictionaryByCategoryCode,
  GETDictionaryCategory,
  GetDictionaryData,
  GETObjectDataParams,
} from '@types'

import { commonAPI } from './common.api'

export const dictionaryAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllDictionaries: build.query<GetDictionaryData, GETObjectDataParams | void>({
      query: params => ({
        url: 'dictionary',
        params: {
          page: params?.page,
          size: params?.size,
        },
      }),
      transformResponse: (output: GETDictionaryByCategoryCode[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<GETDictionaryByCategoryCode[]>(output, meta),
    }),
    fetchDictionariesByCategoryCode: build.query<
      GETDictionaryByCategoryCode[],
      string | null | void
    >({
      query: catCode => ({
        url: `dictionary/category/${catCode}`,
      }),
    }),
    fetchAllDictionaryCategories: build.query<GETDictionaryCategory[], void>({
      query: () => ({
        url: 'dictionary/categories',
      }),
    }),
  }),
})

export const {
  useFetchAllDictionariesQuery,
  useFetchDictionariesByCategoryCodeQuery,
  useFetchAllDictionaryCategoriesQuery,
} = dictionaryAPI

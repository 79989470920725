import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldForm } from '@pages/FormCreateOrEdit/components/AddFieldDialog/helpers/getDefaultValue'

import { mapValueTypeToValueFormat } from '@helpers'
import { BIND_TYPE, DROPDOWN_FILTER_TYPES, MODAL_TYPE, OBJECT_FIELD_TYPE } from '@constants'
import {
  FormRow,
  GETEntityFilter,
  GETShareColumn,
  ModalType,
  MutationType,
  ObjectFieldDTO,
  PayloadCreateField,
} from '@types'

type UseHandlersParams = {
  objectField: ObjectFieldDTO | undefined
  formCode: string
  embeddedObjectCode: string
  methods: UseFormReturn<FieldForm, any, undefined>
  isEdit: boolean
  modalType: ModalType
  currentRow: GETShareColumn | null | undefined
  editField: MutationType<PayloadCreateField, FormRow> | undefined
  handleCloseModal: (isDirty?: boolean | undefined) => void
  addField: MutationType<PayloadCreateField, FormRow>
  defaultValues: FieldForm
}

export const useHandlers = ({
  objectField,
  formCode,
  embeddedObjectCode,
  methods,
  isEdit,
  modalType,
  currentRow,
  editField,
  handleCloseModal,
  addField,
  defaultValues,
}: UseHandlersParams) => {
  const { t } = useTranslation()

  const { reset, watch, setValue } = methods

  const [isShowFilterDialog, setShowFilterDialog] = useState(false)
  const [currentTypeFilter, setCurrentTypeFilter] = useState<DROPDOWN_FILTER_TYPES | null>(null)
  const [currentFilter, setCurrentFilter] = useState<GETEntityFilter | null>(null)

  const isObjectLinkedValue = objectField?.type === OBJECT_FIELD_TYPE.OBJECT_LINK

  const watchBinding = watch('bindType')
  const watchedDropDownListFilters = watch('dropDownListFilters')
  const watchedDropDownWindowFilters = watch('dropDownWindowFilters')

  const handleSave = (data: FieldForm) => {
    const {
      id = Date.now(),
      code,
      title,
      value,
      objectValue,
      objectFormCode,
      bindType = BIND_TYPE.FIELD,
      required,
      params,
      json,
      dropDownListCode,
      dropDownWindowCode,
      dropDownListFilters,
      dropDownWindowFilters,
      isDefaultPlaceholder,
      placeholderValue,
      formatDate,
      asCheckbox,
      asDuration,
      editField: isEdit,
      preFillLink,
      preFillSourceObject,
      isMultiline,
    } = data

    const valueFormat = {
      valueFormat: objectField?.valueType
        ? mapValueTypeToValueFormat(objectField?.valueType, asCheckbox, asDuration)
        : null,
    }

    const requestBody: PayloadCreateField = {
      code,
      title,
      value,
      bindType,
      id,
      formCode,
      editField: isEdit,
      objectValue: isObjectLinkedValue ? objectValue : undefined,
      embeddedObjectCode: isObjectLinkedValue ? embeddedObjectCode : undefined,
      userRequired: watchBinding === BIND_TYPE.FIELD ? required : null,
      ...(json
        ? { params }
        : {
            objectFormCode:
              typeof objectFormCode === 'object' && objectFormCode?.id
                ? objectFormCode.id.toString()
                : undefined,
          }),
      dropDownListCode:
        typeof dropDownListCode === 'object' && dropDownListCode?.id
          ? (dropDownListCode?.id as string)
          : '',
      dropDownWindowCode:
        typeof dropDownWindowCode === 'object' && dropDownWindowCode?.id
          ? (dropDownWindowCode?.id as string)
          : '',
      dropDownListFilters: dropDownListFilters || [],
      dropDownWindowFilters: dropDownWindowFilters || [],
      placeholderValue,
      isDefaultPlaceholder,
      formatDate: formatDate ? formatDate.label : null,
      preFillLink: preFillSourceObject && preFillLink?.length > 0 ? preFillLink : null,
      isMultiline,
      ...valueFormat,
    }

    if (modalType === MODAL_TYPE.EDIT && currentRow) {
      editField?.(requestBody)
        .unwrap()
        .then(() => handleCloseModal())
        .catch(console.error)

      return
    }

    addField?.(requestBody)
      .unwrap()
      .then(() => handleCloseModal())
      .catch(console.error)

    reset(defaultValues)
  }

  const handleDoubleClickChip = (filter: GETEntityFilter, type: DROPDOWN_FILTER_TYPES) => {
    setCurrentFilter(filter)
    setCurrentTypeFilter(type)
    setShowFilterDialog(true)
  }

  const handleAddFilter = (type: DROPDOWN_FILTER_TYPES) => {
    setShowFilterDialog(true)
    setCurrentTypeFilter(type)
  }

  const handleResetFilterDialog = () => {
    setShowFilterDialog(false)
    setCurrentFilter(null)
    setCurrentTypeFilter(null)
  }

  const handleSubmitFilter = (filter: GETEntityFilter) => {
    if (currentTypeFilter) {
      const newFilter = { ...filter, type: currentTypeFilter }
      const isList = currentTypeFilter === DROPDOWN_FILTER_TYPES.LIST
      const inputName = isList ? 'dropDownListFilters' : 'dropDownWindowFilters'
      const updatedFilters = (isList ? watchedDropDownListFilters : watchedDropDownWindowFilters)
        .map(item => (item.id === filter.id ? newFilter : item))
        .map(item => (item.id === filter.id ? newFilter : item))

      if (filter.id) {
        setValue(inputName, updatedFilters, {
          shouldDirty: true,
        })

        handleResetFilterDialog()

        return
      }

      setValue(
        inputName,
        [...(isList ? watchedDropDownListFilters : watchedDropDownWindowFilters), newFilter],
        {
          shouldDirty: true,
        }
      )
      handleResetFilterDialog()
    }
  }

  const handleDeleteFilter = (filter: GETEntityFilter, type: DROPDOWN_FILTER_TYPES) => {
    if (type === DROPDOWN_FILTER_TYPES.LIST) {
      setValue(
        'dropDownListFilters',
        watchedDropDownListFilters.filter(({ id }) => id !== filter.id),
        { shouldDirty: true }
      )
    }
    if (type === DROPDOWN_FILTER_TYPES.WINDOW) {
      setValue(
        'dropDownWindowFilters',
        watchedDropDownWindowFilters.filter(({ id }) => id !== filter.id),
        { shouldDirty: true }
      )
    }
  }

  const handleCloseFilterDialog = (isDirty: boolean) => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    handleResetFilterDialog()
  }

  return {
    state: {
      isShowFilterDialog,
      currentFilter,
    },
    handlers: {
      handleSave,
      handleDoubleClickChip,
      handleAddFilter,
      handleSubmitFilter,
      handleDeleteFilter,
      handleCloseFilterDialog,
    },
  }
}

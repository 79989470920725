import { FC } from 'react'
import { Add, ContentCopy, Delete, Edit, MoreHoriz, Send } from '@mui/icons-material'
import { Grid, IconButton, ListItemIcon } from '@mui/material'

type IconButtonsProps = {
  onAdd?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onCopy?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onEdit?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onAdditionalButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onMore?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const IconButtons: FC<IconButtonsProps> = ({
  onAdd,
  onCopy,
  onDelete,
  onEdit,
  onAdditionalButtonClick,
  onMore,
}) => {
  return (
    <Grid container item justifyContent={'flex-end'}>
      {onCopy && (
        <IconButton onClick={onCopy}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <ContentCopy />
          </ListItemIcon>
        </IconButton>
      )}
      {onAdd && (
        <IconButton onClick={onAdd}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Add />
          </ListItemIcon>
        </IconButton>
      )}
      {onEdit && (
        <IconButton onClick={onEdit}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Edit />
          </ListItemIcon>
        </IconButton>
      )}
      {onAdditionalButtonClick && (
        <IconButton onClick={onAdditionalButtonClick}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Send />
          </ListItemIcon>
        </IconButton>
      )}
      {onDelete && (
        <IconButton onClick={onDelete}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Delete />
          </ListItemIcon>
        </IconButton>
      )}
      {onMore && (
        <IconButton onClick={onMore}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <MoreHoriz fontSize='small' />
          </ListItemIcon>
        </IconButton>
      )}
    </Grid>
  )
}

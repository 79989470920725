import { Rnd } from 'react-rnd'

import { HandleSetOpenedContainersParams } from '@pages/ConfiguredEntity/types'

import {
  closeDialog,
  setSelectedViewDialog,
  transformCreatingFormDialogToEditing,
} from '@redux/reducers/dialogWindowManager.reducer'

import { DraggableFormState } from '@features/DialogWindowManager/DialogWindow'

import { useAppDispatch, useAppSelector } from '@hooks'
import { DRAGGABLE_FORM_SIZES } from '@constants'

import { EVENT_NAME, publish } from '../../../events'
import { LinkType, ObjectDataRecord } from '../../../types'
import { isFormDialog } from '../DialogWindowManager'

export const useFormEventHandlers = () => {
  const { dialogWindows, selectedId } = useAppSelector(state => state.dialogWindowManager)
  const dispatch = useAppDispatch()

  const handleUpdateSamePageFormData = (
    oldObjectId: string,
    newFormCode: string,
    objectCode: string,
    objectId: string
  ) => {
    console.log('handleUpdateSamePageFormData')
  }

  const handleRemoveSamePageForm = (formDataId?: number, isDirty?: boolean) => {
    console.log('handleRemoveSamePageForm')
  }

  const handleNavigateToDefaultPage = () => {
    console.log('handleNavigateToDefaultPage')
  }

  const handleCloseCurrentFormTab = () => {
    console.log('handleCloseCurrentFormTab')
  }

  const onObjectDataRecordSuccessCreating =
    (dialogId: string, rnd: Rnd) => (links: LinkType[], data: ObjectDataRecord) => {
      const currentDialog = dialogWindows.find(dialog => dialog.id === dialogId)

      // Если форма открыта через контрол панель
      if (currentDialog && isFormDialog(currentDialog) && currentDialog.meta.isIndependentForm) {
        dispatch(closeDialog({ id: dialogId }))

        return
      }

      const position = rnd.getDraggablePosition()
      dispatch(
        transformCreatingFormDialogToEditing({
          id: dialogId,
          // TODO _id здесь возможно нужно сначала найти PK, а потом уже его использовать
          objectId: data._id,
          initialPosition: position,
        })
      )
    }

  const handleChangeFormCode = (formCode: string, verifyFormCode: string) => {
    console.log('handleChangeFormCode')
  }

  const onSetOpenedContainersInStaticForms = (params: HandleSetOpenedContainersParams) => {
    console.log('onSetOpenedContainersInStaticForms')
  }

  const onBreadcrumbsMainLinkClick =
    (
      dialogId: string,
      parentDialogId: string | null,
      rnd: Rnd,
      state: Readonly<DraggableFormState>
    ) =>
    () => {
      const position = rnd.getDraggablePosition()
      const bounding = rnd.getSelfElement()?.getBoundingClientRect()
      const isFullscreen = state.isFullscreen
      const sizeBeforeFullscreen = state.sizeBeforeFullscreen
      const positionBeforeFullscreen = state.positionBeforeFullscreen
      dispatch(setSelectedViewDialog({ id: parentDialogId }))
      if (dialogWindows.find(dialog => dialog.id === parentDialogId)?.hidden) {
        // Для создания ощущения возврата к представлению из формы, устанавливаем представлению позицию и размер формы
        publish(EVENT_NAME.DIALOG_WINDOW_CHANGE_POSITION, { id: parentDialogId, position })
        publish(EVENT_NAME.DIALOG_WINDOW_CHANGE_SIZE, {
          id: parentDialogId,
          size: {
            width: bounding?.width ?? DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
            height: bounding?.height ?? DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
          },
        })
        publish(EVENT_NAME.DIALOG_WINDOW_CHANGE_FULLSCREEN_MODE, {
          id: parentDialogId,
          isFullscreen,
          sizeBeforeFullscreen,
          positionBeforeFullscreen,
        })
      }
      dispatch(closeDialog({ id: dialogId }))
    }

  return {
    handleUpdateSamePageFormData,
    handleRemoveSamePageForm,
    handleNavigateToDefaultPage,
    handleCloseCurrentFormTab,
    onObjectDataRecordSuccessCreating,
    handleChangeFormCode,
    onSetOpenedContainersInStaticForms,
    onBreadcrumbsMainLinkClick,
  }
}

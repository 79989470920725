import { FIELD_VALUE_TYPE } from '@constants'
import { FormRow, ObjectDataRecord } from '@types'

// Формирует значение по-умолчанию в зависимости от типа филда
export const getDefaultValueByType = (field: FormRow, row: ObjectDataRecord) => {
  const valueType = field.valueType
  const valueName = field.code
  const value = row[valueName]
  const objectValue = field.objectValue

  switch (valueType) {
    case FIELD_VALUE_TYPE.DATETIME:
      return [valueName, value ?? null]
    case FIELD_VALUE_TYPE.OBJ_EMBEDDED:
    case FIELD_VALUE_TYPE.OBJ_PK_LINK:
    case FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK:
      return [valueName, value ? { id: value, label: row[`${valueName}.${objectValue}`] } : null]
    default:
      return [valueName, value ?? '']
  }
}

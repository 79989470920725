import { FC, useContext, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { Box, Button, Grid, Typography } from '@mui/material'

import { getInitialExpandedContainers } from '@components/DisplayForm/helpers'
import {
  useCreateObjectDataRecordWithFixedKey,
  useDisplayFormState,
  useUpdateObjectDataRecordWithFixedKey,
} from '@components/DisplayForm/hooks'

import { setOpenedContainerForm } from '@redux/reducers/formStates.reducer'

import { useAppDispatch, useAppSelector, useKeyPress, useSubscribeEvent } from '@hooks'
import { buttonStylesByMode } from '@helpers'
import { FORM_TYPE, HOT_KEY } from '@constants'

import { EVENT_NAME, publish } from '../../../../events'
import { FormContext } from '../../DisplayForm'
import { Row } from '../../types'
import { ConfiguredFormElements } from '../ConfiguredFormElements'

type DisplayFormContentProps = {
  methods: UseFormReturn<Row, any>
  isDirty: boolean
}

export const DisplayFormContent: FC<DisplayFormContentProps> = ({ methods, isDirty }) => {
  const {
    form,
    type,
    isViewEdit,
    handleEditOrSaveClick,
    handleCancel: onCancel,
    isDeleteRow,
    isFetchingFormError,
    globalId,
    row,
    skipHotKeyPress,
    isDropdownWindowOpenFormState,
    containers,
    onSetWindowTitle,
    dialogId,
    isSaveByEnterDisabled,
    isPendingCreateOrUpdateForm,
  } = useContext(FormContext)

  const { isFormDialogWindow } = useDisplayFormState()

  const [, { isLoading: isLoadingUpdateRow }] = useUpdateObjectDataRecordWithFixedKey(globalId)

  const [, { isLoading: isLoadingCreateRow }] = useCreateObjectDataRecordWithFixedKey(globalId)

  const formStates = useAppSelector(state => state.formStates)
  const dispatch = useAppDispatch()
  const [expandedContainers, setExpandedContainers] = useState(
    getInitialExpandedContainers(form?.code, formStates)
  )

  const { t } = useTranslation()

  const handleToggleExpandedContainers = (expanded: boolean) => {
    publish(EVENT_NAME.CONFIGURED_FORM_TOGGLE_OPENED_CONTAINERS, {
      expanded: !expanded,
      dialogId,
    })

    containers.forEach(container => {
      dispatch(
        setOpenedContainerForm({
          containerId: container.id,
          formCode: container.formCode,
          open: !expanded,
        })
      )
    })

    setExpandedContainers(!expanded)
  }

  const [activeElementBeforModeTrigger, setActiveElementBeforModeTrigger] =
    useState<Element | null>(null)

  const { keyPressed: changeModeFormTrigger } = useKeyPress(
    HOT_KEY.ENTER,
    undefined,
    skipHotKeyPress || isDropdownWindowOpenFormState || isSaveByEnterDisabled,
    'shiftKey'
  )
  const { keyPressed: exitEditModeFormTrigger, setKeyPressed: setExitEditModeKeyPressed } =
    useKeyPress(HOT_KEY.ESCAPE, undefined, skipHotKeyPress || isDropdownWindowOpenFormState)

  useEffect(() => {
    if (changeModeFormTrigger && (!isLoadingUpdateRow || !isLoadingCreateRow)) {
      setActiveElementBeforModeTrigger(document.activeElement)
      handleEditOrSaveClick()

      return
    }
    if (exitEditModeFormTrigger && !changeModeFormTrigger) {
      setTimeout(() => (activeElementBeforModeTrigger as HTMLElement)?.focus(), 0)

      handleCancel()
      setExitEditModeKeyPressed(false)

      return
    }
  }, [exitEditModeFormTrigger, changeModeFormTrigger])

  useSubscribeEvent(EVENT_NAME.CONFIGURED_FORM_HAS_OPENED_CONTAINER, data => {
    setExpandedContainers(data.detail)
  })

  const isCreateOrEdit: boolean = type === FORM_TYPE.EDIT || type === FORM_TYPE.CREATE || isViewEdit

  const [resetFormKey, setResetFormKey] = useState(uuid())

  const handleCancel = () => {
    setResetFormKey(uuid())
    onCancel()
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isDeleteRow ? (
        <Typography textAlign={'center'}>{t('form.deleteRow')}</Typography>
      ) : (
        <Grid
          container
          alignContent='space-between'
          alignItems='center'
          justifyContent='space-between'
          sx={{ mt: 0.5, height: '100%' }}
        >
          {isFetchingFormError ? (
            <Typography align='center'>{t('form.noForm')}</Typography>
          ) : (
            <ConfiguredFormElements key={resetFormKey} />
          )}
          {!isFetchingFormError && (
            <Grid
              container
              display='flex'
              flexDirection='row'
              height='28px'
              justifyContent='space-between'
            >
              <Box mb='10px'>
                <Button
                  sx={theme => ({
                    ...buttonStylesByMode(theme, isFormDialogWindow),
                    ml: '3px',
                  })}
                  onClick={() => handleToggleExpandedContainers(expandedContainers)}
                >
                  {!expandedContainers ? t('form.show') : t('form.hide')}
                </Button>
              </Box>
              <Box>
                <Button
                  disabled={!isDirty && (type !== FORM_TYPE.VIEW || isViewEdit)}
                  sx={theme => ({
                    ...buttonStylesByMode(theme, isFormDialogWindow),
                    mr: isCreateOrEdit ? '15px' : '3px',
                  })}
                  onClick={handleEditOrSaveClick}
                >
                  {isCreateOrEdit ? t('form.save') : t('form.edit')}
                </Button>
                {isCreateOrEdit && (
                  <Button
                    disabled={isViewEdit ? false : !isDirty}
                    sx={theme => ({
                      ...buttonStylesByMode(theme, isFormDialogWindow),
                      mr: '3px',
                    })}
                    onClick={handleCancel}
                  >
                    {t('form.close')}
                  </Button>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GridSortModel } from '@microservices/wiskey-react-components'

import { useDeleteStyleSettingMutation } from '@redux/api/styleSetting.api'

import { MODAL_TYPE, ROUTES } from '@constants'

export const useHandlers = () => {
  const navigate = useNavigate()

  const [currentSort, setCurrentSort] = useState<GridSortModel | undefined>([])

  const [deleteStyleSetting] = useDeleteStyleSettingMutation()

  const handleCreateStyleSetting = () => {
    navigate(MODAL_TYPE.CREATE)
  }

  const handleEditStyleSetting = (id: number) => {
    navigate(`${ROUTES.CONFIG_STYLE_SETTINGS_EDIT}${id}`)
  }

  const handleDeleteStyleSetting = (id: number) => {
    deleteStyleSetting(id)
  }

  const handleSetSorting = (value: GridSortModel | null) => {
    setCurrentSort(value ? value : [])
  }

  return {
    state: { currentSort },
    data: {},
    handlers: {
      handleCreateStyleSetting,
      handleEditStyleSetting,
      handleDeleteStyleSetting,
      handleSetSorting,
    },
  }
}

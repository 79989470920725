import { FC } from 'react'
import { TableRow as MUITableRow, TableRowProps as MUITableRowProps } from '@mui/material'

export type TableRowProps = MUITableRowProps

export const TableRow: FC<TableRowProps> = ({ children, sx, ...props }) => {
  return (
    <MUITableRow
      {...props}
      component={'div'}
      sx={{
        height: 32,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        boxSizing: 'border-box',
        // minWidth: '100%',
        width: '100%',
        background: theme => theme.palette.background.paper,
        ...sx,
      }}
    >
      {children}
    </MUITableRow>
  )
}

import {
  GETObjectDataParams,
  GETStyleSettingParams,
  POSTStyleSettingParams,
  ShortStyleSetting,
  StyleSetting,
} from '@types'

import { commonAPI } from './common.api'

export const styleSettingAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllStyleSettings: build.query<ShortStyleSetting[], GETObjectDataParams | void>({
      query: params => ({
        url: '/ui/styleSetting',
        params: {
          searchPattern: params?.searchPattern,
          sort: params?.sort,
        },
      }),
      providesTags: ['AllStyleSettings'],
    }),
    fetchStyleSettingById: build.query<StyleSetting, GETStyleSettingParams>({
      query: ({ id }) => ({
        url: `/ui/styleSetting/id/${id}`,
      }),
      providesTags: ['SingleStyleSetting'],
    }),
    createOrUpdateStyleSetting: build.mutation<StyleSetting, POSTStyleSettingParams>({
      query: styleSetting => ({
        url: `/ui/styleSetting`,
        method: 'POST',
        body: styleSetting,
      }),
      invalidatesTags: ['AllStyleSettings', 'SingleStyleSetting'],
    }),
    deleteStyleSetting: build.mutation<void, number>({
      query: id => ({
        url: `/ui/styleSetting/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllStyleSettings', 'SingleStyleSetting'],
    }),
  }),
})

export const {
  useFetchAllStyleSettingsQuery,
  useFetchStyleSettingByIdQuery,
  useCreateOrUpdateStyleSettingMutation,
  useDeleteStyleSettingMutation,
} = styleSettingAPI

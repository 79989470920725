import { DATE_RANGE_POSITION, DEFAULT_FORMAT_DATE } from '@constants'
import { GETCriteriaValueType } from '@types'

export const getDatePickerRangePlaceholderCriteria = (
  criteria: GETCriteriaValueType,
  position: DATE_RANGE_POSITION
) => {
  switch (position) {
    case DATE_RANGE_POSITION.START:
      if (criteria.isDefaultPlaceholder) {
        return ''
      }
      if (criteria.placeholderStart) {
        return criteria.placeholderStart
      }

      return criteria.formatDate || DEFAULT_FORMAT_DATE
    case DATE_RANGE_POSITION.END:
      if (criteria.isDefaultPlaceholder) {
        return ''
      }
      if (criteria.placeholderEnd) {
        return criteria.placeholderEnd
      }

      return criteria.formatDate || DEFAULT_FORMAT_DATE
  }
}

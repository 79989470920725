import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { ACTION_CODE } from '@constants'
import { SelectOption } from '@types'

import { AccordionConfigExamples, BtnCopyConfigExample } from '@gantt/components/ConfigExamples'
import {
  AvailableVariables,
  CONTEXT_MENU_BIND_TYPE,
  GANTT_BIND_TYPE_LIST,
  OPEN_MODAL_BIND_TYPE,
  PageContext,
  TOOLTIP_BINDING_TYPE,
} from '@gantt/components/GanttCreateOrEdit'
import {
  COMMON_META_DATA,
  contextMenuAvailableKeys,
  initialAvailableVariables,
  tooltipAvailableKeys,
} from '@gantt/constants'
import {
  checkValidGanttJS,
  checkValidGanttJSON,
  getBindTypeOptionsByEnum,
  getOptionByStr,
} from '@gantt/helpers'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

import { VariablesList } from '../GanttConfiguration/components/VariablesList'

import { useAddGanttActionTimelinesDialog } from './hooks/useAddGanttActionTimelinesDialog'

const getHints = (actionCode: ACTION_CODE) => {
  return {
    json:
      actionCode === ACTION_CODE.OPEN_CONTEXT_MENU
        ? i18next.t('ganttCreate.timelineForm.hint.contextMenuJsonHint')
        : undefined,
  }
}

const tooltipHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
  json: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
}

const tooltipPlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.tooltipJS'),
}

const commandPlaceholders = {
  command: i18next.t('placeholder.selectCommand'),
}

const formPlaceholders = {
  form: i18next.t('placeholder.objectFormCode'),
}

export const AddGanttActionTimelinesDialog = () => {
  const { showDialog, handleCloseModal, currentBarObject, availableVariables } =
    useContext(PageContext)
  const {
    actionInputs,
    isDirty,
    methods,
    handleSave,
    key,
    loadingAllForm,
    formCodeOptions,
    watchActionCode,
    watchActionFieldBindType,
  } = useAddGanttActionTimelinesDialog()
  const { t } = useTranslation()
  const [isInvalidScript, setIsInvalidScript] = useState(false)

  const bindings: SelectOption[] = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return getBindTypeOptionsByEnum(CONTEXT_MENU_BIND_TYPE)
    }

    if (watchActionCode === ACTION_CODE.OPEN_FORM_MODEL) {
      return [
        getOptionByStr(OPEN_MODAL_BIND_TYPE.FORM, t('ganttCreate.timelineForm.bindTypeForm')),
        getOptionByStr(OPEN_MODAL_BIND_TYPE.JSON),
      ]
    }

    if (watchActionCode === ACTION_CODE.EXECUTE_COMMAND) {
      return [getOptionByStr(GANTT_BIND_TYPE_LIST.COMMANDS)]
    }

    return []
  }, [watchActionCode])

  const handleClose = () => {
    handleCloseModal(isDirty)
  }

  const availableVariablesByCode: AvailableVariables = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return availableVariables
    }

    return { ...initialAvailableVariables(), ...COMMON_META_DATA }
  }, [watchActionCode, availableVariables])

  const funValidator = useMemo(
    () => (value: string, returnValueType: GANTT_SCRIPT_RETURN_TYPE, keys?: string[]) => {
      if (watchActionFieldBindType === GANTT_BIND_TYPE_LIST.JSON) {
        return checkValidGanttJSON(value, returnValueType, keys)
      }

      return checkValidGanttJS(value, availableVariablesByCode, returnValueType, keys)
    },
    [availableVariablesByCode, watchActionFieldBindType]
  )

  const funValidatorContextMenu = useCallback(
    (value: string) => {
      return funValidator(
        value,
        GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS,
        contextMenuAvailableKeys
      )
    },
    [watchActionFieldBindType]
  )

  const funValidatorTooltip = useCallback((value: string) => {
    return funValidator(value, GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS, tooltipAvailableKeys)
  }, [])

  const handleAfterValidationScript = useCallback((state: boolean) => {
    setIsInvalidScript(state)
  }, [])

  useEffect(() => {
    handleAfterValidationScript(false)
  }, [watchActionFieldBindType])

  return (
    <ModalWrapper
      btnText={t('ganttCreate.common.apply')}
      disabledSave={!isDirty || isInvalidScript}
      isShow={showDialog}
      onClose={handleClose}
      onSave={handleSave}
    >
      {loadingAllForm ? (
        <Grid container alignItems='center' justifyContent='center' my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box
          key={`AddGanttActionTimelinesDialog_${String(key)}`}
          maxHeight={500}
          overflow='auto'
          pb={2}
          pr={2}
          pt={2}
        >
          <FormProvider {...methods}>
            <form>
              <FormGenerator inputs={actionInputs} />
              {watchActionCode === ACTION_CODE.OPEN_TOOLTIP && (
                <>
                  <BindTypeInputs
                    bindTypeOptions={getBindTypeOptionsByEnum(TOOLTIP_BINDING_TYPE)}
                    containerName='actionField'
                    hintDict={tooltipHints}
                    placeholderDict={tooltipPlaceholders}
                    watchedObject={currentBarObject}
                    scriptEditor={{
                      validator: funValidatorTooltip,
                      onAfterValidation: handleAfterValidationScript,
                    }}
                  />
                  {watchActionFieldBindType &&
                    [GANTT_BIND_TYPE_LIST.JS, GANTT_BIND_TYPE_LIST.JSON].includes(
                      watchActionFieldBindType
                    ) && (
                      <AccordionConfigExamples>
                        <VariablesList variables={availableVariables} />
                      </AccordionConfigExamples>
                    )}
                </>
              )}
              {watchActionCode === ACTION_CODE.EXECUTE_COMMAND && (
                <BindTypeInputs
                  bindTypeOptions={bindings}
                  containerName='actionField'
                  placeholderDict={commandPlaceholders}
                  valueInputLabel={t('ganttCreate.resourceForm.command')}
                  watchedObject={currentBarObject}
                  commandPicker={{
                    isTypeHidden: true,
                  }}
                />
              )}
              {[ACTION_CODE.OPEN_CONTEXT_MENU, ACTION_CODE.OPEN_FORM_MODEL].includes(
                watchActionCode
              ) && (
                <>
                  <BindTypeInputs
                    bindTypeOptions={bindings}
                    containerName='actionField'
                    hintDict={getHints(watchActionCode)}
                    isShownBinding={Boolean(bindings?.length)}
                    placeholderDict={formPlaceholders}
                    valueOptions={formCodeOptions}
                    scriptEditor={{
                      validator: funValidatorContextMenu,
                      onAfterValidation: handleAfterValidationScript,
                    }}
                    valueInputLabel={
                      watchActionCode === ACTION_CODE.OPEN_FORM_MODEL ? t('label.code') : undefined
                    }
                  />
                  {watchActionFieldBindType &&
                    [GANTT_BIND_TYPE_LIST.JS, GANTT_BIND_TYPE_LIST.JSON].includes(
                      watchActionFieldBindType
                    ) && (
                      <AccordionConfigExamples>
                        <VariablesList variables={availableVariables} />
                      </AccordionConfigExamples>
                    )}
                </>
              )}
            </form>
          </FormProvider>
          <Box display='flex' flexWrap='wrap' mb={1}>
            <Box pr={1}>{t('ganttCreate.configExamples.blockTitle')}</Box>
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.contextHeaderMenuY')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.contextMenuWithCommand')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.simpleTooltip')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.groupTooltip')} />
          </Box>
        </Box>
      )}
    </ModalWrapper>
  )
}

import { ChangeEvent, FC, MouseEvent } from 'react'
import { Button } from '@microservices/wiskey-react-components'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { styled } from '@mui/material/styles'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type InputFileUploadProps = {
  onChange: (files: FileList | null) => void
  disabledButton: boolean
}

export const InputFileUpload: FC<InputFileUploadProps> = ({ onChange, disabledButton }) => {
  const onInputClick = (event: MouseEvent<HTMLInputElement>) => {
    const element = event.target as HTMLInputElement
    // нужно очистить кэш, чтобы была возможность загрузить файл с тем же именем (кэш хранится в event.target.value)
    // value равен 'C:\\fakepath\\filename.csv'
    // event'ы из onInputClick и onFileChanged связаны между собой
    element.value = ''
  }

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.files)
  }

  return (
    <Button
      component='label'
      disabled={disabledButton}
      role={undefined}
      startIcon={<CloudUploadIcon />}
      tabIndex={-1}
      variant='contained'
    >
      Upload file
      <VisuallyHiddenInput
        type='file'
        onClick={event => onInputClick(event)}
        accept='.csv'
        // multiple
        onChange={onFileChanged}
      />
    </Button>
  )
}

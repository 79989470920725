import { ELEMENT_TYPE } from '@constants'

export const getBackgroundByElementType = (
  background: boolean | null,
  elementType: ELEMENT_TYPE | undefined
) => {
  if (!background) {
    return elementType === ELEMENT_TYPE.VIEW ? false : null
  }

  return background
}

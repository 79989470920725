import i18next from 'i18next'

import { ErrorValidJSType } from './checkValidJS'

type ObjectWithAnyKeys<T> = Record<string, T>

const KEYS_ARR_OF_OBJ = ['items']

export const checkAvailableKeyInArrayOfObjects: <T>(
  value: ObjectWithAnyKeys<T>[],
  keys?: string[] | undefined,
  requiredKeys?: string[]
) => ErrorValidJSType | undefined = <T>(
  value: ObjectWithAnyKeys<T>[],
  keys?: string[],
  requiredKeys?: string[]
) => {
  if (!keys) {
    return { error: false }
  }

  for (const obj of value) {
    for (const key in obj) {
      if (KEYS_ARR_OF_OBJ.includes(key)) {
        return checkAvailableKeyInArrayOfObjects(
          obj[key] as ObjectWithAnyKeys<T>[],
          keys,
          requiredKeys
        )
      }

      if (!keys.includes(key)) {
        return {
          error: true,
          message: i18next.t(`error.notValidKeyInObject`, { key }),
        }
      }
      if (requiredKeys?.every(key => key in obj)) {
        return {
          error: true,
          message: i18next.t(`error.notValidKeyInObject`, { key }),
        }
      }
    }
  }
}

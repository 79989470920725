import { createContext, FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Box, Grid, Skeleton, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { TextSettings } from '@components/TextSettings'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'

import { useStyleSettingsCreateOrEdit } from './hooks/useStyleSettingsCreateOrEdit'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const StyleSettingsCreateOrEdit: FC = () => {
  const { t } = useTranslation()

  const {
    state: { isDirtyTextSettings },
    data: {
      methods,
      id,
      isCreate,
      textSettingsRef,
      initialTextSettings,
      isLoadingStyleSettingById,
    },
    handlers: { handleCancel, handleSave, handleTextExternalDirty },
  } = useStyleSettingsCreateOrEdit()

  const {
    formState: { isDirty },
  } = methods

  const contextValue = {
    methods,
    id,
    isCreate,
  }

  const modelType = isCreate ? 'create' : 'edit'

  return (
    <PageContext.Provider value={contextValue}>
      <PageContentLayout>
        <Box sx={{ width: '100%' }}>
          <FormProvider {...methods}>
            <Box p={1} pb={0}>
              <Grid container sx={{ pb: 2 }}>
                <Typography variant='h5'>{t(`styleSettings.title.${modelType}`)}</Typography>
              </Grid>
              <FormInput
                disabled={!isCreate}
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                label={t(`styleSettings.internalId.label`)}
                loading={isLoadingStyleSettingById}
                name='code'
                placeholder={t(`styleSettings.internalId.placeholder`)}
                replacePattern={REGEX.MODEL_CODE_REPLACE_PATTERN}
                size='small'
                sx={{ pt: 1 }}
                rules={{
                  required: true,
                  validate: value => value.trim().length !== 0,
                }}
              />
              <FormInput
                inputType={GENERATOR_INPUT_TYPE.INPUT}
                label={t(`styleSettings.title.styleSetting`)}
                loading={isLoadingStyleSettingById}
                name='title'
                placeholder={t(`styleSettings.title.styleSetting`)}
                size='small'
                sx={{ pt: 1 }}
                rules={{
                  required: true,
                }}
              />
              {isLoadingStyleSettingById ? (
                <Box>
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                  <Skeleton height={60} />
                </Box>
              ) : (
                <TextSettings
                  ref={textSettingsRef}
                  borderSettings={true}
                  initialTextState={initialTextSettings}
                  isEdit={true}
                  labelPlacement='top'
                  labelText={t('parameters.visual.columnText')}
                  onSetExternalDirty={handleTextExternalDirty}
                />
              )}

              <Grid container justifyContent='flex-end'>
                <Box mt={2}>
                  <Button
                    disabled={!(isDirty || isDirtyTextSettings)}
                    sx={{ mr: '15px' }}
                    variant='contained'
                    onClick={handleSave}
                  >
                    {t('styleSettings.saveButton')}
                  </Button>
                  <Button variant='outlined' onClick={handleCancel}>
                    {t('styleSettings.cancelButton')}
                  </Button>
                </Box>
              </Grid>
            </Box>
          </FormProvider>
        </Box>
      </PageContentLayout>
    </PageContext.Provider>
  )
}

import { Position } from 'react-rnd'
import { v4 as uuidv4 } from 'uuid'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FORM_TYPE } from '@constants'

import {
  DIALOG_WINDOW_TYPE,
  DialogWindowManagerState,
  DialogWindowType,
  FileUploadDialogWindowMeta,
  FormDialogWindowMeta,
  SavedFiltersWindowMeta,
  StringEditDialogWindowMeta,
  ViewDialogWindowMeta,
} from '../../types'

const initialState: DialogWindowManagerState = {
  selectedId: null,
  dialogWindows: [],
}

const dialogWindowManagerSlice = createSlice({
  name: 'dialogWindowManager',
  initialState,
  reducers: {
    openViewDialog: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType<ViewDialogWindowMeta>>
    ) => {
      state.dialogWindows.push(action.payload)
    },
    openFormDialog: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType<FormDialogWindowMeta>>
    ) => {
      state.dialogWindows.push(action.payload)
    },
    openStringEditDialog: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType<StringEditDialogWindowMeta>>
    ) => {
      state.dialogWindows.push(action.payload)
    },
    openSearchAssistantDialog: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType>
    ) => {
      if (state.dialogWindows.find(dialog => dialog.type === DIALOG_WINDOW_TYPE.SEARCH_ASSISTANT)) {
        return
      }
      state.dialogWindows.push(action.payload)
    },
    openSavedFiltersDialog: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType<SavedFiltersWindowMeta>>
    ) => {
      const savedFiltersDialogIndex = state.dialogWindows.findIndex(
        dialog => dialog.type === DIALOG_WINDOW_TYPE.SAVED_FILTERS
      )
      if (savedFiltersDialogIndex !== -1) {
        const newDialogWindows = [...state.dialogWindows]
        newDialogWindows[savedFiltersDialogIndex].meta = action.payload.meta

        state.dialogWindows = newDialogWindows
        state.selectedId = state.dialogWindows[savedFiltersDialogIndex].id

        return
      }
      state.dialogWindows.push(action.payload)
    },
    openFileUploadDialog: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType<FileUploadDialogWindowMeta>>
    ) => {
      state.dialogWindows.push(action.payload)
    },
    // Скрываем представление и рисуем на его месте форму
    openFormDialogInsteadOfView: (
      state: DialogWindowManagerState,
      action: PayloadAction<DialogWindowType<FormDialogWindowMeta>>
    ) => {
      const parentViewIndex = state.dialogWindows.findIndex(
        dialog => dialog.id === action.payload.parentDialogId
      )
      if (parentViewIndex !== -1) {
        const newDialogWindows = [...state.dialogWindows]
        newDialogWindows[parentViewIndex] = { ...newDialogWindows[parentViewIndex], hidden: true }
        newDialogWindows.push(action.payload)
        state.dialogWindows = newDialogWindows
      }
    },
    // Закрываем форму и закрываем родительское представление, если оно скрыто
    closeFormDialog: (state, action: PayloadAction<{ id: string }>) => {
      const formId = action.payload.id
      const formDialogIndex = state.dialogWindows.findIndex(dialog => dialog.id === formId)
      if (formDialogIndex !== -1) {
        const newDialogWindows = [...state.dialogWindows]
        const formDialog = { ...newDialogWindows[formDialogIndex] }
        const parentViewId = formDialog.parentDialogId

        state.dialogWindows = newDialogWindows.filter(dialog => {
          if (dialog.id === parentViewId && dialog.hidden) return false

          return dialog.id !== formId
        })
      }
    },
    setSelectedViewDialog: (state, action: PayloadAction<{ id: string | null }>) => {
      const viewDialog = state.dialogWindows.find(dialog => dialog.id === action.payload.id)
      if (viewDialog?.hidden) {
        viewDialog.hidden = false
      }
      state.selectedId = action.payload.id
    },
    transformCreatingFormDialogToEditing: (
      state: DialogWindowManagerState,
      action: PayloadAction<{ id: string; objectId: string; initialPosition: Position }>
    ) => {
      const index = state.dialogWindows.findIndex(dialog => dialog.id === action.payload.id)
      if (index !== -1) {
        const newDialogWindows = [...state.dialogWindows]
        const creatingFormDialog = newDialogWindows[index] as DialogWindowType<FormDialogWindowMeta>
        // Attention! Новый id вызовет монтирование, т.к меняется key
        creatingFormDialog.id = uuidv4()
        creatingFormDialog.initialPosition = action.payload.initialPosition
        creatingFormDialog.meta.objectId = action.payload.objectId
        creatingFormDialog.meta.event = FORM_TYPE.EDIT

        state.dialogWindows = newDialogWindows
      }
    },
    closeDialog: (state, action: PayloadAction<{ id: string }>) => {
      state.dialogWindows = state.dialogWindows.filter(dialog => dialog.id !== action.payload.id)
    },
    setSelectedDialog: (state, action: PayloadAction<{ id: string | null }>) => {
      state.selectedId = action.payload.id
    },
  },
})

export default dialogWindowManagerSlice.reducer
export const {
  openViewDialog,
  openSearchAssistantDialog,
  openFormDialog,
  openStringEditDialog,
  openSavedFiltersDialog,
  closeFormDialog,
  setSelectedViewDialog,
  openFileUploadDialog,
  openFormDialogInsteadOfView,
  transformCreatingFormDialogToEditing,
  closeDialog,
  setSelectedDialog,
} = dialogWindowManagerSlice.actions

import { MouseEvent, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'
import isEqual from 'lodash.isequal'
import { v4 as uuid } from 'uuid'
import {
  DataGridProProps as MUIDataGridProps,
  GridCellParams,
  GridPinnedColumns,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from '@microservices/wiskey-react-components'
import { useTheme } from '@mui/material/styles'
import {
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridRowClassNameParams,
} from '@mui/x-data-grid'
import { IMessage } from '@stomp/stompjs'

import { FormContext } from '@components/DisplayForm'

import {
  useFetchListControlOrDropdownObjectDataEnrichedMutation,
  useFetchObjectDataFilterByCodeEnrichedMutation,
  useFetchObjectDataRecordEnrichedByIdMutation,
} from '@redux/api'
import { useDeleteViewRestrictionsByTypeMutation } from '@redux/api/restriction.api'
import { deleteViewRow, setViewRows } from '@redux/reducers/changedViewRows.reducer'
import { openFormDialog, setSelectedDialog } from '@redux/reducers/dialogWindowManager.reducer'
import {
  addFormHistory,
  createHistoryByForm,
  createHistoryByView,
  deleteById,
} from '@redux/reducers/formHistory.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useAppSelector, useDebounceFn } from '@hooks'
import {
  getInitialPositionWindow,
  isObjectValueType,
  renderBooleanValueCell,
  renderEnumCell,
  renderIntegerAsTimeValueCell,
  renderJSValueCell,
  renderJSXValueCell,
  renderMappedCheckboxCell,
  renderObjectEmbeddedCell,
} from '@helpers'
import { getVerifyFormCode } from '@helpers'
import {
  BIND_TYPE,
  CLICK_EVENT_TYPE,
  CLICK_TYPE,
  DELETED_ROW_CLASSNAME,
  DROPDOWN_FILTER_TYPES,
  ENTITY,
  EVENT_CODE,
  EVENT_NAME_OBJECT_MESSAGE,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  FORM_TYPE,
  FOUND_SEARCH_VALUE_CLASSNAME,
  NAMES_FIELDS,
  OBJECT_FIELD_TYPE,
  PASSED_FORM_DATA_VARIANT,
  PREV_SELECTED_ROW_CLASSNAME,
  QuickRuleType,
  RULES_EXCEPTIONS,
  SEARCH_RULE_TYPE,
  SELECTED_ROW_CLASSNAME,
  SIZE_PAGE,
  UPDATES_ROW_CLASSNAME,
} from '@constants'
import {
  DIALOG_WINDOW_TYPE,
  EnumCellType,
  GETEnrichedFilteredEntityObjectDataParams,
  GETEnrichedViewObjectDataParams,
  GETObjectDataParamsEnriched,
  MessageBody,
  ObjectDataRecord,
  PassedFormData,
  POSTObjectDataFilter,
  RESTRICTION_TYPE,
  RestrictionDTO,
  ViewRow,
} from '@types'

import useInfiniteScroll, { ScrollingParams, SortType } from '../../../hooks/infiniteScroll'
import { binarySearchFirstOccurrence } from '../components/SearchAssistant/helpers'
import {
  compareModels,
  findPinnedColumnsDifferences,
  getColumnFieldNameForSort,
  getFilteredPinnedColumnsArray,
  getParsedFields,
  getSortByColumns,
  getSortParam,
  getTransformResponse,
  getUpdatedOptions,
  transformFixRestrictions,
} from '../helpers'
import { HandleSetOpenedContainersParams, useHandlersParams } from '../types'

import { useQuickSearch } from './useQuickSearch'
import { useScrollDialogContainer } from './useScrollDialogContainer'

export const useHandlers = ({
  entityCode,
  entityId,
  path,
  apiRef,
  entity,
  objectCode,
  actions,
  isNestedView,
  getLeaveForm,
  onResetEditForm,
  onSelectPickerOption,
  initialCommonParameters: { numberOfWindows: numberOfWindowsParameter, pagination: sizeParameter },
  initialColumnVisibilityModel,
  type,
  formObjectId,
  formObjectCode,
  formElementId,
  valueId,
  fieldId,
  onMultiSelectPickerOption,
  multipleSelectPickerOption,
  objectValueNameOption,
  onSetColumnVisibilityModel,
  columns,
  entityRestrictions,
  isLoadingRestrictions,
  handleUpdateRestrictions,
  handleOpenForm,
  search,
  isAssistiantSearch,
  dialogId,
  customPageSize,
  handleGetEntityRestrictionsData,
  viewColumns,
  onSetColumns,
  readonly,
  contextId,
}: useHandlersParams) => {
  const { links, currentLink, globalId: formGlobalId } = useContext(FormContext)
  const theme = useTheme()

  const [isCtrlHeld, setIsCtrlHeld] = useState(false)
  const [isShiftHeld, setIsShiftHeld] = useState(false)
  const [selectedRowIds, setSelectedRowsId] = useState<string[]>([])
  const [samePageFormsData, setSamePageFormsData] = useState<PassedFormData[]>([])
  const [currentStaticForm, setCurrentStaticForm] = useState(0)
  const [currentDynamicForm, setCurrentDynamicForm] = useState(0)
  const [isOpenShowColumnsSettings, setOpenShowColumnsSettings] = useState(false)
  const [totalElements, setTotalElements] = useState<number | null>(null)
  const [searchAssistantValue, setSearchAssistantValue] = useState<POSTObjectDataFilter>([])
  const [valueSearchFilter, setValueSearchFilter] = useState('')
  const [sortKeysForQuickSearch, setSortKeysForQuickSearch] = useState<string[]>([])
  const [searchRule, setSearchRule] = useState<QuickRuleType>(
    type === ENTITY.DROP_DOWN_ENTITY
      ? {
          name: SEARCH_RULE_TYPE.CONTAIN,
          exception: RULES_EXCEPTIONS.DEFAULT,
        }
      : {
          name: SEARCH_RULE_TYPE.EQUAL,
          exception: RULES_EXCEPTIONS.DEFAULT,
        }
  )
  const hasQuickSearch = entity?.hasQuickSearch

  const [fetchEnrichedRow] = useFetchObjectDataRecordEnrichedByIdMutation()

  const [deleteViewRestrictions, { isSuccess: isSuccessDeleteRestrictions }] =
    useDeleteViewRestrictionsByTypeMutation()

  const {
    changedViewRows: { objectsWithDirtyRecords },
    dialogWindowManager: { dialogWindows },
  } = useAppSelector(state => state)

  const [prevSelectedRowId, setPrevSelectedRowId] = useState<string | undefined>(undefined) // для подсвечивания row другим цветом при возвращении в список

  const dispatch = useAppDispatch()

  // TODO необходимо избавиться и перейти на DialogWindowManager
  const dynamicFormsData = useMemo(
    () => samePageFormsData.filter(data => data.variant === PASSED_FORM_DATA_VARIANT.DYNAMIC),
    [samePageFormsData]
  )

  const staticFormsData = useMemo(
    () => samePageFormsData.filter(data => data.variant === PASSED_FORM_DATA_VARIANT.STATIC),
    [samePageFormsData]
  )

  const useFetchEntityObjectDataEnrichedMutation =
    type === ENTITY.LIST_CONTROL || type === ENTITY.DROP_DOWN_ENTITY
      ? useFetchListControlOrDropdownObjectDataEnrichedMutation
      : useFetchObjectDataFilterByCodeEnrichedMutation

  const pageSizeForRequest = customPageSize * 2

  // TODO рефакторинг
  const viewParamsForRequest: GETEnrichedViewObjectDataParams & {
    skip?: boolean
  } = {
    contextId,
    code: entity?.objectCode,
    viewId: entity?.id,
    size:
      pageSizeForRequest > Number(sizeParameter)
        ? pageSizeForRequest.toString()
        : sizeParameter || SIZE_PAGE,
    body: searchAssistantValue,
    // для ассистента отдельный запрос в useConfiguredEntity в then у fetchEntity
    skip: !entity?.id || !contextId || isAssistiantSearch,
    ...(hasQuickSearch && {
      // TODO Похоже правильнее использовать стейт
      // sortKeysForQuickSearch: getSortKeysOfColumnsForQuickSearch(
      //   getInitialColumnVisibilityModel(viewColumns, true, SEARCH_RULE_TYPE.EQUAL)
      // ),
      sortKeysForQuickSearch,
      searchValue: valueSearchFilter ? valueSearchFilter : '',
      searchRule: searchRule ? searchRule.name : null,
    }),
    sort: getSortByColumns(viewColumns),
  }

  const idForDropDownWindow = formElementId || valueId || fieldId

  // TODO рефакторинг
  const listControlOfDropdownParamsForRequest: GETEnrichedFilteredEntityObjectDataParams & {
    skip?: boolean
  } = {
    contextId,
    elementId: formElementId,
    valueId,
    fieldId,
    formObjectCode,
    formObjectId,
    viewId: entityId || undefined,
    size:
      pageSizeForRequest > Number(sizeParameter)
        ? pageSizeForRequest.toString()
        : sizeParameter || SIZE_PAGE,
    dropDownEntityType: type === ENTITY.DROP_DOWN_ENTITY ? DROPDOWN_FILTER_TYPES.WINDOW : undefined,
    skip: !entity?.id || !formObjectCode || !idForDropDownWindow || !contextId,
    body: searchAssistantValue,
    ...(hasQuickSearch && {
      // TODO Похоже правильнее использовать стейт
      // sortKeysForQuickSearch: getSortKeysOfColumnsForQuickSearch(
      //   getInitialColumnVisibilityModel(viewColumns, true, SEARCH_RULE_TYPE.EQUAL)
      // ),
      sortKeysForQuickSearch,
      searchValue: valueSearchFilter ? valueSearchFilter : '',
      searchRule: searchRule ? searchRule.name : null,
    }),
    sort: getSortByColumns(viewColumns),
  }

  const entityParamsForRequest =
    type === ENTITY.LIST_CONTROL || type === ENTITY.DROP_DOWN_ENTITY
      ? listControlOfDropdownParamsForRequest
      : viewParamsForRequest

  const {
    combinedData: objectData,
    isLoading: isLoadingObjectData,
    totalPages,
    totalCountElements,
    refresh,
    onChangeSort,
    readMore,
    currentSort,
    setCombinedDataForce: setObjectData,
    currentPage,
    scrollingParams,
  } = useInfiniteScroll<
    GETObjectDataParamsEnriched & { body?: POSTObjectDataFilter },
    ObjectDataRecord
  >(useFetchEntityObjectDataEnrichedMutation, entityParamsForRequest, getTransformResponse)

  const refreshObjectData = (params?: Omit<ScrollingParams, 'page'>, skip?: boolean) => {
    if (apiRef.current && Object.keys(apiRef.current).length) {
      apiRef.current.scrollToIndexes({ rowIndex: 0 })
    }

    return refresh(params, skip)
  }

  const refreshChangeSortData = (sort: SortType[]) => {
    if (apiRef.current && Object.keys(apiRef.current).length) {
      apiRef.current.scrollToIndexes({ rowIndex: 0 })
    }

    return onChangeSort(sort, {
      sortKeysForQuickSearch,
      searchValue: valueSearchFilter,
      searchRule: searchRule.name,
    })
  }

  const {
    handlers: {
      handleSetColumnNameCheckBox,
      handleSetGlobalFilter,
      handleSetSearchRule,
      handleSetSortKeysColumnCheckBox,
      handleSetSortKeysForQuickSearch,
      handleSetBooleanMode,
    },
    state: { columnNameCheckBox, sortKeysColumnCheckBox, isBooleanSearchMode },
  } = useQuickSearch({
    searchRule,
    sortKeysForQuickSearch,
    valueSearchFilter,
    viewColumns,
    dialogId,
    setSearchRule,
    setSortKeysForQuickSearch,
    setValueSearchFilter,
    refreshObjectData,
    entityType: type,
  })

  useEffect(() => {
    if (type === ENTITY.DROP_DOWN_ENTITY && multipleSelectPickerOption) {
      setSelectedRowsId(multipleSelectPickerOption.map(({ id }) => id.toString()))
    }
  }, [multipleSelectPickerOption, type])

  const setHistoryFormInForm = ({
    formCode,
    newId,
    objectId,
    event,
  }: {
    formCode: string
    newId: string
    objectId: string | null
    event: FORM_TYPE
  }) => {
    dispatch(
      addFormHistory({
        id: formGlobalId,
        form: {
          formCode,
          objectCode,
          objectId,
          event,
          title: formCode,
          id: newId,
          isWindow: false,
        },
      })
    )
  }

  const setHistoryFormByView = ({
    isWindow = false,
    formCode,
    newId,
    objectId,
    event,
  }: {
    isWindow?: boolean
    formCode: string
    newId: string
    objectId: string | null
    event: FORM_TYPE
  }) => {
    if (dialogId) {
      dispatch(
        createHistoryByView({
          id: newId,
          form: {
            formCode,
            objectCode,
            objectId,
            event,
            isWindow,
            title: formCode,
            id: newId,
          },
          view: {
            objectCode,
            title: entity?.title || '',
            viewPath: path,
            id: dialogId,
            isRootLink: true,
          },
        })
      )
    }
  }

  const setHistoryFormByForm = ({
    isWindow = false,
    formCode,
    newId,
    objectId,
    event,
  }: {
    isWindow?: boolean
    formCode: string
    newId: string
    objectId: string | null
    event: FORM_TYPE
  }) => {
    dispatch(
      createHistoryByForm({
        id: newId,
        childForm: {
          formCode,
          objectCode,
          objectId,
          event,
          title: formCode,
          id: newId,
          isWindow,
        },
        parentForm: { ...currentLink, isRootLink: true },
      })
    )
  }

  // TODO Раньше здесь был просто setState, логика с поиском была на месте вызова обработчика
  const handleSetObjectData = (newObjectData: ObjectDataRecord) => {
    setObjectData(prev => {
      const newData = [...prev]
      const currentRowIndex = newData.findIndex(row => row._id === newObjectData._id)
      newData[currentRowIndex] = { ...newData[currentRowIndex], ...newObjectData }

      return newData
    })
  }

  const handleDeleteViewRestrictionsByType = (restrictionType: RESTRICTION_TYPE) => {
    return deleteViewRestrictions({
      viewId: entityId as string,
      restrictionType,
    })
  }

  const handleOpenShowColumnsSettings = (value: boolean) => setOpenShowColumnsSettings(value)

  const handleSetTotalElements = (value: number) => setTotalElements(value)

  const handleChangeModelSort = (value: GridSortModel | null) => {
    if (!entity || value === null) {
      return
    }

    const sort: SortType[] = value ? getSortParam(value, apiRef) : []

    const newRestrictions: RestrictionDTO[] = sort.map((currentSorting, currentSortingOrder) => {
      return {
        parentFieldName: null,
        fieldName: currentSorting.field,
        fieldCode: currentSorting.field,
        viewId: entityId as string,
        restrictionType: RESTRICTION_TYPE.SORT,
        value: `${currentSorting.sortKey}//${currentSorting.sort}//${currentSortingOrder}`,
      }
    })

    // Очистить все ограничения
    handleDeleteViewRestrictionsByType(RESTRICTION_TYPE.SORT).then(() => {
      // Засетить новые значения из sort
      handleUpdateRestrictions(newRestrictions).then(() => {
        refreshChangeSortData(sort)
      })
    })
  }

  const handleObjectDataPageChange: MUIDataGridProps['onRowsScrollEnd'] = () => {
    readMore()
  }

  const renderDefaultCell = (
    code: string,
    params: GridRenderCellParams<unknown, ObjectDataRecord, unknown>
  ) => {
    return <>{params.row[code] ?? ''}</>
  }

  const cellRenderer = (
    column: ViewRow,
    params: GridRenderCellParams<unknown, ObjectDataRecord, unknown>
  ): ReactNode => {
    const asCheckbox =
      column.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
      column.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN

    if (asCheckbox && column.valueFormat) {
      return renderMappedCheckboxCell(column.code, column.valueFormat, params)
    }

    if (column.bindType === BIND_TYPE.JSX && column.value) {
      return renderJSXValueCell(column.value, params.row)
    }
    if (column.bindType === BIND_TYPE.JS && column.value) {
      return renderJSValueCell(column.value, params.row)
    }
    if (isObjectValueType(column.valueType) && column.objectValue) {
      return renderObjectEmbeddedCell(
        column.code,
        // column.objectValue,
        params.row
      )
    }
    if (column.valueType === FIELD_VALUE_TYPE.BOOLEAN) {
      return renderBooleanValueCell(params.row[column.code])
    }
    if (
      column.valueType === FIELD_VALUE_TYPE.INTEGER &&
      column.valueFormat === FIELD_VALUE_FORMAT.TIME
    ) {
      return renderIntegerAsTimeValueCell(column.code, params)
    }

    if (
      column.fieldType === OBJECT_FIELD_TYPE.ENUM &&
      column.enumDescription &&
      column.objectCode
    ) {
      const dataEnumCell: EnumCellType = {
        isDialogWindow: Boolean(dialogId),
        name: getColumnFieldNameForSort(column),
        objectCode: column.objectCode,
        value: params.row[column.code],
        valueType: column.valueType,
      }

      return renderEnumCell(dataEnumCell)
    }

    return renderDefaultCell(column.code, params)
  }

  const handleClickSearchFilter = (body: POSTObjectDataFilter) => {
    refreshObjectData({
      body,
      ...(hasQuickSearch && {
        sortKeysForQuickSearch,
        searchRule: searchRule.name,
        searchValue: valueSearchFilter,
      }),
    })
    setSearchAssistantValue(body)
  }

  const handleSetIsCtrlHeld = (value: boolean) => setIsCtrlHeld(value)

  const handleSetIsShiftHeld = (value: boolean) => setIsShiftHeld(value)

  const handleSetSelectedRowIds = (newValue: string) => {
    // Ничего не нужно, т.к. обработка идёт в useEffect (см. выше)
    if (multipleSelectPickerOption) {
      return
    }

    setSelectedRowsId([newValue])
    apiRef.current.scrollToIndexes({ rowIndex: apiRef.current.getRowIndex(newValue) })
  }

  // Для сброса динамических и статических окон
  const resetDynamicAndStaticForms = () => {
    setSamePageFormsData([])
    setSelectedRowsId([])
    setCurrentStaticForm(0)
    setCurrentDynamicForm(0)
  }

  const handleUpdateSamePageFormData = (
    oldObjectId: string,
    newFormCode: string,
    objectCode: string,
    objectId: string
  ) => {
    setSamePageFormsData(prev =>
      prev.map(data => {
        if (data.objectId !== oldObjectId) {
          return data
        }

        return { ...data, objectCode, formCode: newFormCode, objectId }
      })
    )
  }

  const handleAddSamePageForm = (value: Omit<PassedFormData, 'id'>, replace?: boolean) => {
    const existentFormsData = samePageFormsData.find(formData => formData.rowId === value.rowId)

    if (!existentFormsData) {
      if (
        value.variant === PASSED_FORM_DATA_VARIANT.DYNAMIC &&
        dynamicFormsData.length === Number(numberOfWindowsParameter)
      ) {
        dispatch(
          showMessage({
            type: 'info',
            text: t('error.dynamicFormsExceeded', { count: Number(numberOfWindowsParameter) }),
          })
        )

        return
      }

      if (!samePageFormsData.length) {
        value.rowId && handleSetSelectedRowIds(value.rowId)
      }

      const lastSamePageElement = samePageFormsData[samePageFormsData.length - 1]
      const newFormDataId =
        lastSamePageElement && lastSamePageElement.id
          ? lastSamePageElement.id + 1
          : samePageFormsData.length + 1

      setHistoryFormByView({
        formCode: value.formCode,
        newId: value.globalId,
        objectId: value.objectId,
        event: value.event,
      })

      setSamePageFormsData(prev => [...prev, { ...value, id: newFormDataId }])

      if (value.variant === PASSED_FORM_DATA_VARIANT.DYNAMIC) {
        setCurrentDynamicForm(dynamicFormsData.length)
      }

      if (value.variant === PASSED_FORM_DATA_VARIANT.STATIC) {
        const currentOpenedSamePageFormIndex = samePageFormsData.findIndex(
          data => data.rowId === selectedRowIds[0]
        )

        if (!samePageFormsData[currentOpenedSamePageFormIndex]) {
          setCurrentStaticForm(staticFormsData.length)

          return
        }

        // В этом "if" прописана логика "подмены" данных в уже открытой статической форме (задача WF-851)
        if (replace === true) {
          const oldSamePageFormsData = [...samePageFormsData]

          const globalIdForNewForm = uuid()

          const newValue = {
            ...value,
            id: samePageFormsData[currentOpenedSamePageFormIndex].id,
            openedContainers:
              samePageFormsData[currentOpenedSamePageFormIndex].formCode === value.formCode
                ? samePageFormsData[currentOpenedSamePageFormIndex].openedContainers
                : [],
            globalId: globalIdForNewForm,
          }

          dispatch(deleteById({ id: samePageFormsData[currentOpenedSamePageFormIndex].globalId }))

          setHistoryFormByView({
            formCode: newValue.formCode,
            newId: newValue.globalId,
            objectId: newValue.objectId,
            event: newValue.event,
          })

          oldSamePageFormsData.splice(currentOpenedSamePageFormIndex, 1, newValue)

          setSamePageFormsData(oldSamePageFormsData)

          return
        }

        setCurrentStaticForm(staticFormsData.length)
      }

      return
    }

    if (existentFormsData.variant === PASSED_FORM_DATA_VARIANT.STATIC) {
      const currentStaticFormDataIndex = staticFormsData.findIndex(
        data => data.rowId === existentFormsData.rowId
      )

      currentStaticFormDataIndex !== -1 && setCurrentStaticForm(currentStaticFormDataIndex)
    }

    handleSetSelectedRowIds(existentFormsData.rowId as string)
  }

  const handleStaticFormChange = (newValue: number) => {
    setCurrentStaticForm(newValue)
  }

  const handleDynamicFormChange = (newValue: number) => {
    setCurrentDynamicForm(newValue)
  }

  const handleRemoveSamePageForm = (formDataId?: number, isDirty?: boolean) => {
    if (!formDataId) {
      return
    }

    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    const staticForm = staticFormsData.find(data => data.id === formDataId)
    const dynamicForm = dynamicFormsData.find(data => data.id === formDataId)

    if (staticForm) {
      if (currentStaticForm + 1 === staticFormsData.length) {
        setCurrentStaticForm(prev => prev - 1)
      }
    }

    if (dynamicForm) {
      if (currentDynamicForm + 1 === dynamicFormsData.length) {
        setCurrentDynamicForm(prev => prev - 1)
      }
    }

    if (samePageFormsData.length === 1) {
      setSelectedRowsId([])
      setCurrentDynamicForm(0)
      setCurrentStaticForm(0)
      setIsCtrlHeld(false)
    }

    setSamePageFormsData(prev => prev.filter(formData => formData.id !== formDataId))
  }

  const handleCloseCurrentFormTab = () => {
    if (samePageFormsData.length === 1) {
      setSelectedRowsId([])
      setCurrentDynamicForm(0)
      setCurrentStaticForm(0)
    }
  }

  const handleClearSamePageForms = () => {
    setSamePageFormsData([])
    setSelectedRowsId([])
  }

  // Метод для отслеживания UI состояния статической формы (открытые контейнеры и табы)
  // для дальнейшего использования этих данных при открытии новой одинаковой формы или
  // при повторном открытии при нажатии на таб (задача WF-851)
  const handleSetOpenedContainersInStaticForms = ({
    formDataId,
    containerExpanded,
    containerIndex,
    openedTabIndex,
  }: HandleSetOpenedContainersParams) => {
    if (staticFormsData.length === 0) {
      return
    }

    const currentFormData = samePageFormsData.find(formData => formData.id === formDataId)

    if (!currentFormData) {
      return
    }

    setSamePageFormsData(prev =>
      prev.map(samePageFormsData => {
        if (samePageFormsData.id !== formDataId) {
          return samePageFormsData
        }

        const currentContainerIndex = samePageFormsData.openedContainers.findIndex(
          openedContainer => {
            return openedContainer.containerIndex === containerIndex
          }
        )

        const currentContainer = samePageFormsData.openedContainers.find(
          container => containerIndex === container.containerIndex
        )

        if (currentContainerIndex !== -1 && currentContainer !== undefined) {
          if (!containerExpanded) {
            samePageFormsData.openedContainers.splice(currentContainerIndex, 1)
          }

          if (currentContainer.openedTabIndex !== openedTabIndex) {
            samePageFormsData.openedContainers = samePageFormsData.openedContainers.map(
              (containerData, containerIndex) => {
                if (containerIndex !== currentContainerIndex) {
                  return containerData
                }

                return { openedTabIndex, containerIndex: containerData.containerIndex }
              }
            )
          }

          return samePageFormsData
        }

        samePageFormsData.openedContainers?.push({
          openedTabIndex,
          containerIndex,
        })

        return samePageFormsData
      })
    )
  }

  const handleEdit = (
    params: GridCellParams<ObjectDataRecord>,
    event: MouseEvent<HTMLButtonElement>
  ) => {
    const row = params.row
    const formCode = getVerifyFormCode({ eventCode: EVENT_CODE.ON_ROW_EDIT, row, actions })
    if (formCode && entityCode) {
      if (links) {
        if (getLeaveForm?.()) {
          return
        }
        onResetEditForm?.()

        const id = uuid()

        setHistoryFormInForm({ formCode, newId: id, objectId: row._id, event: FORM_TYPE.EDIT })

        return
      }

      resetDynamicAndStaticForms()

      const globalId = uuid()

      setHistoryFormByView({ formCode, newId: globalId, objectId: row._id, event: FORM_TYPE.EDIT })

      handleOpenForm?.(
        {
          event: FORM_TYPE.EDIT,
          formCode,
          objectCode,
          objectId: row._id,
          viewCode: entityCode,
          path,
          search,
          id: globalId,
          entityType: type,
          viewId: entityId || undefined,
        },
        event,
        CLICK_EVENT_TYPE.EDIT_CLICK
      )
    }
  }

  const handleAdditionalButtonClick = (
    params: GridCellParams<ObjectDataRecord>,
    event: MouseEvent<HTMLButtonElement>
  ) => {
    const initialPosition = getInitialPositionWindow(event)
    const viewId = entityId
    const viewCode = entityCode

    const row = params.row
    const formCode = getVerifyFormCode({
      eventCode: EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK,
      row,
      actions,
    })

    // if (checkMaxOpenedDialogs()) return

    if (viewId && formCode) {
      const id = uuid()
      dispatch(
        openFormDialog({
          id,
          parentDialogId: null,
          type: DIALOG_WINDOW_TYPE.FORM,
          // title: option?.elementTitle,
          title: null,
          meta: {
            preFill: {
              objectCode,
              // TODO Сделать нормальный ид через флаги
              objectId: row._id,
            },
            objectCode: '',
            // formCode: option.elementCode.toString(),
            formCode,
            viewCode: '',
            // path: option.id.toString(),
            path,
            event: FORM_TYPE.CREATE,
            globalId: id,
            entityType: ENTITY.VIEW,
            // viewId: option.elementId,
            viewId,
            isIndependentForm: true,
          },
          initialPosition,
        })
      )

      dispatch(
        addFormHistory({
          id,
          form: {
            // formCode: option.elementCode.toString(),
            formCode,
            objectCode: '',
            event: FORM_TYPE.CREATE,
            objectId: null,
            // title: option?.elementTitle || '',
            title: '',
            id,
            isWindow: true,
          },
        })
      )
    }
    // TODO Открытие формы
  }

  const handleRowClick = (params: GridRowParams<ObjectDataRecord>, event: MuiEvent<MouseEvent>) => {
    if (readonly) {
      return
    }
    const row = params.row
    const formCode = getVerifyFormCode({ eventCode: EVENT_CODE.ON_ROW_CLICK, row, actions })
    const globalId = uuid()

    handleSetSelectedRowIds(row._id)

    if (type === ENTITY.DROP_DOWN_ENTITY) {
      if (multipleSelectPickerOption && objectValueNameOption) {
        const newOptions = getUpdatedOptions(
          multipleSelectPickerOption,
          {
            id: params.row._id,
            label: params.row[objectValueNameOption],
          },
          ({ id }) => id === params.row._id
        )

        onMultiSelectPickerOption?.(newOptions)

        return
      }

      onSelectPickerOption?.(params.row._id)

      return
    }

    if (formCode && entityCode) {
      if (event?.type === CLICK_TYPE.SINGLE) {
        // TODO на рефакторинге заменить e.ctrlKey && e.shiftKey на event.key из констант hotkey.ts
        if (event.ctrlKey && event.shiftKey) {
          return
        }

        if (event.shiftKey) {
          const openedDialogByRowId = dialogWindows.find(
            dialog => dialog.meta?.objectId === row._id && formCode === dialog.meta?.formCode
          )

          if (openedDialogByRowId) {
            dispatch(setSelectedDialog({ id: openedDialogByRowId.id }))

            return
          }

          // При открытии из вложенной вьюхи в форму
          if (links) {
            setHistoryFormByForm({
              formCode,
              newId: globalId,
              objectId: row._id,
              event: FORM_TYPE.VIEW,
              isWindow: true,
            })

            handleOpenForm?.(
              {
                event: FORM_TYPE.VIEW,
                formCode,
                objectCode,
                objectId: row._id,
                viewCode: entityCode,
                path,
                search,
                id: globalId,
                entityType: type,
                viewId: entityId || undefined,
              },
              event,
              CLICK_EVENT_TYPE.ROW_CLICK
            )

            return
          }

          handleOpenForm?.(
            {
              event: FORM_TYPE.VIEW,
              formCode,
              objectCode,
              objectId: row._id,
              viewCode: entityCode,
              path,
              search,
              id: globalId,
              entityType: type,
              viewId: entityId || undefined,
            },
            event,
            CLICK_EVENT_TYPE.ROW_CLICK
          )

          setHistoryFormByView({
            formCode,
            newId: globalId,
            objectId: row._id,
            event: FORM_TYPE.VIEW,
            isWindow: true,
          })

          return
        }

        if (event.ctrlKey || event.shiftKey) {
          // Нельзя открыть окно с формой, если вью находится в другой форме
          if (isNestedView) {
            return
          }

          handleAddSamePageForm({
            rowId: row.id,
            formCode,
            objectCode,
            objectId: row._id,
            viewCode: entityCode,
            viewId: entity?.id as string,
            path,
            event: FORM_TYPE.VIEW,
            variant: event.ctrlKey
              ? PASSED_FORM_DATA_VARIANT.STATIC
              : PASSED_FORM_DATA_VARIANT.DYNAMIC,
            globalId,
            openedContainers: [],
          })

          if (event.ctrlKey) {
            isShiftHeld && handleSetIsShiftHeld(false)
            handleSetIsCtrlHeld(true)
          }

          if (event.shiftKey) {
            isCtrlHeld && handleSetIsCtrlHeld(false)
            handleSetIsShiftHeld(true)
          }

          return
        }

        if (isCtrlHeld) {
          handleAddSamePageForm(
            {
              rowId: row.id,
              formCode,
              objectCode,
              objectId: row._id,
              viewCode: entityCode,
              viewId: entity?.id as string,
              path,
              event: FORM_TYPE.VIEW,
              variant: PASSED_FORM_DATA_VARIANT.STATIC,
              globalId,
              openedContainers: [],
            },
            true
          )
        }
      } else if (event?.type === CLICK_TYPE.DOUBLE) {
        handleClearSamePageForms()
        setPrevSelectedRowId(row.id)
        // для вьюхи вложенной в форму
        if (links) {
          if (getLeaveForm?.()) {
            return
          }

          onResetEditForm?.()

          setHistoryFormInForm({
            formCode,
            newId: globalId,
            objectId: row._id,
            event: FORM_TYPE.VIEW,
          })

          return
        }

        resetDynamicAndStaticForms()

        setHistoryFormByView({
          formCode,
          newId: globalId,
          objectId: row._id,
          event: FORM_TYPE.VIEW,
        })

        handleOpenForm?.(
          {
            event: FORM_TYPE.VIEW,
            formCode,
            objectCode,
            objectId: row._id,
            viewCode: entityCode,
            path,
            search,
            id: globalId,
            entityType: type,
            viewId: entityId || undefined,
          },
          event,
          CLICK_EVENT_TYPE.ROW_CLICK
        )
      }
    }
  }

  const handleCellClick = (cell: GridCellParams, event: MuiEvent<MouseEvent>) => {
    if (cell.colDef.type !== 'linkToForm') {
      return
    }

    event.stopPropagation()

    const row = cell.row
    const formCode = getVerifyFormCode({ eventCode: EVENT_CODE.ON_ROW_CLICK, row, actions })
    const globalId = uuid()

    setPrevSelectedRowId(row.id)
    handleClearSamePageForms()
    // для вьюхи вложенной в форму

    if (formCode && entityCode) {
      if (event.shiftKey) {
        const openedDialogByRowId = dialogWindows.find(
          dialog => dialog.meta?.objectId === row._id && formCode === dialog.meta?.formCode
        )

        if (openedDialogByRowId) {
          dispatch(setSelectedDialog({ id: openedDialogByRowId.id }))

          return
        }

        // Открытие через shift в если вьюха вложеная в форму
        if (links) {
          if (getLeaveForm?.()) {
            return
          }

          onResetEditForm?.()

          setHistoryFormByForm({
            formCode,
            newId: globalId,
            objectId: row._id,
            event: FORM_TYPE.VIEW,
          })

          handleOpenForm?.(
            {
              event: FORM_TYPE.VIEW,
              formCode,
              objectCode,
              objectId: row._id,
              viewCode: entityCode,
              path,
              search,
              id: globalId,
              entityType: type,
              viewId: entityId || undefined,
            },
            event,
            CLICK_EVENT_TYPE.ROW_CLICK
          )

          return
        }

        setHistoryFormByView({
          formCode,
          newId: globalId,
          objectId: row._id,
          event: FORM_TYPE.VIEW,
          isWindow: true,
        })

        handleOpenForm?.(
          {
            event: FORM_TYPE.VIEW,
            formCode,
            objectCode,
            objectId: row._id,
            viewCode: entityCode,
            path,
            search,
            id: globalId,
            entityType: type,
            viewId: entityId || undefined,
          },
          event,
          CLICK_EVENT_TYPE.ROW_CLICK
        )

        return
      }

      resetDynamicAndStaticForms()

      // открытие если вьюха вложена в форму
      if (links) {
        if (getLeaveForm?.()) {
          return
        }

        onResetEditForm?.()

        setHistoryFormInForm({
          formCode,
          newId: globalId,
          objectId: row._id,
          event: FORM_TYPE.VIEW,
        })

        return
      }

      // дефолтное открытие при клике на иконку
      setHistoryFormByView({ formCode, newId: globalId, objectId: row._id, event: FORM_TYPE.VIEW })

      handleOpenForm?.(
        {
          event: FORM_TYPE.VIEW,
          formCode,
          objectCode,
          objectId: row._id,
          viewCode: entityCode,
          path,
          search,
          id: globalId,
          entityType: type,
          viewId: entityId || undefined,
        },
        event,
        CLICK_EVENT_TYPE.CELL_CLICK
      )
    }
  }

  const handleCreate = (event: MouseEvent<HTMLButtonElement>) => {
    const formCode = getVerifyFormCode({ eventCode: EVENT_CODE.ON_ROW_CREATE, actions })
    const globalId = uuid()

    event.stopPropagation()

    if (formCode && entityCode) {
      if (links) {
        if (getLeaveForm?.()) {
          return
        }
        onResetEditForm?.()

        setHistoryFormByForm({
          formCode,
          newId: globalId,
          objectId: null,
          event: FORM_TYPE.CREATE,
          isWindow: true,
        })
        // Форма создания из list control открывается в новом блокирующем окне
        handleOpenForm?.(
          {
            event: FORM_TYPE.CREATE,
            formCode,
            objectCode,
            viewCode: entityCode,
            path,
            search,
            id: globalId,
            entityType: type,
            viewId: entityId || undefined,
          },
          event,
          CLICK_EVENT_TYPE.CREATE_CLICK
        )

        return
      }
      resetDynamicAndStaticForms()

      setHistoryFormByView({ formCode, newId: globalId, objectId: null, event: FORM_TYPE.CREATE })

      handleOpenForm?.(
        {
          event: FORM_TYPE.CREATE,
          formCode,
          objectCode,
          viewCode: entityCode,
          path,
          search,
          id: globalId,
          entityType: type,
          viewId: entityId || undefined,
        },
        event,
        CLICK_EVENT_TYPE.CREATE_CLICK
      )
    }
  }

  const handleGetRowClassName = (params: GridRowClassNameParams) => {
    let defaultClassName = ''
    const objectWithFields = objectsWithDirtyRecords[objectCode]
    const row = objectWithFields ? objectWithFields[params.row._id] : undefined

    if (theme?.palette?.mode === 'light') {
      defaultClassName = params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
    }

    if (row && row === EVENT_NAME_OBJECT_MESSAGE.CREATE) {
      return UPDATES_ROW_CLASSNAME
    }

    if (row && row === EVENT_NAME_OBJECT_MESSAGE.DELETE) {
      return DELETED_ROW_CLASSNAME
    }

    if (selectedRowIds.includes(params.row._id)) {
      return SELECTED_ROW_CLASSNAME
    }

    if (prevSelectedRowId === params.row.id) {
      return PREV_SELECTED_ROW_CLASSNAME
    }

    return defaultClassName
  }

  // в зависимости от правила и если чекнут столбец
  const changeBackgroundCell = (columnValue: string, sortKey: string): boolean => {
    const checkedColumn = sortKeysForQuickSearch.includes(sortKey)

    if (checkedColumn) {
      switch (searchRule.name) {
        case SEARCH_RULE_TYPE.EQUAL:
          return Boolean(valueSearchFilter) && valueSearchFilter === columnValue

        case SEARCH_RULE_TYPE.NOT_EQUAL:
          return Boolean(valueSearchFilter) && valueSearchFilter !== columnValue

        case SEARCH_RULE_TYPE.CONTAIN:
          return Boolean(valueSearchFilter) && columnValue.includes(valueSearchFilter)

        case SEARCH_RULE_TYPE.NOT_CONTAIN:
          return Boolean(valueSearchFilter) && !columnValue.includes(valueSearchFilter)

        case SEARCH_RULE_TYPE.EMPTY:
          return !columnValue

        case SEARCH_RULE_TYPE.NOT_EMPTY:
          return Boolean(columnValue)

        case SEARCH_RULE_TYPE.STARTS_WITH:
          return Boolean(valueSearchFilter) && columnValue.startsWith(valueSearchFilter)

        case SEARCH_RULE_TYPE.ENDS_WITH:
          return Boolean(valueSearchFilter) && columnValue.endsWith(valueSearchFilter)

        case SEARCH_RULE_TYPE.LESS:
          return Boolean(valueSearchFilter) && Number(columnValue) < Number(valueSearchFilter)

        case SEARCH_RULE_TYPE.MORE:
          return Boolean(valueSearchFilter) && Number(columnValue) > Number(valueSearchFilter)

        case SEARCH_RULE_TYPE.LESS_OR_EQUAL:
          return Boolean(valueSearchFilter) && Number(columnValue) <= Number(columnValue)

        case SEARCH_RULE_TYPE.MORE_OR_EQUAL:
          return Boolean(valueSearchFilter) && Number(columnValue) >= Number(valueSearchFilter)

        default:
          return false
      }
    }

    return false
  }

  const handleGetCellClassName = (params: GridCellParams<ObjectDataRecord, ObjectDataRecord>) => {
    const columnValue = params.colDef.field ?? params.colDef.columnValue
    const sortKey = params.colDef.sortKey
    const cellValue = columnValue && `${params.row[columnValue]}`
    const objectValue = params.colDef?.columnObjectValue

    const objectWithFields = objectsWithDirtyRecords[objectCode]
    const dirtyFields = objectWithFields ? objectWithFields[params.row._id] : undefined

    const valueCell =
      cellValue && objectValue
        ? renderObjectEmbeddedCell(
            columnValue,
            // objectValue,
            params.row
          )
        : cellValue

    if (sortKey && cellValue && changeBackgroundCell(`${valueCell}`, sortKey)) {
      return FOUND_SEARCH_VALUE_CLASSNAME
    }

    if (dirtyFields && Array.isArray(dirtyFields) && columnValue) {
      // совпадение в измененных полях
      const hasDirtyField = dirtyFields.includes(columnValue)
      if (hasDirtyField) {
        return UPDATES_ROW_CLASSNAME
      }
    }

    return ''
  }

  const handleObjectSubscribe = (msg: IMessage) => {
    const body: MessageBody = JSON.parse(msg.body)
    const { objectCode, objectId: rowId, changedFields, eventName } = body

    if (!entity?.id) {
      return
    }

    const rowSearchIndex = objectData.findIndex(row => row._id === rowId)
    const objectIsAlreadyLoaded = rowSearchIndex !== -1
    const changedFieldsMap = changedFields ? new Map(Object.entries(changedFields)) : null
    // TODO Рефакторинг, жоский
    const fieldDependsOfSorting =
      currentSort && changedFieldsMap
        ? currentSort?.some(({ field, sortKey }) =>
            sortKey ? changedFieldsMap.has(sortKey) : changedFieldsMap.has(field)
          )
        : false
    const fieldIncludeRefs = changedFields
      ? Object.keys(changedFields).some(field => field.includes(NAMES_FIELDS.REF))
      : false

    // Если записи в таблице нет и измененные поля не зависят от сортировки
    if (
      !objectIsAlreadyLoaded &&
      !fieldDependsOfSorting &&
      eventName === EVENT_NAME_OBJECT_MESSAGE.UPDATE
    ) {
      return
    }

    // Если существует запись в таблице, измененные поля не зависят от сортировки и не имеют реф. полей
    if (objectIsAlreadyLoaded && !fieldDependsOfSorting && !fieldIncludeRefs && changedFields) {
      dispatch(
        setViewRows({
          changedFields: Object.keys(changedFields),
          recordId: rowId,
          objectCode,
          eventName,
        })
      )
      const copyObjectData = structuredClone(objectData)

      copyObjectData.splice(rowSearchIndex, 1, {
        ...copyObjectData[rowSearchIndex],
        ...changedFields,
      })

      setObjectData(copyObjectData)

      setTimeout(() => {
        dispatch(deleteViewRow({ objectCode, recordId: rowId }))
      }, 3000)

      return
    }

    switch (eventName) {
      case EVENT_NAME_OBJECT_MESSAGE.UPDATE:
        // Если измененные поля зависят от сортировки
        if (changedFields && currentSort) {
          const copyObjectData = structuredClone(objectData)
          objectIsAlreadyLoaded && copyObjectData.splice(rowSearchIndex, 1)

          const position = binarySearchFirstOccurrence(copyObjectData, changedFields, currentSort)
          const isPositionExist = position !== -1 && position <= objectData.length

          if (isPositionExist) {
            // Если обновленной записи нет в таблице, то загружаем
            if (!objectIsAlreadyLoaded) {
              fetchEnrichedRow({ objectCode, ids: [rowId], viewId: entity.id })
                .unwrap()
                .then(([newRow]) => {
                  dispatch(
                    setViewRows({
                      changedFields: Object.keys(changedFields),
                      recordId: rowId,
                      objectCode,
                      eventName,
                    })
                  )

                  copyObjectData.splice(position, 0, { id: uuid(), ...newRow })

                  setObjectData(copyObjectData)
                  setTimeout(() => {
                    dispatch(deleteViewRow({ objectCode, recordId: rowId }))
                  }, 3000)
                })

              return
            }

            const copyRow = structuredClone(objectData[rowSearchIndex])

            // если запись есть в таблице, не имеют реф. полей - запрос можно не делать
            if (!fieldIncludeRefs) {
              dispatch(
                setViewRows({
                  changedFields: Object.keys(changedFields),
                  recordId: rowId,
                  objectCode,
                  eventName,
                })
              )

              copyObjectData.splice(position, 0, { ...copyRow, ...changedFields })

              setObjectData(copyObjectData)
              setTimeout(() => {
                dispatch(deleteViewRow({ objectCode, recordId: rowId }))
              }, 3000)

              return
              // если запись есть в таблице, имеют реф. поля
            } else {
              fetchEnrichedRow({ objectCode, ids: [rowId], viewId: entity.id })
                .unwrap()
                .then(([newRow]) => {
                  dispatch(
                    setViewRows({
                      changedFields: Object.keys(changedFields),
                      recordId: rowId,
                      objectCode,
                      eventName,
                    })
                  )

                  copyObjectData.splice(rowSearchIndex, 1)
                  copyObjectData.splice(position, 0, { id: copyRow.id, ...newRow })

                  setObjectData(copyObjectData)
                  setTimeout(() => {
                    dispatch(deleteViewRow({ objectCode, recordId: rowId }))
                  }, 3000)
                })
            }
          }
        }

        return
      case EVENT_NAME_OBJECT_MESSAGE.CREATE:
        fetchEnrichedRow({
          objectCode,
          ids: [rowId],
          viewId: entity.id,
          body: searchAssistantValue,
          ...(hasQuickSearch
            ? {
                searchValue: entityParamsForRequest.searchValue as string,
                searchRule: entityParamsForRequest.searchRule as string,
                sortKeysForQuickSearch: entityParamsForRequest.sortKeysForQuickSearch as string[],
              }
            : {}),
        })
          .unwrap()
          .then(([newRow]) => {
            if (!newRow) {
              return
            }

            if (currentSort) {
              // Если список данных пустой, то позиция будет -1 и объект не добавится
              const position = binarySearchFirstOccurrence(objectData, newRow, currentSort)
              // Если данных нет, то binarySearchFirstOccurrence возвращает -1 индекс для созданной записи
              // TODO поэтому нужно пересмотреть алгоритм, так как в пустой таблице не появляются созданные записи
              const isEmptyObjectData = objectData.length === 0
              const isPositionExist = position !== -1 && position <= objectData.length

              if (isPositionExist || isEmptyObjectData) {
                const copyObjectData = structuredClone(objectData)

                copyObjectData.splice(position, 0, { id: uuid(), ...newRow })

                setObjectData(copyObjectData)
                setTotalElements(prevState => (prevState ?? 0) + 1)
                dispatch(
                  setViewRows({
                    changedFields: Object.keys(newRow),
                    recordId: rowId,
                    objectCode,
                    eventName,
                  })
                )
                setTimeout(() => {
                  dispatch(deleteViewRow({ objectCode, recordId: rowId }))
                }, 3000)
              }
            }
          })

        return
      case EVENT_NAME_OBJECT_MESSAGE.DELETE:
        if (objectIsAlreadyLoaded) {
          dispatch(
            setViewRows({
              changedFields: [],
              recordId: rowId,
              objectCode,
              eventName,
            })
          )
          setTotalElements(prevState => (prevState ?? 0) - 1)
          setTimeout(() => {
            dispatch(deleteViewRow({ objectCode, recordId: rowId }))

            const copyObjectData = structuredClone(objectData)
            copyObjectData.splice(rowSearchIndex, 1)
            setObjectData(copyObjectData)
          }, 3000)
        }

        return
      default:
        return
    }
  }

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    if (!params) {
      return
    }

    const { parentFieldName, fieldName } = getParsedFields(params.colDef.field)

    const fieldCode = parentFieldName ? `${parentFieldName}_ref` : params.colDef.field

    const existingRestriction = entityRestrictions?.width?.find(
      restriction => restriction.fieldCode === fieldCode
    )

    handleUpdateRestrictions([
      {
        ...(existingRestriction ? { id: existingRestriction.id } : {}),
        parentFieldName,
        fieldName,
        fieldCode,
        viewId: entityId as string,
        restrictionType: RESTRICTION_TYPE.WIDTH,
        value: Math.round(params.width),
      },
    ])
      .unwrap()
      .then(([{ value }]) => {
        onSetColumns(prev =>
          prev.map(col =>
            col.field === params.colDef.field ? { ...col, width: value as number } : col
          )
        )
      })
  }

  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>()

  const handleSetPinnedColumns = (value: GridPinnedColumns) => {
    setPinnedColumns(value)
  }

  const handlePinnedColumnsChange = (pinnedColumns: GridPinnedColumns) => {
    if (entityRestrictions === undefined) {
      return
    }

    const currentPinnedColumns = transformFixRestrictions(entityRestrictions.fix ?? [])

    // Поиск и преобразование новых закрепленных колонки
    const newPinnedColumns = findPinnedColumnsDifferences(
      entityRestrictions.fix ?? [],
      entityId as string,
      currentPinnedColumns,
      pinnedColumns
    )

    // Поиск и преобразование колонок, которые были откреплены
    const unpinnedColumns = findPinnedColumnsDifferences(
      entityRestrictions.fix ?? [],
      entityId as string,
      pinnedColumns,
      currentPinnedColumns,
      null,
      newPinnedColumns
    )

    const foundRestrictions: RestrictionDTO[] = [...unpinnedColumns, ...newPinnedColumns]
    const restrictionsToChange: RestrictionDTO[] = []

    const visibleColumns = apiRef.current.getVisibleColumns().map(col => col.field)
    const pinnedColumnsArray = getFilteredPinnedColumnsArray(pinnedColumns)
    const hasAllPinnedInVisibleColumns = visibleColumns.every(v => pinnedColumnsArray.includes(v))

    // проверка на наличие всех видимых закрепленных колонок, обход бага с дергающимся скроллом https://jiradc.utg.group/browse/WF-1342
    if (hasAllPinnedInVisibleColumns) {
      alert(t('configEntity.view.allPinError'))

      return
    }

    foundRestrictions.forEach((updatedRestriction: RestrictionDTO) => {
      const columnInView = entity?.columns?.find(
        column => column.code === updatedRestriction.fieldCode
      )

      if (columnInView && columnInView.pinnedColumn) {
        return
      }

      restrictionsToChange.push(updatedRestriction)
    })

    const shouldApplyUpdates = restrictionsToChange.length > 0

    if (shouldApplyUpdates) {
      handleUpdateRestrictions(restrictionsToChange).then(() => {
        handleGetEntityRestrictionsData()
      })
    } else {
      alert(t('configEntity.view.fixedByAdmin'))
    }
  }

  const handleHideColumnsDebounce = useCallback(
    useDebounceFn({
      fn: (newModel: GridColumnVisibilityModel, prevModel: GridColumnVisibilityModel) => {
        const resultModel = compareModels(prevModel, newModel)
        const modelEqualityCheck = isEqual(prevModel, newModel)

        if (modelEqualityCheck) {
          return
        }

        const columnVisibModel = Object.keys(resultModel).map(field => {
          const { parentFieldName, fieldName } = getParsedFields(field)

          const existingRestriction =
            entityRestrictions &&
            entityRestrictions?.show?.find(restriction => restriction.fieldCode === field)

          return {
            ...(existingRestriction ? { id: existingRestriction.id } : {}),
            parentFieldName,
            fieldName,
            fieldCode: fieldName,
            viewId: entityId as string,
            restrictionType: RESTRICTION_TYPE.SHOW,
            value: resultModel[field],
          }
        })

        handleUpdateRestrictions(columnVisibModel)
          .then(() => onSetColumnVisibilityModel(newModel))
          .catch(() => onSetColumnVisibilityModel(prevModel))
      },
      delay: 1000,
    }),
    [entityRestrictions]
  )

  const handleChangeColumnVisibilityModel = (model: GridColumnVisibilityModel) => {
    if (Object.entries(model).length === 0) {
      columns.forEach(column => {
        model[column.field] = true
      })
    }

    const pinnedColumnsArray = getFilteredPinnedColumnsArray(apiRef.current.getPinnedColumns())
    const visibleColumns = Object.keys(model).filter(key => model[key] === true)
    const hasAllPinnedInVisibleColumns =
      pinnedColumnsArray.length &&
      visibleColumns.length &&
      visibleColumns.every(v => pinnedColumnsArray.includes(v))

    // проверка на наличие всех видимых закрепленных колонок, обход бага с дергающимся скроллом https://jiradc.utg.group/browse/WF-1342
    if (hasAllPinnedInVisibleColumns) {
      alert(t('configEntity.view.allPinError'))

      return
    }

    onSetColumnVisibilityModel(model)

    handleHideColumnsDebounce(model, initialColumnVisibilityModel)
  }

  const handleHideColumnMenu = () => {
    if (apiRef.current && apiRef.current.hideColumnMenu) {
      apiRef.current.hideColumnMenu()
    }
  }

  const handleSetInitialSortingFromRestrictions = (viewColumns: ViewRow[]) => {
    const initialSorting: SortType[] = getSortByColumns(viewColumns || [])

    // Применение пользовательской сортировки при открытии сущности
    refreshChangeSortData(initialSorting)
  }

  useScrollDialogContainer({
    dialogId,
    apiRef,
    onScrollDraggingDialogContainer: handleHideColumnMenu,
  })

  return {
    state: {
      isCtrlHeld,
      isShiftHeld,
      selectedRowIds,
      samePageFormsData,
      currentStaticForm,
      currentDynamicForm,
      isOpenShowColumnsSettings,
      columnNameCheckBox,
      sortKeysForQuickSearch,
      sortKeysColumnCheckBox,
      pinnedColumns,
      scrollingParams,
      isLoadingRestrictions,
      searchAssistantValue,
      valueSearchFilter,
      searchRule,
      isSuccessDeleteRestrictions,
      isBooleanSearchMode,
    },
    data: {
      currentSort,
      objectData,
      totalPages,
      totalCountElements,
      totalElements,
      valueSearchFilter,
      searchRule,
      currentPage,
      isLoadingObjectData,
      staticFormsData,
      dynamicFormsData,
    },
    handlers: {
      refreshObjectData,
      handleChangeModelSort,
      cellRenderer,
      handleClickSearchFilter,
      handleSetIsCtrlHeld,
      handleSetIsShiftHeld,
      handleUpdateSamePageFormData,
      handleAddSamePageForm,
      handleSetSelectedRowIds,
      handleStaticFormChange,
      handleDynamicFormChange,
      handleRemoveSamePageForm,
      handleCloseCurrentFormTab,
      handleClearSamePageForms,
      handleSetOpenedContainersInStaticForms,
      handleEdit,
      handleAdditionalButtonClick,
      handleRowClick,
      handleCellClick,
      handleCreate,
      handleGetRowClassName,
      handleObjectDataPageChange,
      handleSetObjectData,
      handleGetCellClassName,
      handleObjectSubscribe,
      handleSetTotalElements,
      handleOpenShowColumnsSettings,
      handleColumnWidthChange,
      handlePinnedColumnsChange,
      handleChangeColumnVisibilityModel,
      handleDeleteViewRestrictionsByType,
      handleSetInitialSortingFromRestrictions,
      refreshChangeSortData,
      handleSetGlobalFilter,
      handleSetColumnNameCheckBox,
      handleSetSearchRule,
      handleSetSortKeysForQuickSearch,
      handleSetSortKeysColumnCheckBox,
      handleSetPinnedColumns,
      handleSetBooleanMode,
    },
  }
}

import { useMemo } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, IconButton } from '@mui/material'

import { JS_FUN_ARGS } from '@gantt/constants'
import { useCopy } from '@gantt/hooks/useCopy'

type VariablesListProps = {
  variables: Record<string, string[]>
}

export const VariablesList = ({ variables }: VariablesListProps) => {
  const handleWriteClipboardText = useCopy()
  const variablesArr = useMemo(() => {
    return (Object.keys(variables) as JS_FUN_ARGS[])
      .map(group => variables[group].map(variable => `${group}.${variable}`))
      .flat(1)
  }, [variables])

  return (
    <Box maxHeight={200} overflow={'auto'}>
      {variablesArr.map(variable => (
        <Box
          key={variable}
          alignItems={'center'}
          display={'flex'}
          justifyContent={'space-between'}
          onClick={() => handleWriteClipboardText(variable)}
        >
          <Box sx={{ cursor: 'copy' }}>{variable}</Box>
          {variable && (
            <IconButton>
              <ContentCopyIcon />
            </IconButton>
          )}
        </Box>
      ))}
    </Box>
  )
}

import { GETObjectDataParams, ObjectState, ShortObjectState } from '@types'

import { GETObjectStateParams, POSTObjectStateParams } from '../../types/api/objectState.api'

import { commonAPI } from './common.api'

export const objectStateApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllObjectStates: build.query<ShortObjectState[], GETObjectDataParams>({
      query: params => ({
        url: '/ui/objectState',
        params: {
          searchPattern: params?.searchPattern,
          sort: params?.sort,
          objectCode: params?.objectCode,
          objectField: params?.objectField,
        },
      }),
      providesTags: ['AllObjectStates'],
    }),
    fetchObjectStateById: build.query<ObjectState, GETObjectStateParams>({
      query: ({ id }) => ({
        url: `/ui/objectState/id/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleObjectState'],
    }),
    createOrUpdateObjectState: build.mutation<ObjectState, POSTObjectStateParams>({
      query: objectState => ({
        url: `/ui/objectState`,
        method: 'POST',
        body: objectState,
      }),
      invalidatesTags: ['AllObjectStates', 'ObjectState', 'SingleObjectState'],
    }),
    deleteObjectState: build.mutation<void, number>({
      query: id => ({
        url: `/ui/objectState/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllObjectStates', 'ObjectState'],
    }),
  }),
})

export const {
  useFetchAllObjectStatesQuery,
  useFetchObjectStateByIdQuery,
  useCreateOrUpdateObjectStateMutation,
  useDeleteObjectStateMutation,
} = objectStateApi

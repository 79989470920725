import { useNavigate } from 'react-router-dom'

import { useDeleteObjectStateMutation } from '@redux/api'

import { MODAL_TYPE, ROUTES } from '@constants'

export const useHandlers = () => {
  const navigate = useNavigate()

  const [deleteObjectState] = useDeleteObjectStateMutation()

  const handleCreateObjectsState = () => navigate(MODAL_TYPE.CREATE)

  const handleEditObjectsState = (id: number | string) =>
    navigate(`${ROUTES.CONFIG_OBJECTS_STATES_EDIT}/${id}`)

  const handleDeleteObjectsState = (id: number) => deleteObjectState(id)

  return {
    state: {},
    handlers: {
      handleEditObjectsState,
      handleCreateObjectsState,
      handleDeleteObjectsState,
    },
  }
}

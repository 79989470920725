import { useEffect } from 'react'

import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'
import { useFetchPreFillObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchPreFillObjectDataWithFixedKey'

import { PreFillType } from '@constants'

type UseFetchRowProps = {
  // objectCode: string
  formCode: string
  objectId?: string
  globalId: string
  preFill?: PreFillType
  // fetchRow: MutationType<GETObjectDataRecordParams, ObjectDataRecord>
}

export const useFetchRow = ({ objectId, formCode, globalId, preFill }: UseFetchRowProps) => {
  const [fetchRow, { isLoading: isLoadingRow, data }] = useFetchObjectDataWithFixedKey(globalId)
  const [fetchPreFillRow, { isLoading: isLoadingPreFillRow, data: preFillData }] =
    useFetchPreFillObjectDataWithFixedKey(globalId)

  useEffect(() => {
    // Если есть preFill и нет objectId (то есть это форма создания),
    // то мы загружаем данные для предварительного заполнения формы создания
    if (formCode && !objectId && preFill) {
      fetchPreFillRow({ formCode, objectId: preFill.objectId, objectCode: preFill.objectCode })

      return
    }
    // Иначе загружаем данные как обычно (для формы редактирования)
    if (formCode && objectId) {
      fetchRow({ formCode, objectId })

      return
    }
  }, [formCode, objectId])
  // TODO рефакторинг логики с preFill
  return {
    isLoadingRow: preFill ? isLoadingPreFillRow : isLoadingRow,
    row: preFill ? preFillData : data,
  }
}

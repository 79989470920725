import { Interpolation, Theme } from '@mui/material/styles'

import { THEME_MODE } from '@constants'

export const borderTableByMode = (
  wrapperClassName: string,
  theme: Theme,
  borderColor?: Interpolation<Theme>
) => {
  const isGlobalStyleBorder = borderColor && borderColor !== 'none'

  if (isGlobalStyleBorder) {
    return {
      '& .MuiDataGrid-root': {
        borderRadius: 0,
        border: `1px solid ${borderColor}`,
      },
      '& .MuiDataGrid-cell': {
        transition: 'background-color 0.3s linear',
        border: `1px solid ${borderColor} !important`,
        borderTopWidth: '0 !important',
        borderLeftWidth: '0 !important',
      },
      [`.${wrapperClassName} .MuiDataGrid-columnHeader`]: {
        borderRight: `1px solid ${borderColor}`,
      },
      [`.${wrapperClassName} .MuiDataGrid-pinnedColumns--right`]: {
        borderLeft: `1px solid ${borderColor} !important`,
      },
      [`.${wrapperClassName} .MuiDataGrid-pinnedColumnHeaders--right`]: {
        borderLeft: `1px solid ${borderColor} !important`,
        '& .MuiDataGrid-columnHeader:last-child': {
          borderRightWidth: 0,
        },
      },
      [`.${wrapperClassName} .MuiDataGrid-columnHeaders`]: {
        borderBottom: `1px solid ${borderColor}`,
      },
    }
  }

  if (theme.palette.mode === THEME_MODE.LIGHT) {
    return {
      '& .MuiDataGrid-root': {
        border: theme.palette.border.table,
        borderRadius: 0,
      },
      '& .MuiDataGrid-cell': {
        transition: 'background-color 0.3s linear',
        border: `${theme.palette.border.table}`,
      },
      [`.${wrapperClassName} .MuiDataGrid-columnHeader`]: {
        border: theme.palette.border.table,
      },
      [`.${wrapperClassName} .MuiDataGrid-columnHeaders`]: {
        border: theme.palette.border.table,
      },
    }
  }

  return {
    '& .MuiDataGrid-root': {
      border: theme.palette.border.table,
    },
    '& .MuiDataGrid-cell': {
      transition: 'background-color 0.3s linear',
    },
  }
}

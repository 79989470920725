import Box from '@mui/material/Box'
import Button, { type ButtonProps } from '@mui/material/Button'
import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress'
import IconButton, { type IconButtonProps } from '@mui/material/IconButton'

type IconProps = {
  loading: boolean
  children: React.ReactNode
  progressProps?: CircularProgressProps
}
export function LoadingIconButton({
  loading,
  children, // Mui Icon
  progressProps,
  ...iconButtonProps
}: IconButtonProps & IconProps): React.ReactElement {
  const size = iconButtonProps.size === 'large' ? 4 : iconButtonProps.size === 'small' ? -3 : 0

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', verticalAlign: 'middle' }}>
      <IconButton {...iconButtonProps} disabled={loading}>
        {children}
      </IconButton>
      {loading && (
        <CircularProgress sx={{ position: 'absolute', top: size, left: size, zIndex: 1 }} />
      )}
    </Box>
  )
}

type Props = { loading: boolean; children: React.ReactNode; progressProps?: CircularProgressProps }
export function LoadingButton({
  loading,
  children,
  progressProps,
  startIcon,
  endIcon,
  ...buttonProps
}: ButtonProps & Props): React.ReactElement {
  return (
    <Button
      {...buttonProps}
      disabled={loading || buttonProps.disabled}
      endIcon={loading && endIcon ? <CircularProgress size={24} {...progressProps} /> : endIcon}
      position='relative'
      startIcon={
        loading && startIcon ? <CircularProgress size={24} {...progressProps} /> : startIcon
      }
    >
      {loading && !startIcon && !endIcon && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'grid',
            placeContent: 'center',
          }}
        >
          <CircularProgress size={24} {...progressProps} />
        </Box>
      )}
      {children}
    </Button>
  )
}

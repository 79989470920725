import { FieldPath } from 'react-hook-form'

import { GanttFormValues } from '@gantt/components/GanttCreateOrEdit'

export const RESOURCE_KEY_FIELD_PREFIX = 'sectionResource_'
export const RESOURCE_LINK_FIELD_PREFIX = 'sectionLink_'
export const GENERAL_FORM_FIELD_KEYS: Array<FieldPath<GanttFormValues>> = ['code', 'title']
export const AXIS_Y_FORM_FIELD_KEYS: Array<FieldPath<GanttFormValues>> = ['resource']
export const AXIS_X_FORM_FIELD_KEYS: Array<FieldPath<GanttFormValues>> = ['timeline']
export const MAIN_OBJECT_FORM_FIELD_KEYS: Array<FieldPath<GanttFormValues>> = ['sort']

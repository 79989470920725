import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import isEqual from 'lodash.isequal'

import { useObjectsStates } from '@pages/ObjectsStates/hooks'
import { FormObjectStatesType, ObjectStateValueType } from '@pages/ObjectsStatesCreateOrEdit/types'

import {
  useCreateOrUpdateObjectStateMutation,
  useFetchAllObjectQuery,
  useFetchObjectByCodeQuery,
  useFetchObjectStateByIdQuery,
} from '@redux/api'

import { usePrompt } from '@hooks'
import { MODAL_TYPE, ROUTES } from '@constants'
import { ModalType, ObjectFieldDTO } from '@types'

type useHandlersParams = {
  valueJs: string
  setValueJs: Dispatch<SetStateAction<string>>
}

export const useHandlers = ({ valueJs, setValueJs }: useHandlersParams) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [fieldsSelectedObject, setFieldsSelectedObject] = useState<ObjectFieldDTO[] | undefined>([])

  const { data: objects } = useFetchAllObjectQuery()

  const { data: objectStateById, isLoading: isLoadingObjectStateById } =
    useFetchObjectStateByIdQuery(
      { id },
      {
        skip: !id,
        refetchOnMountOrArgChange: true,
      }
    )

  useEffect(() => {
    if (objectStateById) {
      reset({
        internalId: objectStateById.code ? objectStateById.code : null,
        title: objectStateById.title ? objectStateById.title : '',
        object:
          objectStateById.objectTitle && objectStateById.objectCode
            ? { id: objectStateById.objectCode, label: objectStateById.objectTitle }
            : null,
        objectField: objectStateById.objectField
          ? { id: objectStateById.objectField, label: objectStateById.objectField }
          : null,
        isJsObjectStates: objectStateById.isJs ? objectStateById.isJs : false,
      })
      if (objectStateById?.styleSettingScript) {
        setValueJs(objectStateById?.styleSettingScript)
      }
    }
  }, [objectStateById])

  const objectStateId = objectStateById?.id

  const initFields: ObjectStateValueType[] = useMemo(
    () =>
      objectStateById?.objectStateValues.map(el => ({
        id: el.id,
        fieldValue: el?.objectFieldValue,
        styleSettingsTitle: el?.styleSetting?.title,
        styleSettingId: el?.styleSetting?.id,
        objectStateId,
      })),
    [objectStateById]
  )
  const [editableFieldValue, setEditableFieldValue] = useState<ObjectStateValueType>(
    {} as ObjectStateValueType
  )
  const [fields, setFields] = useState<ObjectStateValueType[]>(initFields ?? [])
  // если в таблицу с ObjectStateValues внесли изменения (добавили/убрали/отредактирвоали филд)
  const [dirtyOnField, setDirtyOnField] = useState(false)

  useEffect(() => {
    if (initFields) {
      setFields(initFields)
    }
  }, [initFields])

  const handleSetFields = (data: ObjectStateValueType, isEdit: boolean) => {
    if (isEdit && editableFieldValue.id === data.id) {
      const filteredFields = fields.filter(field => field.id !== data.id)
      setFields([...filteredFields, data])
      setDirtyOnField(!isEqual(initFields, [...filteredFields, data]))

      return
    }
    setFields([...fields, data])
    setDirtyOnField(!isEqual(initFields, [...fields, data]))
  }

  const methods = useForm<FormObjectStatesType>({
    defaultValues: {
      id: undefined,
      internalId: null,
      title: '',
      object: null,
      objectField: null,
      isJsObjectStates: false,
      fieldsLength: fields.length ?? 0,
    },
  })

  const {
    watch,
    handleSubmit,
    formState: { isDirty },
    reset,
    setValue,
  } = methods

  const watchObject = watch('object')
  const watchIsJsObjectStates = watch('isJsObjectStates')

  usePrompt({ when: isDirty })

  const { data: selectedObject } = useFetchObjectByCodeQuery(watchObject?.id, {
    skip: !watchObject?.id,
  })

  useEffect(() => {
    if (selectedObject?.fields) {
      setFieldsSelectedObject(selectedObject?.fields)
    }
  }, [selectedObject])

  const [createOrUpdateObjectState] = useCreateOrUpdateObjectStateMutation()

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalType>(MODAL_TYPE.CREATE)
  const [objectStateValueID, setObjectStateValueID] = useState<number | undefined>(undefined)
  const [showScriptValueDialogTitle, setShowScriptValueDialogTitle] = useState<boolean>(false)

  const handleSetShowScriptValueDialogTitle = (value: boolean) =>
    setShowScriptValueDialogTitle(value)

  const handleSave = handleSubmit((objectStateData: FormObjectStatesType) => {
    const { internalId, objectField, object, isJsObjectStates, title } = objectStateData

    const objectCode = watchObject?.id ? watchObject?.id : null

    const fieldsPOST = fields.map(field => {
      // отправляем на бэк без styleSettingsTitle и id => приходят каждый раз новые id у objectStateValues
      const { styleSettingsTitle, id, ...rest } = field
      setDirtyOnField(false)

      return { ...rest }
    })

    const objectStateValues = fields ? [...fieldsPOST] : []

    createOrUpdateObjectState({
      ...(id ? { id } : {}),
      code: internalId,
      objectCode,
      title,
      objectTitle: object?.label,
      objectField: objectField?.label,
      styleSettingScript: isJsObjectStates ? valueJs : null,
      isJs: isJsObjectStates ? isJsObjectStates : false,
      objectStateValues,
    })
      .unwrap()
      .then(res => {
        if (id) {
          return
        }

        if (!res) {
          handleCancel()
        }

        navigate(`${ROUTES.CONFIG_OBJECTS_STATES_EDIT}/${res.id}`)
      })
      .catch(() => {
        //
      })
  })

  const {
    state: { allObjectsStates },
  } = useObjectsStates()

  const handleCloseModal = (hasDirty: boolean) => {
    if (hasDirty && !confirm(t('notifications.leave'))) {
      return
    }
    setShowDialog(false)
  }

  const handleSetShowDialog = (show: boolean) => {
    setShowDialog(show)
  }

  const handleCancel = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }
    navigate(ROUTES.CONFIG_OBJECTS_STATES)
  }

  const handleOpenDialog = async (type: ModalType, id?: number | string) => {
    if (type === MODAL_TYPE.EDIT && id) {
      const editableField = fields.find(field => id === field.id)
      if (editableField) {
        setEditableFieldValue(editableField)
      }
    }
    setModalType(type)
    setShowDialog(true)
  }

  const handleDeleteObjectStateValue = (id: number | string) => {
    const filteredFields = fields.filter(field => field.id !== id)
    setFields(filteredFields)
    setDirtyOnField(!isEqual(initFields, filteredFields))
  }

  const handleEdit = (id?: number | string) => {
    setObjectStateValueID(Number(id))
    if (id) {
      handleOpenDialog(MODAL_TYPE.EDIT, id)
    }
  }

  return {
    state: {
      showDialog,
      modalType,
      fieldsSelectedObject,
      objectStateValueID,
      showScriptValueDialogTitle,
      rows: fields,
      editableFieldValue,
      isDirty,
      dirtyOnField,
      allObjectsStates,
    },
    data: {
      objects,
      watch,
      watchIsJsObjectStates,
      watchObject,
      methods,
      selectedObject,
      objectStateId,
      objectStateById,
      isLoadingObjectStateById,
    },
    handlers: {
      handleSave,
      handleCancel,
      handleCloseModal,
      handleOpenDialog,
      handleEdit,
      handleDeleteObjectStateValue,
      handleSetShowScriptValueDialogTitle,
      setValue,
      handleSetFields,
      handleSetShowDialog,
    },
  }
}

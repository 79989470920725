import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'

import { transformTextParamsData } from '@pages/Parameters/helpers'

import { getColumnParametersFromArray, getEntityTypeForRequest } from '@helpers'
import { CATEGORIES, ENTITY, MODEL_TYPE, ROUTES } from '@constants'

import {
  useCreateActionViewMutation,
  useCreateAllColumnsMutation,
  useCreateColumnViewMutation,
  useCreateMandatoryColumnsMutation,
  useCreateViewMutation,
  useDeleteActionViewMutation,
  useDeleteColumnViewMutation,
  useFetchActionViewMutation,
  useFetchAllObjectQuery,
  useFetchColumnViewMutation,
  useFetchDictionariesByCategoryCodeQuery,
  useFetchObjectByCodeQuery,
  useFetchViewQuery,
  useUpdateActionViewMutation,
  useUpdateColumnViewMutation,
  useUpdateOrderColumnsMutation,
  useUpdateViewMutation,
  useUpdateVisibilityColumnByIdMutation,
} from '../../../redux/api'
import { EntityType, ViewActionRow, ViewRow } from '../../../types'

import { useHandlers } from './useHandlers'

type UseEntityCreateOrEditProps = {
  type: ENTITY
}

export const useEntityCreateOrEdit = ({ type }: UseEntityCreateOrEditProps) => {
  const [currentEntity, setCurrentEntity] = useState<EntityType>()

  const [createEntity, { isSuccess: isSuccessCreate, isLoading: entityCreateLoading }] =
    useCreateViewMutation()
  const [updateEntity, { isSuccess: isSuccessUpdate, isLoading: entityUpdateLoading }] =
    useUpdateViewMutation()
  const [fetchColumn, { data: row, fulfilledTimeStamp: timeRow, isLoading: isLoadingColumn }] =
    useFetchColumnViewMutation()
  const [createColumn] = useCreateColumnViewMutation()
  const [createAllColumns, { isLoading: createAllColumnsLoading }] = useCreateAllColumnsMutation()
  const [createMandatoryColumns, { isLoading: createMandatoryColumnsLoading }] =
    useCreateMandatoryColumnsMutation()
  const [updateColumn] = useUpdateColumnViewMutation()
  const [deleteColumn] = useDeleteColumnViewMutation()
  const [updateColumnOrder] = useUpdateOrderColumnsMutation()
  const [updateVisibilityColumn] = useUpdateVisibilityColumnByIdMutation()
  const [
    fetchAction,
    { data: actionRow, fulfilledTimeStamp: timeAction, isLoading: isLoadingAction },
  ] = useFetchActionViewMutation()
  const [createAction] = useCreateActionViewMutation()
  const [deleteAction] = useDeleteActionViewMutation()
  const [editAction] = useUpdateActionViewMutation()

  const { state: locationState } = useLocation() as Location & { state?: { code?: string } }
  const { code } = useParams()
  const copyCode = locationState?.code
  const viewType = getEntityTypeForRequest(type)

  const {
    data: entity,
    isError,
    isLoading: entityIsLoading,
  } = useFetchViewQuery({ code: code ?? copyCode, viewType }, { skip: !code && !copyCode })

  const methods = useForm<{ useParameters: boolean; hasQuickSearch: boolean }>({
    defaultValues: { useParameters: true, hasQuickSearch: true },
  })
  const {
    watch,
    formState: { isDirty: isDirtyUseParametersModal },
  } = methods

  const watchUseParameters = watch('useParameters')
  const watchQuickSearch = watch('hasQuickSearch')

  const { headerProperties, textProperties } = getColumnParametersFromArray(
    currentEntity && currentEntity.parameters ? currentEntity.parameters : []
  )

  const initialColumnHeaderStyles = useMemo(
    () => transformTextParamsData(headerProperties),
    [headerProperties, entity]
  )
  const initialColumnTextStyles = useMemo(
    () => transformTextParamsData(textProperties),
    [textProperties, entity]
  )

  const { state, data, handlers } = useHandlers({
    entity,
    type,
    createEntity,
    updateEntity,
    createColumn,
    createAllColumns,
    createMandatoryColumns,
    fetchColumn,
    updateColumn,
    deleteColumn,
    fetchAction,
    deleteAction,
  })

  const { objectCode, modalProperty, showDialog } = state

  const { handleSetCurrentRow } = handlers

  const mainRows: ViewRow[] = useMemo(() => entity?.columns || [], [entity])
  const actionRows: ViewActionRow[] = useMemo(
    () =>
      entity?.actions?.map(action => ({ ...action, params: JSON.stringify(action.params) })) || [],
    [entity]
  )

  const { data: objects } = useFetchAllObjectQuery()
  const { data: object } = useFetchObjectByCodeQuery(objectCode, { skip: !objectCode })
  const { data: bindingValues } = useFetchDictionariesByCategoryCodeQuery(CATEGORIES.BINDING_VIEW)
  const { data: bindingValuesTypes } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_TYPES_VIEW
  )
  const { data: bindingValuesColumns } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_COLUMN,
    {
      skip: modalProperty !== MODEL_TYPE.COLUMN,
    }
  )
  const { data: bindingValuesColumnsTypes } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_TYPES_COLUMN,
    {
      skip: modalProperty !== MODEL_TYPE.COLUMN,
    }
  )
  const { data: bindingValuesAction } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_ACTION,
    {
      skip: modalProperty !== MODEL_TYPE.ACTION,
    }
  )
  const { data: bindingValuesActionEvents } = useFetchDictionariesByCategoryCodeQuery(
    CATEGORIES.BINDING_EVENTS_ACTION
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (entity?.columns && entity.actions) {
      setCurrentEntity(copyCode ? { ...entity, code: '' } : entity)

      return
    }
  }, [entity])

  useEffect(() => {
    if (row && actionRow && timeRow && timeAction) {
      handleSetCurrentRow(timeRow > timeAction ? row : actionRow)

      return
    }
    if (row && !isLoadingAction) {
      handleSetCurrentRow(row)

      return
    }
    if (actionRow && !isLoadingColumn) {
      handleSetCurrentRow(actionRow)
    }
  }, [row, actionRow])

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.CONFIG_VIEWS_CREATE)
    }
  }, [isError])

  useEffect(() => {
    if (!showDialog) {
      handleSetCurrentRow(null)
    }
  }, [showDialog])

  return {
    state: {
      ...state,
      currentEntity,
    },
    data: {
      ...data,
      locationState,
      objects,
      object,
      bindingValues,
      bindingValuesTypes,
      bindingValuesColumnsTypes,
      bindingValuesColumns,
      bindingValuesAction,
      bindingValuesActionEvents,
      mainRows,
      actionRows,
      entityIsLoading,
      entityUpdateLoading,
      entityCreateLoading,
      isSuccessCreate,
      isSuccessUpdate,
      objectCode,
      initialColumnHeaderStyles,
      initialColumnTextStyles,
      methods,
      watchUseParameters,
      watchQuickSearch,
      isDirtyUseParametersModal,
      createAllColumnsLoading,
      createMandatoryColumnsLoading,
    },
    handlers,
    mutations: {
      createAction,
      editAction,
      updateColumnOrder,
      updateVisibilityColumn,
    },
  }
}

import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, Typography } from '@mui/material'

import { getIsLast } from '@gantt/helpers'
import { GANTT_CONFIG_STEPS } from '@gantt/types'

import { TooltipStyled } from './TooltipStyled'

interface IProps {
  label: string
  errors: (string | undefined)[]
}

export const StepError: FC<IProps> = ({ errors, label }) => {
  const { t } = useTranslation()

  const title = useMemo(
    () =>
      errors.map((item, index) => {
        const itemSting = getIsLast(index, errors) ? item : `${item}, `

        if (label === GANTT_CONFIG_STEPS.AXIS_X) {
          return <div key={itemSting}>{itemSting}</div>
        }

        return itemSting
      }),
    [errors]
  )

  return (
    <TooltipStyled
      title={
        <>
          {t('ganttCreate.invalidFields')}
          {title}
        </>
      }
    >
      <Typography color='error' variant='caption'>
        <Box alignItems='center' display='flex' justifyContent='center'>
          <Typography mr={0.5} variant={'body2'}>
            {t('ganttCreate.validationErrors')}
          </Typography>
          <HelpOutlineIcon fontSize='small' />
        </Box>
      </Typography>
    </TooltipStyled>
  )
}

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { matchPath, useLocation } from 'react-router-dom'

import { useFetchAllStyleSettingsQuery } from '@redux/api/styleSetting.api'

import { useDebounce } from '@hooks'
import { getSortParamRequest } from '@helpers'
import { SEARCH_PATTERN_DELAY } from '@constants'
import { ShortStyleSetting } from '@types'

import { useHandlers } from './useHandlers'

export const useConfigStyleSettings = () => {
  const { pathname } = useLocation()
  const isAllStyleSettings = matchPath('/style_settings', pathname)
  const [allStyleSettings, setAllStyleSettings] = useState<ShortStyleSetting[]>([])

  const methods = useForm<{ searchPattern: string }>({ defaultValues: { searchPattern: '' } })
  const { watch } = methods
  const searchPattern = watch('searchPattern')

  const { state, data, handlers } = useHandlers()

  const { currentSort } = state

  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)
  const sortParam = getSortParamRequest(currentSort)

  const { data: styleSettingsData, isLoading: isLoadingStyleSettingsData } =
    useFetchAllStyleSettingsQuery(
      {
        sort: sortParam ? sortParam : undefined,
        searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    )

  useEffect(() => {
    if (styleSettingsData) {
      setAllStyleSettings(styleSettingsData)
    }
  }, [styleSettingsData])

  return {
    state: { allStyleSettings, ...state },
    data: {
      ...data,
      isAllStyleSettings,
      isLoadingStyleSettingsData,
      methods,
    },
    handlers,
  }
}

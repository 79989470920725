import { useContext, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { t } from 'i18next'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import { IMessage } from '@stomp/stompjs'

import { PageContext as FormContext } from '@pages/FormPage'

import { getDefaultValuesByObjectFields } from '@components/DisplayForm/helpers'
import { useCreateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useCreateObjectDataRecordWithFixedKey'
import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'
import { useFormFields } from '@components/DisplayForm/hooks/useFormFields'
import { useUpdateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useUpdateObjectDataRecordWithFixedKey'

import { useFetchObjectDataRecordEnrichedByIdMutation } from '@redux/api'
import { deleteFormField, setFormFields } from '@redux/reducers/changedFormFields.reducer'
import { selectIndexById } from '@redux/reducers/formHistory.reducer'

import { useAppDispatch, useAppSelector } from '@hooks'
import { getDirtyValues, isObjectValueType } from '@helpers'
import {
  EVENT_NAME_OBJECT_MESSAGE,
  FIELD_VALUE_TYPE,
  FORM_TYPE,
  OBJECT_FIELD_TYPE,
} from '@constants'
import {
  FormRow,
  FormType,
  LinkFormHistory,
  LinkType,
  LinkViewHistory,
  MessageBody,
  ObjectDataRecord,
  ObjectFieldDTO,
  PassedFormData,
} from '@types'

import { Row } from '../types'

type useHandlersParams = {
  passedParams: PassedFormData
  fetchedForm: FormType | undefined
  pkField: ObjectFieldDTO | undefined
  // row: ObjectDataRecord | undefined
  fields: FormRow[]
  withPassedParams: boolean
  methods: UseFormReturn<Row>
  currentLink: LinkFormHistory
  dialogId?: string
  onBreadcrumbsMainLinkClick: (link?: LinkViewHistory) => void
  onObjectDataRecordSuccessCreating: (links: LinkType[], data: ObjectDataRecord) => void
  handleRemoveSamePageForm: (formDataId?: number, isDirty?: boolean) => void
  handleUpdateSamePageFormData: (
    oldObjectId: string,
    newFormCode: string,
    objectCode: string,
    objectId: string
  ) => void
  handleCloseCurrentFormTab: () => void
}

export const useHandlers = ({
  passedParams,
  fetchedForm,
  // row,
  fields,
  withPassedParams,
  methods,
  currentLink,
  dialogId,
  onBreadcrumbsMainLinkClick,
  onObjectDataRecordSuccessCreating,
  handleRemoveSamePageForm,
  handleUpdateSamePageFormData,
  handleCloseCurrentFormTab,
  pkField,
}: useHandlersParams) => {
  const { formCode, objectId, viewCode, viewId, path, event, globalId } = passedParams
  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods

  const formHistory = useAppSelector(state => state.formHistory)[globalId] || {}
  const { links, selectedIndex } = formHistory

  const [isPendingCreateOrUpdateForm, setPendingCreateOrUpdateForm] = useState(false)

  const [isViewEdit, setViewEdit] = useState(false)
  const [isDeleteRow, setIsDeleteRow] = useState(false)
  const [isDropdownWindowOpenFormState, setDropdownWindowOpenFormState] = useState(false)
  const [isSaveByEnterDisabled, setSaveByEnterDisabled] = useState(false)

  const isViewType = currentLink.event === FORM_TYPE.VIEW && !isViewEdit
  // TODO Проверить, возможно нужно заменить, скорее всего нужно завязаться на formCode
  const [fetchEnrichedRow] = useFetchObjectDataRecordEnrichedByIdMutation()

  const [updateObjectDataRecord, { isSuccess: isSuccessUpdateObjectData }] =
    useUpdateObjectDataRecordWithFixedKey(globalId)

  const [createObjectDataRecord] = useCreateObjectDataRecordWithFixedKey(globalId)

  const [fetchForm, { data: row }] = useFetchObjectDataWithFixedKey(globalId)

  const formFields = useFormFields({ formCode })

  const { handleSetSearchParams } = useContext(FormContext)

  const dispatch = useAppDispatch()

  const handleSetSaveByEnterDisabled = (value: boolean) => {
    setSaveByEnterDisabled(value)
  }

  const handleDataForNewForm = ({ event, formCode, objectCode, objectId }: LinkFormHistory) => {
    // TODO: проверить нужно ли
    handleUpdateSamePageFormData(passedParams.objectId, formCode, objectCode, objectId as string)
  }

  const handleObjectSubscribe = (msg: IMessage) => {
    const body: MessageBody = JSON.parse(msg.body)
    const { objectCode, objectId: rowId, changedFields, eventName } = body
    const isCurrentRow = rowId === row?._id
    // TODO viewId здесь нет
    if (viewId && isCurrentRow) {
      switch (eventName) {
        case EVENT_NAME_OBJECT_MESSAGE.UPDATE:
          fetchEnrichedRow({ objectCode, ids: [rowId], viewId }) // TODO Разобраться зачем тут вообще viewId
            .unwrap()
            .then(() => {
              if (changedFields) {
                dispatch(
                  setFormFields({
                    objectCode,
                    changedFields: Object.keys(changedFields),
                    eventName,
                    recordId: rowId,
                  })
                )

                setTimeout(() => {
                  dispatch(deleteFormField({ objectCode, recordId: rowId }))
                }, 3000)
              }
            })
          break
        case EVENT_NAME_OBJECT_MESSAGE.DELETE:
          setIsDeleteRow(true)
          break
      }
    }
  }

  const handleSetDropdownWindowOpenFormState = (value: boolean) => {
    return setDropdownWindowOpenFormState(value)
  }

  const handleSetType = (type: FORM_TYPE) => {
    if (fetchedForm?.objectCode && objectId && viewCode && path) {
      handleSetSearchParams({
        objectCode: fetchedForm.objectCode,
        objectId,
        viewCode,
        path,
        event: type,
      })
    }
  }

  const handleFormatValue = ([key, value]: [string, any]) => {
    const field = fields.find(field => field.code === key)

    // Datetime
    if (value?._isAMomentObject && value?.isValid) {
      return [key, moment(value).format()]
    }

    // Linked object
    if (field && isObjectValueType(field.valueType)) {
      return [key, value?.id ? value.id : value]
    }

    // Enum
    if (field && field.type === OBJECT_FIELD_TYPE.ENUM) {
      return [key, value]
    }

    // Integer
    if (
      field &&
      (field.valueType === FIELD_VALUE_TYPE.INTEGER || field.valueType === FIELD_VALUE_TYPE.DOUBLE)
    ) {
      if (isNumber(value)) {
        return [key, value]
      }

      return [key, value ? Number(value) : null]
    }

    // Default
    return [key, value]
  }

  /**
   * @param link
   * @param alertHidden - для скрытия алерта подтверждения
   */
  const handleOpenLink = (newIndex: number, alertHidden?: boolean) => {
    const newLink = links[newIndex]

    const isAlert = !alertHidden && isDirty
    if (isAlert && !confirm(t('notifications.leave'))) {
      return
    }

    if (dialogId) {
      dispatch(selectIndexById({ id: dialogId, index: newIndex }))
    }

    // вынес это из Breadcrumbs. Логика закрытия динамического и статического окна
    if (withPassedParams && !('formCode' in newLink)) {
      handleRemoveSamePageForm(passedParams.id)

      return
    }

    // Вынес наружу в onBreadcrumbsMainLinkClick
    if (newIndex === 0 && newLink.isRootLink) {
      onBreadcrumbsMainLinkClick()

      return
    }
    handleResetEdit()

    handleDataForNewForm(newLink as LinkFormHistory)
  }

  const handleSave = handleSubmit((data: Row) => {
    if (isPendingCreateOrUpdateForm) {
      return
    }

    const formId = fetchedForm?.id

    if (!formId) {
      return
    }

    if (!pkField) {
      return
    }

    const rowData: ObjectDataRecord = Object.fromEntries(
      Object.entries(data).map(([key, value]) => handleFormatValue([key, value]))
    )

    const dirtyRowData = getDirtyValues(dirtyFields, rowData)
    setPendingCreateOrUpdateForm(true)
    if ((currentLink.event === FORM_TYPE.EDIT || isViewEdit) && row) {
      // Редактирование записи
      const record = { ...dirtyRowData }
      record[pkField.name] = row[pkField.name] // Достаём primary key, чтобы отправить его в запрос

      updateObjectDataRecord({
        formId,
        record,
      })
        .unwrap()
        // В ответе нет обогащённых данных через ref. Поэтому грузим объект заново
        // Данные автоматически обновляются в useFormResetLogic из-за fixedCacheKey в хуке useFetchObjectDataWithFixedKey
        .then(() =>
          fetchForm({ formCode, objectId: record[pkField.name] as unknown as string }).unwrap()
        )
        .finally(() => {
          setPendingCreateOrUpdateForm(false)
          setViewEdit(false)
        })
    } else {
      // Создание записи
      createObjectDataRecord({
        formId,
        record: { ...dirtyRowData },
      })
        .unwrap()
        .then(data => {
          if (data) {
            if (selectedIndex > 0) {
              handleOpenLink(selectedIndex - 1, true)

              return
            }
            onObjectDataRecordSuccessCreating(links, data)
            // navigate('/' + (links[0].path || viewPath))
          }
        })
        .finally(() => {
          setPendingCreateOrUpdateForm(false)
        })
    }
  })

  const handleCancel = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    if (!formFields || !row) {
      return
    }

    const defaultValues = getDefaultValuesByObjectFields(formFields, row)
    isDirty && reset(defaultValues)
    setViewEdit(false)
  }

  const handleEditOrSaveClick = () => {
    if (isViewType) {
      setViewEdit(true)

      return
    }

    if (isDirty) {
      handleSave()
    }
  }

  const handleResetEdit = () => setViewEdit(false)

  return {
    data: {
      isViewType,
      links,
    },
    state: {
      isViewEdit,
      isDeleteRow,
      isDropdownWindowOpenFormState,
      isSaveByEnterDisabled,
      isSuccessUpdateObjectData,
      isPendingCreateOrUpdateForm,
    },
    handlers: {
      handleDataForNewForm,
      handleObjectSubscribe,
      handleSetType,
      handleFormatValue,
      handleOpenLink,
      handleSave,
      handleCancel,
      handleEditOrSaveClick,
      handleResetEdit,
      handleSetDropdownWindowOpenFormState,
      handleSetSaveByEnterDisabled,
    },
  }
}

import { FC } from 'react'
import { FormLabel, TextField } from '@microservices/wiskey-react-components'
import { Grid, SxProps } from '@mui/material'

import { generalFieldStylesByMode } from '@helpers'

type JSFieldProps = {
  name: string
  label?: string
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  labelSx?: SxProps
  placeholder?: string
  value?: string
  className?: string
  sx?: SxProps
  dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>
  isDialogWindow: boolean
}

export const JSField: FC<JSFieldProps> = ({
  name,
  value,
  label,
  labelPlacement = 'start',
  labelSx,
  placeholder,
  className,
  sx,
  dirtyFields,
  isDialogWindow,
}) => {
  const renderInput = () => {
    return (
      <TextField
        disabled
        fullWidth
        className={className}
        name={name}
        placeholder={placeholder}
        size={'small'}
        value={value}
        sx={theme => ({
          ...generalFieldStylesByMode(theme, dirtyFields, value, undefined, isDialogWindow),
        })}
      />
    )
  }

  return (
    <Grid container item justifyContent={'space-between'}>
      <FormLabel
        control={renderInput()}
        label={label}
        labelPlacement={labelPlacement}
        labelSx={labelSx}
        name={name}
        sx={sx}
      />
    </Grid>
  )
}

import { useContext } from 'react'
import i18next from 'i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'

import { generateDefaultObjectByFields } from '@helpers'
import {
  FIELD_VALUE_TYPE,
  GENERATOR_INPUT_TYPE,
  MODAL_TYPE,
  PINNED_COLUMN,
  REGEX,
} from '@constants'
import { SelectOption } from '@types'

import { GanttColumnForm, PageContext } from '@gantt/components/GanttCreateOrEdit'

type useInputsParams = {
  bindTypeOptions: SelectOption[]
  valueType: FIELD_VALUE_TYPE | null
  watchPinning: boolean
  currentRow?: GanttColumnForm | null
}

export const useInputs = ({ valueType, watchPinning, currentRow }: useInputsParams) => {
  const { modalType, rawObjectFields, currentColumnsList } = useContext(PageContext)
  const srcObj = generateDefaultObjectByFields(rawObjectFields || [])
  const pinnedColumnOptions: SelectOption[] = Object.values(PINNED_COLUMN).map(value => ({
    id: value,
    name: value,
  }))

  const columnInputs: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('ganttCreate.columnForm.columnInputs.code.placeholder'),
      label: i18next.t('ganttCreate.columnForm.columnInputs.code.label'),
      disabled: modalType === MODAL_TYPE.EDIT,
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      rules: {
        required: true,
        validate: value => {
          if (currentColumnsList.find(col => col.code === value && currentRow?.code !== value)) {
            return `${value} ${i18next.t('columnInputs.errors.alreadyUsed')}`
          }
        },
      },
    },
  ]

  const otherColumnInputs: FormInputProps[] = [
    {
      name: 'valueType',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: i18next.t('ganttCreate.columnForm.columnInputs.valueType.label'),
      placeholder: i18next.t('ganttCreate.columnForm.columnInputs.valueType.placeholder'),
      disabled: true,
      value: valueType,
    },
    {
      name: 'pinning',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('ganttCreate.columnForm.columnInputs.pinning.label'),
      labelPlacement: 'end',
    },
    {
      name: 'useParameters',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('ganttCreate.columnForm.columnInputs.useParameters.label'),
      labelPlacement: 'end',
    },
    ...(watchPinning
      ? [
          {
            name: 'pinnedColumn',
            inputType: GENERATOR_INPUT_TYPE.SELECT,
            label: i18next.t('ganttCreate.columnForm.columnInputs.pinnedColumn.label'),
            rules: { required: true },
            selectOptions: pinnedColumnOptions,
          },
        ]
      : []),
  ]

  return {
    otherColumnInputs,
    columnInputs,
    srcObj,
  }
}

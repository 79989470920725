import { RadioOption } from '@microservices/wiskey-react-components/dist/hookFormControllers/RadioGroupController'

export enum CONTEXT_MODEL_TYPE {
  VIEW = 'VIEW',
  GANTT_CHART = 'GANTT_CHART',
  CONTROL_PANEL = 'CONTROL_PANEL',
  VARIABLE = 'VARIABLE',
  SEARCH_ASSISTANT = 'SEARCH_ASSISTANT',
  DEFAULT_BACKGROUND_ENTITY = 'DEFAULT_BACKGROUND_ENTITY',
}

export enum DEFAULT_ENTITY_BACKGROUND {
  GANTT_CHART = 'defaultGanttChart',
  VIEW = 'defaultView',
}

export const DEFAULT_ENTITY_RADIO_OPTIONS: RadioOption[] = [
  {
    value: DEFAULT_ENTITY_BACKGROUND.GANTT_CHART,
    label: 'Gantt Chart',
  },
  {
    value: DEFAULT_ENTITY_BACKGROUND.VIEW,
    label: 'View',
  },
]

export const decrease = (number: number) => number - 1

export const increase = (number: number) => number + 1

export const getLastIndex = (array?: Array<any>) =>
  Array.isArray(array) ? (array.length ? decrease(array.length) : 0) : -1

export const getIsFirst = (index: number) => index === 0

export const getIsLast = (index: number, array?: Array<any>) => index === getLastIndex(array)

import { CSSProperties } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { Box } from '@mui/material'

const DragHandle = SortableHandle(({ style }: { style: CSSProperties }) => (
  <span style={{ ...style, ...{ cursor: 'grab' } }}>{'::::'}</span>
))

export const TableDragHandleCell = <T,>() => {
  return (
    <Box sx={{ display: 'flex', height: 32 }}>
      <DragHandle />
    </Box>
  )
}

import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Clear as ClearIcon } from '@mui/icons-material'
import { Divider, Grid, IconButton } from '@mui/material'

import { SearchAssistantConfigContext } from '@pages/ContextCreateOrEdit/components/SearchAssistant'
import { compareValueType } from '@pages/ContextCreateOrEdit/components/SearchAssistant/components/AddSearchFilterDialog/helpers'
import {
  useObjectFieldOfSelectedValue,
  useResetAsCheckboxOnValueTypeChange,
  useResetValuesOnGroupRelatedCheckboxChange,
  useResetValuesOnObjectTitleChange,
  useResetValuesOnValueNameChange,
  useValueInputs,
  useValueTypeOfSelectedValue,
} from '@pages/ContextCreateOrEdit/components/SearchAssistant/components/AddSearchFilterDialog/hooks'
import { SearchFilterValue } from '@pages/ContextCreateOrEdit/components/SearchAssistant/types'

import { DropDownInputWithFitlers } from '@components/DropDownInputWithFitlers'
import { FilterDialog } from '@components/FilterDialog'

import { useFetchAllViewQuery, useFetchObjectByCodeQuery } from '@redux/api'

import { getObjectValueName } from '@helpers'
import {
  DROPDOWN_FILTER_TYPES,
  ENTITY_TYPES_REQUEST,
  FIELD_VALUE_TYPE,
  OBJECT_FIELD_TYPE,
  SEARCH_FIELD_TYPE,
} from '@constants'

import {
  AutocompleteOption,
  GETCriteriaType,
  GETDropDownFilter,
  GETEntityFilter,
} from '../../../../../../types'

import { useResetGroupRelatedCheckboxOnValueNameChange } from './hooks/useResetGroupRelatedCheckboxOnValueNameChange'

type ValueInputsProps = {
  index: number
  disabledRemoving: boolean
  addedFiltersByObject: GETCriteriaType[]
  handleDeleteButtonClick: (index: number) => void
}

export const ValueInputs: FC<ValueInputsProps> = ({
  index,
  disabledRemoving,
  addedFiltersByObject,
  handleDeleteButtonClick,
}) => {
  const { t } = useTranslation()
  const { objects } = useContext(SearchAssistantConfigContext)
  const [isShowFilterDialog, setShowFilterDialog] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<GETEntityFilter | null>(null)
  const [currentFilterPosition, setCurrentFilterPosition] = useState<number | null>(null)
  const [currentTypeFilter, setCurrentTypeFilter] = useState<DROPDOWN_FILTER_TYPES | null>(null)

  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext()

  const valueNameInputName = `values.${index}.name`
  const valueTypeInputName = `values.${index}.valueType`
  const asCheckboxInputName = `values.${index}.asCheckbox`
  const asDurationInputName = `values.${index}.asDuration`
  const fieldTypeInputName = `values.${index}.fieldType`
  const objectValueInputName = `values.${index}.objectValue`
  const linkedObjectCodeInputName = `values.${index}.linkedObjectCode`
  const defaultPlaceholderInputName = `values.${index}.isDefaultPlaceholder`
  const groupRelatedInputName = `values.${index}.isGroupRelated`
  const placeholderValueInputName = `values.${index}.placeholderValue`
  const placeholderStartInputName = `values.${index}.placeholderStart`
  const placeholderEndInputName = `values.${index}.placeholderEnd`
  const dropDownListCodeInputName = `values.${index}.dropDownListCode`
  const dropDownWindowInputName = `values.${index}.dropDownWindowCode`
  const dropDownListFiltersInputName = `values.${index}.dropDownListFilters`
  const dropDownWindowFiltersInputName = `values.${index}.dropDownWindowFilters`

  const watchedValueName = watch(valueNameInputName)
  const watchedAsCheckbox = watch(asCheckboxInputName)
  const watchedAsDuration = watch(asDurationInputName)
  const watchedFieldType = watch(fieldTypeInputName)
  const watchedObjectValue = watch(objectValueInputName)
  const watchedDropDownListCode = watch(dropDownListCodeInputName)
  const watchedDropDownWindowCode = watch(dropDownWindowInputName)
  const watchedDefaultplaceholder = watch(defaultPlaceholderInputName)
  const watchedGroupRelated = watch(groupRelatedInputName)
  const watchedObjectTitle = watch('objectTitle')
  const watchedValues = watch('values') as SearchFilterValue[]
  const watchedDropDownListFilters: GETDropDownFilter[] = watch(dropDownListFiltersInputName)
  const watchedDropDownWindowFilters: GETDropDownFilter[] = watch(dropDownWindowFiltersInputName)

  const [objectCode, setObjectCode] = useState('')
  const { data: object } = useFetchObjectByCodeQuery(objectCode, { skip: !objectCode })
  // Используется для value options
  const objectFields = object?.fields

  const currentObject = useMemo(
    () => objects?.find(object => object.title === watchedObjectTitle),
    [watchedObjectTitle, objects]
  )

  useEffect(() => {
    if (currentObject) {
      setObjectCode(currentObject.code)
    }
  }, [currentObject])

  const isValueEmbedded = useMemo(
    () => compareValueType(objectFields, watchedValueName as AutocompleteOption),
    [watchedValueName, objectFields]
  )

  const { objectField } = useObjectFieldOfSelectedValue({ objectFields, watchedValueName })

  // Эффект для получения текста с постфиксом (PK, ENUM) для выбранного value
  useEffect(() => {
    if (!objectField) {
      return
    }

    setValue(valueNameInputName, {
      id: watchedValueName.id,
      label: watchedValueName.label,
      labelWithPostfix: getObjectValueName(
        objectField?.name as string,
        objectField?.type as OBJECT_FIELD_TYPE,
        objectField?.isPk
      ),
    })
  }, [objectField])

  const linkedObjectCode = objectField?.model ?? ''
  const isLinkedField = Boolean(objectField?.model)

  const isGroupRelatedEnabled = useMemo(() => {
    return objectField?.isPk || isLinkedField
  }, [objectField, isLinkedField])

  const { data: dropdownEntities } = useFetchAllViewQuery(
    {
      objectCode: linkedObjectCode,
      viewType: ENTITY_TYPES_REQUEST.DROP_DOWN_ENTITY,
    },
    { refetchOnMountOrArgChange: true, skip: !linkedObjectCode }
  )

  useEffect(() => {
    if (isDirty) {
      setValue(dropDownListCodeInputName, null)
      setValue(dropDownWindowInputName, null)
    }
  }, [watchedValueName])

  const { data: linkedObject } = useFetchObjectByCodeQuery(linkedObjectCode, {
    skip: !linkedObjectCode,
  })
  // Используется для objectValue options
  const linkedObjectFields = linkedObject?.fields

  useEffect(() => {
    if (isLinkedField) {
      setValue(linkedObjectCodeInputName, linkedObjectCode)
    }
  }, [linkedObjectCode, isLinkedField])

  // useEffect(() => {
  //   if (!isValueEmbedded) {
  //     setValue(objectValueInputName, null)
  //   }
  // }, [isValueEmbedded])

  const { selectedValueType, setSelectedValueType } = useValueTypeOfSelectedValue({
    watchedValueName,
    objectFields,
    valueTypeInputName,
  })

  useResetGroupRelatedCheckboxOnValueNameChange({
    watchedValueName,
    isGroupRelatedEnabled,
    objectFields,
    objectField,
    isLinkedField,
    groupRelatedInputName,
  })

  useResetValuesOnValueNameChange({ watchedValueName, objectValueInputName, fieldTypeInputName })

  useResetValuesOnObjectTitleChange({
    fieldTypeInputName,
    objectValueInputName,
    valueNameInputName,
    setSelectedValueType,
  })

  useResetValuesOnGroupRelatedCheckboxChange({
    watchedGroupRelated,
    fieldTypeInputName,
    objectValueInputName,
    linkedObjectCodeInputName,
    defaultPlaceholderInputName,
    placeholderValueInputName,
    placeholderStartInputName,
    placeholderEndInputName,
    dropDownListCodeInputName,
    dropDownWindowInputName,
    dropDownListFiltersInputName,
    dropDownWindowFiltersInputName,
  })

  useResetAsCheckboxOnValueTypeChange({
    objectField,
    watchedAsCheckbox,
    watchedAsDuration,
    asCheckboxInputName,
    asDurationInputName,
    isDirty,
  })

  const {
    valueInput,
    valueTypeInput,
    asCheckboxInput,
    asDurationInput,
    fieldTypeInput,
    objectValueInput,
    defaultPlaceholderInput,
    groupRelatedInput,
    placeholderValueInput,
    placeholderStartInput,
    placeholderEndInput,
    dropDownListInput,
    dropDownWindowInput,
  } = useValueInputs({
    objectValueInputName,
    valueNameInputName,
    asCheckboxInputName,
    asDurationInputName,
    fieldTypeInputName,
    watchedValueName,
    watchedObjectTitle,
    watchedDefaultplaceholder,
    linkedObjectFields,
    objectFields,
    defaultPlaceholderInputName,
    groupRelatedInputName,
    placeholderValueInputName,
    placeholderStartInputName,
    placeholderEndInputName,
    dropDownListCodeInputName,
    dropDownWindowInputName,
    dropdownEntities: dropdownEntities?.data || [],
    valueTypeInputName,
    valueType: objectField?.valueType,
    addedFiltersByObject,
  })

  const showFieldTypeInput = useMemo(
    () => Boolean(watchedFieldType?.id) || selectedValueType === FIELD_VALUE_TYPE.DATETIME,
    [watchedFieldType, selectedValueType]
  )

  const showObjectValueInput = useMemo(
    () => Boolean(watchedObjectValue?.id) || isValueEmbedded,
    [watchedObjectValue, isValueEmbedded]
  )

  const selectedValues = watchedValues.map(value => value.name?.id).filter(Boolean)

  const handleSubmitFilter = (filter: GETEntityFilter) => {
    if (currentTypeFilter) {
      const newFilter = { ...filter, type: currentTypeFilter }
      const isList = currentTypeFilter === DROPDOWN_FILTER_TYPES.LIST
      const inputName = isList ? dropDownListFiltersInputName : dropDownWindowFiltersInputName
      const updatedFilters = (
        isList ? watchedDropDownListFilters : watchedDropDownWindowFilters
      ).map((item, index) => (index === currentFilterPosition ? newFilter : item))

      if (currentFilterPosition !== null) {
        setValue(inputName, updatedFilters, {
          shouldDirty: true,
        })

        handleResetFilterDialog()

        return
      }

      setValue(
        inputName,
        [...(isList ? watchedDropDownListFilters : watchedDropDownWindowFilters), newFilter],
        {
          shouldDirty: true,
        }
      )
      handleResetFilterDialog()
    }
  }

  const handleDeleteFilter = (position: number, type: DROPDOWN_FILTER_TYPES) => {
    if (type === DROPDOWN_FILTER_TYPES.LIST) {
      setValue(
        dropDownListFiltersInputName,
        watchedDropDownListFilters.filter((_, index) => index !== position),
        { shouldDirty: true }
      )
    }
    if (type === DROPDOWN_FILTER_TYPES.WINDOW) {
      setValue(
        dropDownWindowFiltersInputName,
        watchedDropDownWindowFilters.filter((_, index) => index !== position),
        { shouldDirty: true }
      )
    }
  }

  const handleDoubleClickChip = (
    filter: GETEntityFilter,
    position: number,
    type: DROPDOWN_FILTER_TYPES
  ) => {
    setCurrentFilter(filter)
    setCurrentFilterPosition(position)
    setCurrentTypeFilter(type)
    setShowFilterDialog(true)
  }

  const handleResetFilterDialog = () => {
    setShowFilterDialog(false)
    setCurrentFilterPosition(null)
    setCurrentFilter(null)
    setCurrentTypeFilter(null)
  }

  const handleAddFilter = (type: DROPDOWN_FILTER_TYPES) => {
    setShowFilterDialog(true)
    setCurrentTypeFilter(type)
  }

  const handleCloseFilterDialog = (isDirty: boolean) => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    handleResetFilterDialog()
  }

  const dropdownEntitiesInputs = (
    <Grid item flex={1}>
      <DropDownInputWithFitlers
        filters={watchedDropDownListFilters}
        inputs={[dropDownListInput]}
        watchInput={watchedDropDownListCode}
        onAddFilter={() => handleAddFilter(DROPDOWN_FILTER_TYPES.LIST)}
        onDeleteFilter={(_, index) => handleDeleteFilter(index, DROPDOWN_FILTER_TYPES.LIST)}
        onDoubleClickFilter={(filter, index) =>
          handleDoubleClickChip(filter, index, DROPDOWN_FILTER_TYPES.LIST)
        }
      />
      <DropDownInputWithFitlers
        filters={watchedDropDownWindowFilters}
        inputs={[dropDownWindowInput]}
        watchInput={watchedDropDownWindowCode}
        onAddFilter={() => handleAddFilter(DROPDOWN_FILTER_TYPES.WINDOW)}
        onDeleteFilter={(_, index) => handleDeleteFilter(index, DROPDOWN_FILTER_TYPES.WINDOW)}
        onDoubleClickFilter={(filter, index) =>
          handleDoubleClickChip(filter, index, DROPDOWN_FILTER_TYPES.WINDOW)
        }
      />
    </Grid>
  )

  const startEndPlaceholdersInputs = (
    <>
      <Grid item xs={5.5}>
        <FormInput {...placeholderStartInput} disableClearable />
      </Grid>
      <Grid item>
        <Divider sx={{ width: 10, mx: 0.5 }} />
      </Grid>
      <Grid item xs={4}>
        <FormInput {...placeholderEndInput} disableClearable label={undefined} />
      </Grid>
    </>
  )

  const placeholderInputs = (
    <Grid container item alignItems={'center'} xs={12}>
      {watchedFieldType?.label === SEARCH_FIELD_TYPE.RANGE_OF_VALUES ? (
        startEndPlaceholdersInputs
      ) : (
        <Grid item flexGrow={1}>
          <FormInput {...placeholderValueInput} disableClearable />
        </Grid>
      )}
      <Grid item>
        <FormInput {...defaultPlaceholderInput} disableClearable />
      </Grid>
    </Grid>
  )

  const generalInputs = (
    <>
      {showFieldTypeInput && (
        <Grid container item>
          <FormInput {...fieldTypeInput} disableClearable />
        </Grid>
      )}
      {showObjectValueInput && (
        <Grid container item>
          <FormInput {...objectValueInput} disableClearable />
        </Grid>
      )}
      {/* Dropdown inputs */}
      {isLinkedField && dropdownEntitiesInputs}
    </>
  )

  const shouldDisplayAsCheckboxField =
    (objectField?.valueType === FIELD_VALUE_TYPE.INTEGER ||
      objectField?.valueType === FIELD_VALUE_TYPE.BOOLEAN) &&
    objectField?.type !== OBJECT_FIELD_TYPE.ENUM

  const filterValueInput = (
    <>
      <Grid container item>
        <Grid item flexGrow={1}>
          <FormInput
            {...valueInput}
            disableClearable
            // getOptionDisabled={option => selectedValues.includes(option.id)}
            autocompleteInputProps={params => ({
              endAdornment: (
                <>
                  {!disabledRemoving && (
                    <IconButton
                      disabled={params.disabled}
                      size={'small'}
                      onClick={() => handleDeleteButtonClick(index)}
                    >
                      <ClearIcon fontSize={'small'} />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            })}
          />
        </Grid>
        <Grid item>
          <FormInput {...groupRelatedInput} disableClearable disabled={!isGroupRelatedEnabled} />
        </Grid>
        {watchedValueName && (
          <Grid container item flexGrow={1}>
            <Grid item flexGrow={1}>
              <FormInput {...valueTypeInput} disableClearable />
            </Grid>
            {shouldDisplayAsCheckboxField && (
              <Grid item>
                <FormInput {...asCheckboxInput} disableClearable disabled={watchedAsDuration} />
              </Grid>
            )}
            {objectField?.valueType === FIELD_VALUE_TYPE.INTEGER && (
              <Grid item>
                <FormInput {...asDurationInput} disableClearable disabled={watchedAsCheckbox} />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  )

  return (
    <Grid container spacing={0} sx={{ mt: 0.5 }}>
      {index === 0 && (
        <Grid container item>
          <Divider sx={{ width: '100%', mb: 1 }} />
        </Grid>
      )}
      {isShowFilterDialog && (
        <FilterDialog
          filter={currentFilter}
          isShow={isShowFilterDialog}
          onClose={handleCloseFilterDialog}
          onSubmit={handleSubmitFilter}
        />
      )}
      {/* Filter value */}
      {filterValueInput}

      {/* General inputs */}
      {!watchedGroupRelated && generalInputs}
      {placeholderInputs}
      <Grid container item>
        <Divider sx={{ width: '100%' }} />
      </Grid>
    </Grid>
  )
}

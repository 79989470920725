import { SyntheticEvent, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  useCreateContextMutation,
  useUpdateContextControlPanelByIdMutation,
  useUpdateContextDefaultEntityBackgroundByIdMutation,
  useUpdateContextGanttChartsByIdMutation,
  useUpdateContextMutation,
  useUpdateContextVariablesByIdMutation,
  useUpdateContextViewsByIdMutation,
} from '@redux/api'

import { CONTEXT_MODEL_TYPE, ROUTES } from '@constants'
import { GanttType, GETContextDTO, ViewShortDTO } from '@types'

import { tabs } from '../components'
import { FormContextType } from '../types'

type useHandlersParams = {
  methods: UseFormReturn<FormContextType>
  contextById?: GETContextDTO
}

export const useHandlers = ({ methods, contextById }: useHandlersParams) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields },
  } = methods

  const [currentTab, setCurrentTab] = useState<number>(0)

  const [createContext] = useCreateContextMutation()
  const [updateContext] = useUpdateContextMutation()
  const [updateViews] = useUpdateContextViewsByIdMutation()
  const [updateGanttCharts] = useUpdateContextGanttChartsByIdMutation()
  const [updateControlPanel] = useUpdateContextControlPanelByIdMutation()
  const [updateVariables] = useUpdateContextVariablesByIdMutation()
  const [updateDefaultEntityBackground] = useUpdateContextDefaultEntityBackgroundByIdMutation()
  const [isLoading, setLoading] = useState(false)

  const isNewTitle =
    contextById && getValues().title ? getValues().title !== contextById.title : false

  const handleTabChange = (event: SyntheticEvent, tabIndex: number) => {
    if (
      Object.keys(dirtyFields).find(field => field !== 'title') &&
      !confirm(t('notifications.leave'))
    ) {
      return
    }

    reset()
    setCurrentTab(tabIndex)
  }

  const handleCreateModel = (route: string | undefined) => route && navigate(route)

  const handleCancel = () => navigate(ROUTES.CONFIG_CONTEXTS)

  const handleSave = handleSubmit((contextData: FormContextType) => {
    const data = {
      id: contextData.id ?? null,
      title: contextData.title,
    }
    setLoading(true)

    if (contextData.id) {
      if (isNewTitle) {
        updateContext(data).finally(() => setLoading(false))
      }

      updateListByModelType(
        Number(contextData.id),
        tabs[currentTab].modelType,
        contextData
      ).finally(() => setLoading(false))
    } else {
      createContext(data)
        .unwrap()
        .then(async res => {
          await updateListByModelType(Number(res.id), tabs[currentTab].modelType, contextData)

          navigate(`${ROUTES.CONFIG_CONTEXTS_EDIT}/${res.id}`)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  })

  const updateListByModelType = async (
    id: number,
    modelType: CONTEXT_MODEL_TYPE,
    contextData: FormContextType
  ) => {
    switch (modelType) {
      case CONTEXT_MODEL_TYPE.VIEW:
        if (dirtyFields.selectedViews) {
          return updateViews({
            id,
            data: {
              viewIds: contextData.selectedViews.map((view: ViewShortDTO) => view.id),
            },
          })
        }
        break
      case CONTEXT_MODEL_TYPE.GANTT_CHART:
        if (dirtyFields.selectedGanttCharts) {
          return updateGanttCharts({
            id,
            data: {
              ganttChartIds: contextData.selectedGanttCharts.map(
                (ganttChart: GanttType) => ganttChart.id
              ),
            },
          })
            .unwrap()
            .then(res => {
              reset({
                ...getValues(),
                selectedGanttCharts: res.selectedGanttCharts,
                unselectedGanttCharts: res.unselectedGanttCharts,
              })
            })
        }
        break
      case CONTEXT_MODEL_TYPE.CONTROL_PANEL:
        if (dirtyFields.controlPanel) {
          return updateControlPanel({
            contextId: id,
            controlPanelId: (contextData.controlPanel?.id as number) ?? null,
          })
        }
        break
      case CONTEXT_MODEL_TYPE.VARIABLE:
        if (dirtyFields.selectedVariables) {
          return updateVariables({
            contextId: id,
            variables: contextData.selectedVariables.map(item => {
              return {
                values: item.values,
                variableId: item.variableId,
                variableTitle: item.variableTitle,
                valueType: item.valueType,
                variableType: item.variableType,
              }
            }),
          })
        }
        break
      case CONTEXT_MODEL_TYPE.DEFAULT_BACKGROUND_ENTITY: {
        const hasDefaultEntity =
          contextData.defaultBackgroundEntities[0].view ||
          contextData.defaultBackgroundEntities[0].ganttChart

        return updateDefaultEntityBackground({
          contextId: id,
          backgroundEntity: hasDefaultEntity
            ? contextData.defaultBackgroundEntities.map(entity => {
                const defaultEntity = entity.view || entity.ganttChart

                return {
                  id: null,
                  title: null,
                  sourceId: defaultEntity?.id,
                  type: defaultEntity?.type,
                  code: null,
                }
              })
            : [],
        })
      }
      default:
        return
    }
  }

  return {
    state: {
      currentTab,
      isNewTitle,
      isLoading,
    },
    handlers: {
      handleTabChange,
      handleCreateModel,
      handleSave,
      handleCancel,
    },
  }
}
